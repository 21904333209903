import { $t } from '~/i18n.js'
import { EditOutlined, PrinterOutlined } from "@ant-design/icons";
import { Button, Tag, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { _parseDate, getAge } from "~/lib/localize.js";
import { HelpBubble } from '../../help/help.bubble.jsx'
import { DtoTrainingUpdateModal } from './dtoTraining.UpdateModal.jsx'
import { api } from "~/lib/api.js";

const DTOtrainingRow = ({
  canWijzigDTO,
  editRow,
  cluster,
  active_training,
  onExportClick,
}) => (
  <tr>
    <td className="col">
      {cluster.name}{" "}
      <span className="gray" style={{ marginLeft: 12 }}>
        {cluster.curriculum_versie}
      </span>
    </td>
    <td className="col">
      {canWijzigDTO && (
        <Button
          style={{ marginRight: 12 }}
          shape="circle"
          size="small"
          type="primary"
          onClick={() =>
            editRow({
              status: "concept",
              cluster_id: cluster.id,
              name: cluster.name,
              // overwrite active_Training, e.g. status if applicable
              ...active_training,
            })
          }
          icon={<EditOutlined />}
        />
      )}

      {active_training && (
        <>
          {active_training.status === "started" && (
            <Tooltip placement="top" title={active_training.started_by_name}>
              <Tag color="green">{$t("gestart")}</Tag>{" "}
              {_parseDate(active_training.started_date)}
            </Tooltip>
          )}

          {active_training.status === "completed" && (
            <Tooltip placement="top" title={active_training.started_by_name}>
              <Tag color="gold">{$t("afgerond")}</Tag>{" "}
              {_parseDate(active_training.completed_date)}
            </Tooltip>
          )}

          {active_training.status === "discontinued" && (
            <Tooltip placement="top" title={active_training.started_by_name}>
              <Tag>{$t("gestaakt")}</Tag>{" "}
              {_parseDate(active_training.updated_date)}
            </Tooltip>
          )}

          <Button
            style={{ marginLeft: 12 }}
            size="small"
            icon={<PrinterOutlined />}
            onClick={() => onExportClick(active_training?.id)}
          >
            {$t("Export")}
          </Button>
        </>
      )}
    </td>
  </tr>
);

export const DTOtrainingTab = ({
  canWijzigDTO,
  dto_training,
  bevoegdheden,
  pilot,
  onAddDTOprogramme,
  gegevens,
}) => {
  const [participatingTrainings, setCurrent] = useState(null);
  useEffect(
    () => dto_training && setCurrent(dto_training),
    [dto_training, setCurrent]
  );

  async function onExportClick(id) {
    api.openFileGET(`bevoegdheid/${id}/export_training.json`);
  }
  const [activeTraining, setActiveTraining] = useState(null);
  return (
    <div>
      {activeTraining && (
        <DtoTrainingUpdateModal
          data={activeTraining}
          visible={activeTraining != null}
          onClose={() => setActiveTraining(null)}
          pilot={pilot}
          saveNewResult={(newData) => {
            // save new data to activeTraining cluster
            const newSet = { ...participatingTrainings };
            newSet[activeTraining.cluster_id] = newData;
            setCurrent(newSet);
            if (onAddDTOprogramme) onAddDTOprogramme(newSet);
          }}
        />
      )}

      {(gegevens?.brevet_nummer ||
        gegevens?.aml_nummer ||
        gegevens?.knvvl ||
        gegevens?.date_of_birth) && (
        <div style={{ marginBottom: 12 }}>
          <p className="bold">{$t("DTO gegevens")}:</p>
          {gegevens?.brevet_nummer && (
            <p>
              {$t("(SPL) Brevet nummer")}: {gegevens?.brevet_nummer}{" "}
              {gegevens?.date_brevet_afgifte &&
                `(${_parseDate(gegevens.date_brevet_afgifte)})`}
            </p>
          )}
          {gegevens?.aml_nummer && (
            <p>
              {$t("Technicus licentie (AML) nummer")}: {gegevens?.aml_nummer}
            </p>
          )}
          {gegevens?.knvvl && (
            <p>
              {$t("KNVvL lidnummer")}: {gegevens?.knvvl}
            </p>
          )}

          {gegevens?.date_of_birth && (
            <p>
              {$t("Geboortedatum")}: {_parseDate(gegevens?.date_of_birth)} ({getAge(gegevens?.date_of_birth)})
            </p>
          )}
        </div>
      )}

      <p className="bold">
        {$t("Overzicht van alle DTO training programma's")}
        <HelpBubble
          content={$t(
            "Een lid kan door een instructeur coordinator (HoT) worden toegevoegd aan een programma."
          )}
        />
      </p>
      <table className="dataBlock">
        <tbody>
          {bevoegdheden &&
            bevoegdheden.map(
              (cluster) =>
                cluster.is_dto && (
                  <DTOtrainingRow
                    key={cluster.name}
                    canWijzigDTO={canWijzigDTO}
                    editRow={setActiveTraining}
                    cluster={cluster}
                    active_training={
                      participatingTrainings &&
                      participatingTrainings[cluster.id]
                    }
                    onExportClick={onExportClick}
                  />
                )
            )}
        </tbody>
      </table>
    </div>
  );
};

import { Descriptions } from 'antd'
import React, { useState } from 'react'
import { $t } from '~/i18n.js'

import { useSelector } from 'react-redux'
import { CardBase } from "~/components/timeLine/cardBase";
import { _parseDate } from '../../../../lib/localize'

export const CardConfirm = ({ data, saveData, saveAndNext, prev }) => {
  const [loading, setLoading] = useState(false)
  console.log('... dat? ', data)

  const { group_name_lookup } = useSelector((state) => state.flights)

  const handleSave = async () => {
    setLoading(true)
    data = await saveData(data)
    setLoading(false)
    if (data) saveAndNext()
  }

  return (
    <CardBase prev={prev} next={handleSave} title={$t('Opslaan')} loading={loading}>

      <Descriptions title={$t('Bevestig rooster periode')} size='small' bordered column={1}>
        <Descriptions.Item label={$t('Rooster')}>{data.description}</Descriptions.Item>
        <Descriptions.Item label={$t('Data')}>
          {data.days.length} {$t('dagen')} <br />
          {data.is_vliegend ? 'vliegend' : 'niet vliegend'} <br />
          {$t('van')} {_parseDate(data.date_from)} - {_parseDate(data.date_to)}
        </Descriptions.Item>
        <Descriptions.Item label={$t('Diensten')}>
          {data.roosters.map((rooster, key) =>
            <p key={key}>{rooster.description} - {group_name_lookup[rooster.group_key] || rooster.group_key}</p>)}

          {data.roosters?.length === 0 && $t('Géén diensten')}
        </Descriptions.Item>
      </Descriptions>

    </CardBase>
  )
}

import React, { useState } from 'react'
import useMedia from 'use-media'
import { $t, i18n } from '~/i18n.js'

import { FileExcelOutlined, WarningTwoTone } from '@ant-design/icons'
import { Button, Select, Table, Tag } from 'antd'

import { defaultTableConfig } from "~/components/layout/table";
import { _parseDate, parseMinutesLong } from '../../../../lib/localize'

import { api } from '../../../../lib/api'
import { Inspection } from '../status/inspection'
import { MaterialLogbookColumns, MaterialLogbookColumnsPerDay } from './materialLogbook.columns'

export const MaterialLogbook = ({ history, year, currentMaterial }) => {
  const isWide = useMedia({ minWidth: '1350px' }, true)

  const [loading, setLoading] = useState(false)
  const [datum, setDatum] = useState(null)
  const [datumFlights, setDatumFlights] = useState([])
  const [datumIspections, setInspections] = useState([])
  const loadDatum = async (_datum) => {
    setDatum(_datum)
    setLoading(true)
    const res = await api.get(`flights/${currentMaterial.kist_id}/kist_flights.json?datum=${_datum}`)
    setLoading(false)
    setDatumFlights(res.flights)
    setInspections(res.inspections)
  }

  const years = []
  const currentYear = new Date().getFullYear()
  year = year || currentYear
  const first_year = new Date(currentMaterial.date_first_flight || '2021-01-01').getFullYear()
  for (let i = currentYear; i >= first_year; i--) {
    years.push(i)
  }

  const [fileLoading, setFileLoading] = useState(false)
  const downloadFile = async () => {
    if (fileLoading) return
    setFileLoading(true)
    await api.open_file(`flights/export_history.json?format=xlsx&year=${year}&kist=${currentMaterial.kist_id}`, `vluchten ${currentMaterial.name} ${year}.xlsx`)
    setFileLoading(false)
  }

  let totalStarts = 0
  let totalMinutes = 0

  let data = datum ? datumFlights : currentMaterial.logbook?.flights
  // for single flights, they are dictionaries
  if (data && !Array.isArray(data)) data = [data]
  data?.forEach(row => {
    totalStarts += row.starts
    totalMinutes += (row.minutes || 0 + row.vluchtduur || 0)
    row.id = row.uuid ? row.uuid : `${row.datum}|${row.vertrek_vliegveld}`
  })

  return (
    <div>

      <div className='row' style={{ alignContent: 'center', flexWrap: 'wrap', gap: 12 }}>
        <span>{$t('Jaar')}:</span>

        <Select
          mode='single' size='small' value={year}
          onChange={value => history.push(`/material/${currentMaterial.id}/logbook/${value}`)}
        >
          {years.map((value, key) => <Select.Option value={value} key={key}>{value}</Select.Option>)}
        </Select>

        <Button icon={<FileExcelOutlined />} size='small' loading={fileLoading} onClick={downloadFile}>
          {$t('Download')}
        </Button>

        {datum && <Tag closable onClose={() => setDatum(null)}>
          {_parseDate(datum)}
        </Tag>}
      </div>

      <div className='row' style={{ gap: 12, margin: '6px 0' }}>
        <p>{$t('Starts')}: {totalStarts}</p>
        <p>{$t('Duur')}: {parseMinutesLong(totalMinutes)}</p>

        {datum && !loading &&
          <div className='row' style={{ gap: 6 }}>{$t('Dagelijkse inspectie:')}
            {datumIspections.length > 0
              ? datumIspections.map((i, k) => <Inspection inspection={i} key={k} />)
              : <p><WarningTwoTone twoToneColor='red' /> {$t('Niet uitgevoerd')}</p>}

          </div>}

      </div>

      <Table
        style={{ marginTop: 12, width: '97vw', overflowX: isWide ? null : 'auto', cursor: 'pointer' }}
        pagination={defaultTableConfig}
        size='small'
        rowKey='id'
        dataSource={data}
        columns={datum ? MaterialLogbookColumnsPerDay() : MaterialLogbookColumns()}
        loading={loading}
        onRow={row => {
          return {
            onClick: () => !datum && loadDatum(row.datum)
          }
        }}
      />

    </div>
  )
}

import { PlusOutlined } from '@ant-design/icons'
import { Button, Empty } from 'antd'
import React, { useState } from 'react'
import { SearchTags } from "~/components/searchTag/searchTag";
import { $t } from '~/i18n.js'
import { selectElementByID } from '../../../../lib/helpers'
import { AddWorkorderModal } from './addWorkorder'
import { ModalEditWorkorder } from './modalEditWorkorder'
import { ModalViewWorkorder } from './modalViewWorkorder'
import { WorkorderCard } from './workorderCard'

export const MaterialWorkorders = ({ currentMaterial, currentProfile, currentRole, workorders, active_workorder_id, tasks, readOnly, action, history }) => {
  const [viewAddModal, setViewAddModal] = useState(false)

  const openWorkorder = active_workorder_id ? selectElementByID(workorders, 'id', parseInt(active_workorder_id)) : null

  const canShowEditModal = action === 'edit' && !readOnly
  const closeModal = () => history.push(`/material/${currentMaterial.id}/workorders/`)

  const [searchTag, setSearchTag] = useState(['actief'])
  const list_workorders = workorders?.filter(row => {
    // default only show active workorders
    if (searchTag.length > 0) {
      if (searchTag.includes('archief')) return row.state === 'completed'
      if (searchTag.includes('actief')) return row.state === 'active'
    }
    return true
  })

  // const dispatch = useDispatch();
  // const [loadingArc, setLoadingArc] = useState(false);
  // const createARC = async () => {
  //   setLoadingArc(true);
  //   // partID is cascade, as such is an array of the nest, take last
  //   let payload = {action: 'create_from_arc'};
  //   let res = await dispatch(saveMaterialTab({activeTab: 'workorders', materialID: currentMaterial.id, payload}))
  //   if (res) closeModal()
  //   setLoadingArc(false)
  // };

  return (
    <div className='column' style={{ gap: 24, alignItems: 'flex-start' }}>

      {canShowEditModal && openWorkorder && <ModalEditWorkorder workorder={openWorkorder} visible tasks={tasks} currentMaterial={currentMaterial} closeModal={closeModal} />}
      {!canShowEditModal && openWorkorder &&
        <ModalViewWorkorder readOnly={readOnly} workorder={openWorkorder} visible tasks={tasks} currentMaterial={currentMaterial} currentProfile={currentProfile} closeModal={closeModal} />}

      {viewAddModal && <AddWorkorderModal
        visible={viewAddModal}
        readOnly={readOnly}
        currentMaterial={currentMaterial}
        closeModal={() => setViewAddModal(false)}
                       />}

      <div className='row' style={{ justifyContent: 'space-between', width: '100%', gap: 12 }}>

        {/* {!readOnly && workorders.length > 0 && currentMaterial.category === 'kist' && <Button disabled={currentRole !== 'arc_technician'} loading={loadingArc} onClick={createARC} size='small' icon={<PlusOutlined/>}> */}
        {/*  nieuwe ARC </Button>} */}

        <div className='row' style={{ gap: 6 }}>
          <SearchTags
            items={[
              { name: 'actief', color: 'orange' }, // $t('actief')
              { name: 'archief', color: 'default' } // $t('archief')
            ]}
            searchTags={searchTag}
            setSearchTags={setSearchTag}
          />
        </div>
        {!readOnly && workorders.length > 0 && <Button onClick={() => setViewAddModal(true)} size='small' icon={<PlusOutlined />}>
          {$t('Voeg toe')}
        </Button>}
      </div>

      {!readOnly && !workorders.length && <div>
        <Button type='primary' onClick={() => setViewAddModal(true)}>{$t('Voeg workorder toe')}</Button>
                                          </div>}
      {readOnly && !workorders.length && <div className='row' style={{ justifyContent: 'center', width: '100%', marginTop: 42 }}>
        <Empty description='Geen workorders ingeladen' />
                                         </div>}

      {list_workorders.map((workorder, key) => <WorkorderCard key={key} workorder={workorder} readOnly={readOnly} />)}

    </div>
  )
}

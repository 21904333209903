import { Form, Input } from 'antd'
import React, { useState } from 'react'
import { $t } from '~/i18n.js'

import { DataCard } from "~/components/dataCards/dataCard";
import { deserializePhone, serializePhone } from '../../../lib/localize'

export const CardContact = ({ gegevens, profile, saveData, type }) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const saveForm = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()

      // manipulate values for save
      const res = await saveData({
        gegevens: {
          ...values
        },
        profile: {
          phone: serializePhone(values.phone)
        }
      })
      setLoading(false)
      return res
    } catch (info) {
      console.log('Validate Failed:', info)
      setLoading(false)
    }
  }

  return (
    <DataCard title={$t('Contactgegevens')} onSave={saveForm} loading={loading} type={type}>

      <Form
        form={form}
        name='contact'
        initialValues={{
          ...gegevens,
          phone: deserializePhone(profile.phone)
        }}
        requiredMark={false}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item
          label={$t('Telefoon')}
          name='phone'
          rules={[{ required: true, message: $t('Wat is je telefoon') }]}
        >
          <Input type='tel' />
        </Form.Item>

        <Form.Item
          label={$t('Adres')}
          name='address1'
          rules={[{ required: true, message: $t('Wat is je adres?') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={$t('Adres 2')}
          name='address2'
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={$t('Postcode')}
          name='zipcode'
          rules={[{ required: true, message: $t('Wat is je post code?') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={$t('Stad')}
          name='city'
          rules={[{ required: true, message: $t('In welke stad woon je?') }]}
        >
          <Input />
        </Form.Item>

      </Form>

    </DataCard>
  )
}

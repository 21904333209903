import React from 'react'
import { $t } from '~/i18n.js'

import { i18n } from '~/i18n.js'
import { _parseDate, parseMinutes } from "../../lib/localize.js";
import { FlightTags } from "../flight/sections/FlightTags.jsx";
import { OGNtimeIcon } from "../flight/sections/Ogn.jsx";

export const flightStatsAggregatedColumns = () => ([
  {
    title: $t('Datum'),
    dataIndex: 'datum',
    sorter: (a, b) => new Date(a.datum) - new Date(b.datum),
    render: (text) => _parseDate(text, { month: '2-digit', day: '2-digit' })
  },
  { title: $t('Vertrek'), dataIndex: 'vertrek_vliegveld' },
  { title: $t('Callsign'), dataIndex: 'callsign', responsive: ['md'] },
  { title: $t('Registratie'), dataIndex: 'registratie' },
  { title: $t('Type'), dataIndex: 'type', responsive: ['md'] },
  { title: $t('Meth.'), dataIndex: 'start_methode', render: text => $t(text) },
  { title: $t('Starts'), dataIndex: 'starts' },
  { title: $t('Duur'), dataIndex: 'vluchtduur', render: text => parseMinutes(text) }
])

export const flightStatsColumns = (pilot) => {
  return [
    {
      title: $t('Datum'),
      dataIndex: 'datum',
      defaultSortOrder: 'descend',
      sorter: (a, b) => new Date(a.datum + ' ' + a.start_tijd) - new Date(b.datum + ' ' + b.start_tijd),
      render: (text) => _parseDate(text)
    },
    {
      title: $t('Vertrek'),
      dataIndex: 'vertrek_vliegveld',
      sorter: (a, b) => ('' + a.vertrek_vliegveld).localeCompare(b.vertrek_vliegveld)
    },
    {
      title: $t('Aankomst'),
      dataIndex: 'aankomst_vliegveld',
      sorter: (a, b) => ('' + a.aankomst_vliegveld).localeCompare(b.aankomst_vliegveld),
      responsive: ['md']
    },

    { title: $t('Callsign'), dataIndex: 'callsign' },
    { title: $t('Registratie'), dataIndex: 'registratie' },
    { title: $t('Type'), dataIndex: 'type', responsive: ['md'] },
    {
      title: $t('Gezagvoerder'),
      dataIndex: 'gezagvoerder_naam',
      sorter: (a, b) => ('' + a.gezagvoerder_naam).localeCompare(b.gezagvoerder_naam),
      responsive: ['md']
    },

    {
      title: $t('Pax/DBO'),
      dataIndex: 'tweede_inzittende_naam',
      sorter: (a, b) => ('' + a.tweede_inzittende_naam).localeCompare(b.tweede_inzittende_naam),
      responsive: ['md']
    },

    {
      title: $t('Meth.'), dataIndex: 'start_methode', render: text => $t(text)
    },

    {
      title: $t('Start'),
      dataIndex: 'start_tijd',
      width: 70,
      render: (text, flight) => <div className='row' style={{ width: 65 }}><OGNtimeIcon ogn_indicator={flight.start_ogn} />{text}</div>
    },

    {
      title: $t('Landing'),
      dataIndex: 'landings_tijd',
      width: 70,
      render: (text, flight) => <div className='row' style={{ width: 65 }}><OGNtimeIcon ogn_indicator={flight.landings_ogn} />{text}</div>
    },

    {
      title: $t('PIC tijd'),
      dataIndex: 'pic_time',
      sorter: (a, b) => a.pic_time - b.pic_time,
      render: (text, flight) => <div>
        {flight.starts > 1 && <span>{flight.starts} starts, </span>}
        <span>{parseMinutes(text)}</span>
      </div>
    },

    {
      title: $t('DBO tijd'),
      dataIndex: 'dbo_time',
      sorter: (a, b) => a.dbo_time - b.dbo_time,
      render: (text, flight) => <div>
        {flight.starts > 1 && <span>{flight.starts} starts, </span>}
        <span>{parseMinutes(text)}</span>
      </div>
    },

    {
      title: $t('Hoogte'),
      dataIndex: 'height',
      sorter: (a, b) => a.height - b.height,
      render: (value) => i18n.parseLocaleHeightString(value)
    },

    // Seems not to be used
    // {
    //   title: 'Km', dataIndex: 'afstand',
    //   sorter: (a, b) => a.afstand - b.afstand,
    //   responsive: ['md']
    // },

    {
      render: (text, flight) => <FlightTags flight={flight} pilot={pilot} showNotitie />
    }
  ]
}

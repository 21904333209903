import { Form } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { SettingCard } from "~/components/dataCards/SettingCard.jsx";
import { ListSelectToString } from "~/components/form/ListSelectToString.jsx";
import { SelectCustomCategoryListFromCSV } from "~/components/form/SelectCustomCategoryList.jsx";
import { $t } from "~/i18n.js";
import { all_start_methods } from "~/redux/flights/flights.state.js";

export const SelectableList = () => {
  const { group_name_lookup, all_group_keys } = useSelector(
    (state) => state.flights
  );
  return (
    <SettingCard title={$t("Club inrichting")}>
      <Form.Item
        label={$t("Lidmaatschap types")}
        extra={$t("Type lidmaatschappen van leden")}
        name="lidmaatschap_types"
      >
        <ListSelectToString allowAddNew={true} />
      </Form.Item>

      <Form.Item
        label={$t("Dag types")}
        extra={$t("Type dagen die aangemaakt kunnen worden")}
        name="dag_types"
      >
        <ListSelectToString allowAddNew={true} />
      </Form.Item>

      <Form.Item
        label={$t("Default vliegers")}
        extra={$t(
          "Namen die geselecteerd kunnen worden als de vlieger geen clublid is"
        )}
        name="default_vliegers"
      >
        <ListSelectToString allowAddNew={true} />
      </Form.Item>

      <Form.Item
        label={$t("Filter ledenlijst")}
        extra={$t(
          "Groepen die zichtbaar zijn op de ledenlijst waarom leden kunnen filteren en om te emailen"
        )}
        name="groups_filter"
      >
        <ListSelectToString
          nameLookup={group_name_lookup}
          list={all_group_keys}
        />
      </Form.Item>

      <Form.Item
        label={$t("Filter Aanmeldlijst")}
        extra={$t("Groepen die zichtbaar zijn op de aanmeld lijst")}
        name="groups_aanmeld"
      >
        <ListSelectToString
          nameLookup={group_name_lookup}
          list={all_group_keys}
        />
      </Form.Item>

      <Form.Item
        label={$t("Club startmethodes")}
        extra={$t(
          "Methodes die zichtbaar zijn in de startwagen, leden kunnen bij hun eigen methodes alles zien"
        )}
        name="start_methods"
      >
        <ListSelectToString
          nameLookup={all_start_methods.reduce((acc, key) => {
            acc[key] = $t(key);
            return acc;
          }, {})}
          list={all_start_methods}
        />
      </Form.Item>

      <Form.Item
        label={$t("Vlucht categorie")}
        extra={$t("Selecteerbaar bij het aanmaken van een reguliere vlucht")}
        name="flight_cat"
      >
        <SelectCustomCategoryListFromCSV />
      </Form.Item>

      <Form.Item label={$t("Sleep categorie")} name="sleep_cat">
        <SelectCustomCategoryListFromCSV />
      </Form.Item>

      <Form.Item label={$t("TMG categorie")} name="tmg_cat">
        <SelectCustomCategoryListFromCSV />
      </Form.Item>
    </SettingCard>
  );
};

import { Form, Input } from "antd";
import React from "react";
import { SettingCard } from "~/components/dataCards/SettingCard.jsx";
import { CountryCodeSelect } from "~/components/form/CountryCodeSelect.jsx";
import { EmailValidator, LengthValidator, ValidateEmailListValidator } from "~/components/form/formHelpers.js";
import { ListSelectToString } from "~/components/form/ListSelectToString.jsx";
import { $t } from "~/i18n.js";

export const Financial = () => {
  return (
    <SettingCard title={$t("Financieel")}>
      <Form.Item
        label={$t("Voornaam contactpersoon")}
        name="first_name"
        rules={[LengthValidator(200, true)]}
      >
        <Input type="string" />
      </Form.Item>

      <Form.Item
        label={$t("Achternaam contactpersoon")}
        name="last_name"
        rules={[LengthValidator(200, false)]}
      >
        <Input type="string" />
      </Form.Item>

      <Form.Item
        label={$t("Email contactpersoon")}
        name="email"
        rules={[LengthValidator(200, true), EmailValidator(true)]}
      >
        <Input type="email" />
      </Form.Item>

      <Form.Item
        label={$t("Email facturen")}
        name="email_facturen"
        rules={[LengthValidator(500, false), ValidateEmailListValidator]}
      >
        <ListSelectToString allowAddNew={true} />
      </Form.Item>

      <Form.Item
        label={$t("Club naam")}
        name="financial_name"
        rules={[LengthValidator(300, false)]}
      >
        <Input type="string" />
      </Form.Item>

      <Form.Item
        label={$t("Club adres ${line}", { line: 1 })}
        name="address1"
        rules={[LengthValidator(300, false)]}
      >
        <Input type="string" />
      </Form.Item>

      <Form.Item
        label={$t("Club adres ${line}", { line: 2 })}
        name="address2"
        rules={[LengthValidator(300, false)]}
      >
        <Input type="string" />
      </Form.Item>

      <Form.Item
        label={$t("Postcode")}
        name="zipcode"
        rules={[LengthValidator(300, false)]}
      >
        <Input type="string" />
      </Form.Item>

      <Form.Item
        label={$t("Stad")}
        name="city"
        rules={[LengthValidator(300, false)]}
      >
        <Input type="string" />
      </Form.Item>

      <Form.Item
        label={$t("ISO landcode")}
        name="country"
        rules={[LengthValidator(2, true)]}
      >
        <CountryCodeSelect />
      </Form.Item>

      <Form.Item
        label={$t("BTW nummer")}
        name="vat_number"
        rules={[LengthValidator(200, false)]}
      >
        <Input type="string" />
      </Form.Item>
    </SettingCard>
  );
};

import { QuestionOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Tag, Flex } from "antd";

import React from "react";
import { SettingCard } from "~/components/dataCards/SettingCard.jsx";
import { config } from "~/config.js";
import { $t } from "~/i18n.js";

export const PlanTerms = ({ clubData }) => {
  // comment to add plan key in translation
  // $t('test')
  // $t('small')
  // $t('large')
  // $t('free')
  // $t('cancelled')

  return (
    <SettingCard title={$t("Plan en voorwaarden")}>
      <Row gutter={12} style={{ marginBottom: 18 }}>
        <Col span={8} style={{ textAlign: "right" }}>
          {$t("Prijsplan")}:{" "}
        </Col>
        <Col span={16}>
          <Flex>
            <Tag>{clubData.plan && $t(clubData.plan)}</Tag>
            <Button
              className="clickable"
              style={{ marginLeft: 6 }}
              shape="circle"
              size="small"
              target="_blank"
              href="/pricing"
            >
              <QuestionOutlined />
            </Button>
          </Flex>
          <p>{$t("Wil je het wijzigen, stuur ons een mail.")}</p>
        </Col>
      </Row>

      {clubData.plan === "small" && (
        <Form.Item
          label={$t("Totaal aantal gebruiker slots")}
          name="seats"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="number" style={{ maxWidth: 200 }} />
        </Form.Item>
      )}

      <Row gutter={12} style={{ marginBottom: 18 }}>
        <Col span={8} style={{ textAlign: "right" }}>
          {$t("Gebruikte gebruiker slots")}:{" "}
        </Col>

        <Col span={16}>{clubData.used_seats}</Col>
      </Row>

      <Row gutter={12} style={{ marginBottom: 18 }}>
        <Col span={8} style={{ textAlign: "right" }}>
          {$t("Algemene voorwaarden")}:{" "}
        </Col>

        <Col span={16}>
          {clubData.terms_name && (
            <>
              {$t("Getekend door")}: {clubData.terms_name}
            </>
          )}{" "}
          <a
            target="_blank"
            href={`https://www.gliding.app${config.isZweef && "/nl"}/terms`}
          >
            {$t("Zie text")}
          </a>
        </Col>
      </Row>
    </SettingCard>
  );
};

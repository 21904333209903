import { Descriptions, Modal, Tag } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { $t } from "~/i18n.js";
import { SluitButton } from "../buttons/SluitButton.jsx";
import { RecencyCurrencyBarometer } from "./recency.jsx";
import {
  QuestionCircleOutlined,
  QuestionCircleTwoTone,
} from "@ant-design/icons";
import { colors } from "~/theme/colors.js";

export const RecencyExplanationLine = ({ text }) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <span className="primary clickable" onClick={() => setVisible(true)}>
        {text}
      </span>
      <RecencyExplanationModal
        visible={visible}
        onClose={() => setVisible(false)}
      />
    </>
  );
};

export const RecencyExplanationModal = ({ visible, onClose }) => (
  <Modal
    width={800}
    title={$t("Uitleg vlieger Currency & Recency")}
    open={visible}
    onCancel={onClose}
    footer={[<SluitButton key="sluit" onClick={onClose} />]}
  >
    <div style={{ padding: 12 }}>
      <p className="spacer">
        {$t(
          "Currency en EASA SPL-brevet recencyeisen worden afgeleid uit je vlieghistorie die in het systeem bekend is. Vlieg je buiten clubverband of mis je vluchten in het systeem, dan kan je deze zelf uploaden."
        )}
      </p>

      <h3 className="spacer" style={{ marginTop: 6 }}>
        {$t("Currencybarometer")}
      </h3>

      <p className="spacer">
        {$t(
          "Je currency laat zien hoe stevig je recente vliegervaring is. Recente ervaring verhoogt het nemen van snelle en juiste beslissingen in onverwachte omstandigheden. Uit Duitse onderzoek is gebleken dat de meeste ongelukken veroorzaakt zijn door vliegers met weinig recente vliegervaring. Met dit in gedachte is de"
        )}{" "}
        <span className="bold">{$t("Currencybarometer")}</span>{" "}
        {$t("ontwikkeld")}.
      </p>

      <p style={{ marginBottom: 12 }}>
        {$t(
          "Met de aantal starts en uren die je de afgelopen 6 maanden gemaakt hebt (als gezagvoerder of instructie start als tweede-inzittende) wordt je currency berekent"
        )}
        :
      </p>

      <Descriptions size="small" bordered column={1} layout="horizontal">
        <Descriptions.Item
          label={<RecencyCurrencyBarometer pilot={{ currency: "groen" }} />}
        >
          {$t(
            "Recente vliegervaring is in orde. Houd de veiligheid goed in de gaten."
          )}
        </Descriptions.Item>

        <Descriptions.Item
          label={<RecencyCurrencyBarometer pilot={{ currency: "geel" }} />}
        >
          {$t(
            "Wees voorzichtig met onverwachte gebeurtenissen. Met weinig ervaring reageer je minder goed bij onverwachte gebeurtenissen. Let op bij   starts in onbekende gebieden en met types waar je niet veel op gevlogen hebt."
          )}
        </Descriptions.Item>

        <Descriptions.Item
          label={<RecencyCurrencyBarometer pilot={{ currency: "orange" }} />}
        >
          <p>
            {$t(
              "Onvoldoende recente ervaring, maar wel meer dan 10 uur of 15 starts gevlogen afgelopen 6 maanden. Voor ongeoefende zweefvliegers blijft  het advies: maak de eerste starts bij rustig weer."
            )}
          </p>
        </Descriptions.Item>

        <Descriptions.Item
          label={<RecencyCurrencyBarometer pilot={{ currency: "rood" }} />}
        >
          <p>
            {$t(
              "Onvoldoende recente ervaring. Vliegen kan riskant zijn. Voor ongeoefende zweefvliegers geldt maak de eerste starts bij rustig weer"
            )}
            .
          </p>
        </Descriptions.Item>

        <Descriptions.Item
          label={
            <RecencyCurrencyBarometer
              pilot={{
                currency: "rood",
                recency: { check_start_3mnd: "invalid" },
              }}
            />
          }
        >
          <p>
            {$t(
              "Onvoldoende recente ervaring. De Currencybarometer adviseert je om na 3 maanden zonder vliegen een checkstart te nemen met een instructeur"
            )}
            .
          </p>
        </Descriptions.Item>
      </Descriptions>

      <p style={{ marginTop: 12 }}>
        <span className="bold">{$t("Let op")}</span>,{" "}
        {$t(
          "de Currencybarometer is een hulpmiddel, gebruik het dus als zodanig. Bekijk"
        )}{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          title={$t("Zweefvliegopleiding")}
          href="https://zweefvliegopleiding.nl/index.php/1-menselijke-factoren"
        >
          {$t("deze link")}
        </a>{" "}
        {$t("voor meer achtergrond")}.
      </p>

      <h3 className="spacer" style={{ marginTop: 24 }}>
        {$t("EASA SPL-brevet recencyeisen")}
      </h3>

      <p className="spacer">
        {$t(
          "Voor het geldig houden van het SPL-brevet geldt als recente ervaringseis in de afgelopen 24 maanden. Alhoewel je brevet technisch gezien niet ongeldig kan zijn, mag je niet als Pilot In Command (PIC) je brevet  uitoefenen als je niet aan deze eisen voldaan hebt"
        )}
        .
      </p>

      <Descriptions size="small" bordered column={1} layout="horizontal">
        <Descriptions.Item
          label={
            <RecencyCurrencyBarometer
              pilot={{ recency: { spl: "invalid", checks: 0 } }}
            />
          }
        >
          {$t(
            "Minder dan 2 EASA trainingsvluchten in de afgelopen 24 maanden. Vraag een instructeur voor een checkstart, ook bekend als een EASA trainingsvlucht. Let op: noteer"
          )}{" "}
          <Tag>training</Tag> {$t("bij je vlucht in het systeem.")}
        </Descriptions.Item>

        <Descriptions.Item
          label={
            <RecencyCurrencyBarometer
              pilot={{ recency: { spl: "invalid", starts: 0 } }}
            />
          }
        >
          {$t("Minder dan 15 starts in de afgelopen 24 maanden")}.{" "}
          {$t(
            "Je moet de missende starts maken als tweede-inzittende onder supervisie van een instructeur of als solist onder de supervisie van een instructeur. Voor de vlucht moet je toestemming hebben van een instructeur, en na afloopt dient de vlucht afgetekend te worden door een instructeur"
          )}
          .
        </Descriptions.Item>

        <Descriptions.Item
          label={
            <RecencyCurrencyBarometer
              pilot={{ recency: { spl: "invalid", vliegduur: 0 } }}
            />
          }
        >
          {$t("Minder dan 5 vlieguur in de afgelopen 24 maanden")}.{" "}
          {$t(
            "Je moet de missende starts maken als tweede-inzittende onder supervisie van een instructeur of als solist onder de supervisie van een instructeur. Voor de vlucht moet je toestemming hebben van een instructeur, en na afloopt dient de vlucht afgetekend te worden door een instructeur"
          )}
          .
        </Descriptions.Item>

        <Descriptions.Item
          label={
            <RecencyCurrencyBarometer
              pilot={{ recency: { spl: "invalid", medical: "ontbreekt" } }}
            />
          }
        >
          {$t(
            "Medical kan verlopen zijn of niet in het systeem opgevoerd. Zolang je niet een geldig medical hebt mag je niet vliegen"
          )}
          .
        </Descriptions.Item>
      </Descriptions>

      <p style={{ marginTop: 12 }}>
        {$t(
          "Naast de algemene brevet recencyeisen kan je ook op bepaalde startmethodes onvoldoende ervaring hebben om deze als PIC uit te voeren"
        )}
        .{" "}
        {$t(
          "Je moet de missende starts maken als tweede-inzittende onder supervisie van een instructeur of als solist onder de supervisie van een instructeur. Voor de vlucht moet je toestemming hebben van een instructeur, en na afloopt dient de vlucht afgetekend te worden door een instructeur"
        )}
        .
      </p>

      <Descriptions size="small" bordered column={1} layout="horizontal">
        <Descriptions.Item
          label={
            <RecencyCurrencyBarometer
              pilot={{ recency: { lieraantekening: "invalid" } }}
            />
          }
        >
          {$t("Minder dan 5 starts met de lier in de afgelopen 24 maanden.")}
        </Descriptions.Item>

        <Descriptions.Item
          label={
            <RecencyCurrencyBarometer
              pilot={{ recency: { sleepaantekening: "invalid" } }}
            />
          }
        >
          {$t("Minder dan 5 starts met de sleep in de afgelopen 24 maanden.")}
        </Descriptions.Item>

        <Descriptions.Item
          label={
            <RecencyCurrencyBarometer
              pilot={{ recency: { zelfstartaantekening: "invalid" } }}
            />
          }
        >
          {$t(
            "Minder dan 5 starts met een zelf-starter in de afgelopen 24 maanden (TMG starts tellen mee)"
          )}{" "}
          .
        </Descriptions.Item>

        <Descriptions.Item
          label={
            <RecencyCurrencyBarometer
              pilot={{ recency: { tmgaantekening: "invalid" } }}
            />
          }
        >
          {$t(
            "Minder dan 12 starts, 6 uur én 1 EASA TMG trainingsvlucht (van 1 uur) met een TMG in de afgelopen 24 maanden. Deze trainingsvlucht mag verspreid worden over meerdere vluchten zolang die op dezelfde dag gebeuren."
          )}
          <br />
          <QuestionCircleTwoTone style={{ marginRight: 4 }} twoToneColor={colors.red} />
          {$t(
            "Bij een geldige FCL licentie (PPL of hoger) hoeft de vlieger niet te voldoen aan een minimum uren en starts om de TMG aantekening geldig te houden"
          )}
          .  (SFCL.160.c) 
          {$t(
            "Teken hiervoor in het systeem af dat de gebruiker een FCL licentie heeft."
          )}
        </Descriptions.Item>

        <Descriptions.Item
          label={
            <RecencyCurrencyBarometer
              pilot={{ recency: { "tmg-a-aantekening": "invalid" } }}
            />
          }
        >
          {$t(
            "Minder dan 5 uitgevoerde sleepvluchten in de afgelopen 24 maanden, genoteerd als tmg-a."
          )}
        </Descriptions.Item>

        <Descriptions.Item
          label={
            <RecencyCurrencyBarometer
              pilot={{ recency: { caraantekening: "invalid" } }}
            />
          }
        >
          {$t(
            "Minder dan 5 uitgevoerde starts met een auto in de afgelopen 24 maanden"
          )}
          .
        </Descriptions.Item>

        <Descriptions.Item
          label={
            <RecencyCurrencyBarometer
              pilot={{ recency: { bungeeaantekening: "invalid" } }}
            />
          }
        >
          {$t(
            "Minder dan 2 uitgevoerde bungeevluchten in de afgelopen 24 maanden"
          )}
          .
        </Descriptions.Item>
      </Descriptions>

      <p style={{ marginTop: 12 }}>
        {$t(
          "Indien je uitgecheckt bent om te mogen vliegen met passagiers gelden hier ook ervaringseisen. In de afgelopen 90 dagen dien je 3 starts te  hebben gemaakt, pas dan mag je weer passagiers vliegen. Let op: ook  brevethouders gelden als passagier"
        )}
        .
      </p>

      <Descriptions size="small" bordered column={1} layout="horizontal">
        <Descriptions.Item
          label={
            <RecencyCurrencyBarometer
              pilot={{ recency: { passagiervliegen: "invalid", pax: 2 } }}
            />
          }
        >
          {$t(
            "Minder dan 3 starts in de afgelopen 90 dagen als PIC of samen met een  intructeur, exclusief TMG."
          )}
        </Descriptions.Item>

        <Descriptions.Item
          label={
            <RecencyCurrencyBarometer
              pilot={{
                recency: { tmg_passagiervliegen: "invalid", tmg_pax: 2 },
              }}
            />
          }
        >
          {$t(
            "Minder dan 3 starts in de afgelopen 90 dagen als PIC of samen met een intructeur op een TMG kist."
          )}
        </Descriptions.Item>
      </Descriptions>

      <h3 className="spacer" style={{ marginTop: 24 }}>
        {$t("EASA FI(s) en FE(s) recencyeisen")}
      </h3>

      <p className="spacer">
        {$t(
          "Indien je instructeur bent, gelden de EASA FI(s) recencyeisen. Indien je niet recent bent, zal je ook een Assessment of Competency moeten  afleggen voordat je weer instructie mag geven. Raadpleeg article 360 (en 345) van de sailplane rulebook voor meer info. De data van je laatste  trainingen kan je doorgeven aan de Head of Training."
        )}
      </p>

      <Descriptions size="small" bordered column={1} layout="horizontal">
        <Descriptions.Item
          label={
            <RecencyCurrencyBarometer pilot={{ recency: { fis: "invalid" } }} />
          }
        >
          {$t(
            "Minder dan 30 FI(s) instructie-uren OF minder dan 60 FI(s) instructie-starts in de afgelopen 3 jaar."
          )}
        </Descriptions.Item>

        <Descriptions.Item
          label={
            <RecencyCurrencyBarometer pilot={{ recency: { fis: "invalid" } }} />
          }
        >
          {$t(
            "Minder dan 1 Instructor Refresher Training in de afgelopen 3 jaar."
          )}
        </Descriptions.Item>

        <Descriptions.Item
          label={
            <RecencyCurrencyBarometer pilot={{ recency: { fis: "invalid" } }} />
          }
        >
          {$t(
            'Minder dan 1 "Instruction flight under supervision" in de afgelopen 9 jaar.'
          )}
        </Descriptions.Item>

        <Descriptions.Item
          label={
            <RecencyCurrencyBarometer pilot={{ recency: { fes: "invalid" } }} />
          }
        >
          {$t(
            'Recency voor examinatoren die elke 5 jaar een "FE(S) refresher course" moeten volgen.'
          )}
        </Descriptions.Item>
      </Descriptions>
    </div>
  </Modal>
);

import { Checkbox, Modal, Tag, Timeline } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { HelpBubble } from "~/components/help/help.bubble";
import { TimeInput } from "~/components/timeInput/timeInput";
import { $t } from '~/i18n.js'
import { saveMaterialTab } from '../../../../redux/materiaal/materiaal.actions'

export const AddInspection = ({ visible, currentMaterial, closeModal, last_motor_minutes }) => {
  const dispatch = useDispatch()
  const { gegevens } = useSelector((state) => state.persist)

  const [inspectionOK, setInspection] = useState(false)
  const [total_motor_minutes, setMotorMinutes] = useState(null)
  const [signOK, setSignOK] = useState(false)

  useEffect(() => {
    setMotorMinutes(last_motor_minutes)
  }, [last_motor_minutes])

  const brevet_number = gegevens?.brevet_nummer

  const disabled = !inspectionOK || !signOK || !brevet_number

  const [saving, setSaving] = useState(false)
  const triggerAddInspectie = async () => {
    setSaving(true)
    const payload = { total_motor_minutes, brevet_number, datum: dayjs().format('YYYY-MM-DD') }
    const res = await dispatch(saveMaterialTab({ activeTab: 'meldingen', materialID: currentMaterial.id, payload }))
    if (res) {
      closeModal()
      Modal.success({
        title: $t('Inspectie voltooid'),
        content: $t('Veel plezier met vliegen')
      })
    }
    setSaving(false)
  }

  return (
    <Modal
      title={$t('Voer dagelijkse inspectie uit')}
      confirmLoading={saving}
      open={visible}
      onCancel={closeModal}
      okButtonProps={{ disabled }}
      okText={$t('Aftekenen')}
      width={400}
      onOk={triggerAddInspectie}
    >

      {currentMaterial.status !== 'in bedrijf' && <p>
        <span className='bold'>Status</span> {$t('niet inzetbaar: Inspectie kan niet worden uitgevoerd.')}
                                                  </p>}

      {currentMaterial.maintenance_status === 'red' && <p>
        <span className='bold'>Onderhoud</span> {$t('verlopen: Inspectie kan niet worden uitgevoerd.')}
                                                       </p>}

      {currentMaterial.maintenance_status !== 'red' && currentMaterial.status === 'in bedrijf' && <Timeline>

        <Timeline.Item color={inspectionOK ? 'green' : 'blue'}>
          <Checkbox checked={inspectionOK} onChange={(e) => setInspection(e.target.checked)}>
            <span className='bold'>{$t('Inspectie')}</span>:
            <span> {$t('Hierbij verklaar ik de inspectie voltooid is volgens het Aircraft Flight Manual, zoals beschreven in SAO.GEN.130.')}</span>
          </Checkbox>

        </Timeline.Item>

        {currentMaterial.kist_configuration !== 'Glider' && <Timeline.Item color={total_motor_minutes ? 'green' : 'blue'}>
          <p className='spacer'>
            <span className='bold'>{$t('Motortijd')}</span> (minuten) <HelpBubble
              content={$t('Voeg de laatste motor tijd toe, in minuten, om de in-service life van de motor componenten bij te houden.')}/>:
          </p>

          <div className='row'>
            <span style={{ width: 75 }}>{$t('Vorige')}:</span>
            <TimeInput readOnly value={last_motor_minutes} />
          </div>
          <div className='row' style={{ marginTop: 12 }}>
            <span style={{ width: 75 }}>{$t('Huidige')}:</span>
            <TimeInput readOnly={false} onChange={setMotorMinutes} value={total_motor_minutes} allowClear />
          </div>

        </Timeline.Item>}

        <Timeline.Item color={signOK ? 'green' : 'blue'}>

          {brevet_number
            ? <Checkbox checked={signOK} onChange={(e) => setSignOK(e.target.checked)}>
              <span>{$t('Hierbij teken ik digitaal de inspectie af met mijn Brevet nummer ${brevert}', { brevert: gegevens?.brevet_nummer || 'ONBEKEND' })}</span>
            </Checkbox>
            : <p><Tag color='red'>{$t('Let op')}</Tag> {$t('Geen Brevet nummer bekend, zonder nummer kan de dagelijkse inspectie niet worden afgetekend. Ga naar')} <Link to='/mijn_gegevens'>{$t('mijn gegevens')}</Link> {$t('om je   brevetnummer toe te voegen.')}</p>}

        </Timeline.Item>

                                                                                                  </Timeline>}

    </Modal>
  )
}

import { Table, Tag } from 'antd'
import React, { useMemo } from 'react'
import { i18n } from '~/i18n.js'
import { ActualFlightTime } from '~/components/clock/actualFlightTime.jsx'
import { LandingIcon } from '~/components/icon/icon.jsx'
import { defaultTableConfig } from '~/components/layout/table.jsx'
import { OGNlandingsTijd, OGNstartTijd } from '~/components/flight/sections/Ogn.jsx'
import { $t } from '~/i18n.js'
import { parseMinutes } from '~/lib/localize.js'

const OntbreektTag = () => (
  <Tag color='red' className='bounce'>
    {$t('ontbreekt')}
  </Tag>
)

const zweefkist = (flight_lookup, vliegerLookup) => ({
  title: $t('Zweefkist'),
  render: (_, flight) => {
    // only show koppeling zweefkist in case tmg-aerotow, otherwise it's a regular flight
    if (!flight.start_methode.endsWith('-a')) { return <Tag>{flight.start_methode}</Tag> }
    if (!flight.sleep_uuid) return OntbreektTag()

    // lookup zweefvlucht en show the data
    const zweefVlucht = flight_lookup[flight.sleep_uuid]
    if (!zweefVlucht || !zweefVlucht.registratie) return OntbreektTag()
    return (
      <p>
        {zweefVlucht.callsign} {zweefVlucht.registratie} <br />
        {zweefVlucht.type}
        <br />
        {zweefVlucht &&
        vliegerLookup &&
        zweefVlucht.betalend_lid_id &&
        vliegerLookup[zweefVlucht.betalend_lid_id]
          ? (
            <span>{vliegerLookup[zweefVlucht.betalend_lid_id]}</span>
            )
          : (
              OntbreektTag()
            )}
      </p>
    )
  }
})

const Tijden = () => ({
  title: $t('Tijden'),
  width: 65,
  render: (_, flight) => (
    <div>
      {flight.start_tijd && (
        <OGNstartTijd
          ogn_indicator={flight.start_ogn}
          time={flight.start_tijd}
        />
      )}
      {flight.landings_tijd && (
        <OGNlandingsTijd
          marginLeft={0}
          ogn_indicator={flight.landings_ogn}
          time={flight.landings_tijd}
        />
      )}
    </div>
  )
})

const SleepColumns = (flight_lookup, sleep_category, vliegerLookup) => {
  const columns = [
    {
      title: $t('Sleepkist'),
      render: (_, flight) => {
        if (!flight.registratie) return OntbreektTag()
        return (
          <p>
            {flight.callsign} {flight.registratie} <br />
            {flight.type}
            <br />
            {flight.gezagvoerder_naam
              ? (
                <span>{flight.gezagvoerder_naam}</span>
                )
              : (
                  OntbreektTag()
                )}
          </p>
        )
      }
    },
    zweefkist(flight_lookup, vliegerLookup),
    Tijden(),
    {
      title: $t('Duur'),
      dataIndex: 'vluchtduur',
      width: 70,
      render: (text, flight) => {
        if (flight.landings_tijd) return parseMinutes(text)
        if (flight.start_tijd) return <ActualFlightTime flight={flight} />
      }
    },
    {
      title: $t('Hoogte'),
      dataIndex: 'height',
      width: 80,
      render: (value) => i18n.parseLocaleHeightString(value)
    }
  ]

  if (sleep_category) {
    columns.push({
      title: sleep_category ? sleep_category.split(',')[0] : '',
      dataIndex: 'category',
      width: null,
      render: (_, flight) => (
        <>
          {flight.category && <Tag>{flight.category}</Tag>}
          {flight.vertrek_vliegveld &&
            flight.aankomst_vliegveld &&
            flight.vertrek_vliegveld !== flight.aankomst_vliegveld && (
              <Tag color='green'>
                <LandingIcon size={12} />
                <span style={{ marginLeft: 4 }}>
                  {flight.aankomst_vliegveld}
                </span>
              </Tag>
          )}
        </>
      )
    })
  }
  return columns
}

export const SleepTable = ({
  allFlights,
  sleep_cat,
  setActiveSleep,
  vliegers,
  sleep_kisten
}) => {
  const vliegerLookup = useMemo(
    () =>
      vliegers &&
      vliegers.reduce((all, v) => {
        all[v.id] = v.name
        return all
      }, {}),
    [vliegers]
  )

  const filterFlights = (_flights, _sleepkisten) => {
    const kistLookup =
      _sleepkisten &&
      _sleepkisten.reduce((all, k) => {
        all[k.id] = k.id
        return all
      }, {})

    const sleepFlights = []
    const zweefFlightLookup = {}

    _flights &&
      _flights.forEach((flight) => {
        if (
          flight.start_methode.endsWith("-a") ||
          (kistLookup && flight.kist_id in kistLookup)
        ) {
          sleepFlights.push(flight);
        } else {
          zweefFlightLookup[flight.uuid] = flight;
        }
      })
    sleepFlights.sort((a, b) => b.start_tijd_int - a.start_tijd_int)
    return { sleepFlights, zweefFlightLookup }
  }

  const { sleepFlights, zweefFlightLookup } = useMemo(
    () => filterFlights(allFlights, sleep_kisten),
    [allFlights, sleep_kisten]
  )

  return (
    <Table
      // loading={loading}
      style={{ marginTop: 12, width: '100%', overflowX: 'auto' }}
      rowKey='id'
      pagination={defaultTableConfig}
      className='table--clickable'
      size='small'
      dataSource={sleepFlights}
      columns={SleepColumns(zweefFlightLookup, sleep_cat, vliegerLookup)}
      onRow={(flight) => {
        return {
          onClick: () => setActiveSleep(flight)
        }
      }}
    />
  )
}

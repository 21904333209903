import React, { useState, useEffect } from "react";
import { Input } from "antd";

const PincodeInput = ({
  length = 4,
  value = "",
  disabled = false,
  onComplete = (value = "") => {},
}) => {
  const [values, setValues] = useState(Array(length).fill(""));

  useEffect(() => {
    if (value) {
      setValues(value.split(""));
    } else {
      setValues(Array(length).fill(""));
    }
  }, [value]);

  const handleChange = (value, index) => {
    if (value.length > 1) return; // Restrict input to 1 character

    const newValues = [...values];
    newValues[index] = value;

    setValues(newValues);

    if (value && index < length - 1) {
      document.getElementById(`pin-${index + 1}`).focus(); // Move focus to the next input
    }

    if (newValues.every((v) => v !== "") && onComplete) {
      onComplete(newValues.join("")); // Notify parent if all inputs are filled
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !values[index] && index > 0) {
      document.getElementById(`pin-${index - 1}`).focus(); // Move focus to the previous input
    }
  };

  return (
    <div style={{ display: "flex", gap: "8px" }}>
      {values.map((value, index) => (
        <Input
          key={index}
          id={`pin-${index}`}
          value={value}
          type={"password"}
          disabled={disabled}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          maxLength={1}
          style={{
            width: "40px",
            height: "40px",
            textAlign: "center",
            fontSize: "16px",
          }}
        />
      ))}
    </div>
  );
};

export default PincodeInput;

import { EditOutlined, UserOutlined } from "@ant-design/icons";
import { Alert, Button, Descriptions, Divider, Input, Modal, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { $t, i18n } from "~/i18n.js";
import { parseMinutesLong } from "../../lib/localize.js";
import { colors } from "../../theme/colors.js";
import { SluitButton } from "../buttons/SluitButton.jsx";
import { IGCButton } from "../IGC/IGC.button.jsx";
import { FlightTags } from "./sections/FlightTags.jsx";
import { OGNtimeIcon } from "./sections/Ogn.jsx";
import { PilotLink } from "./sections/PilotLink.jsx";
import { FlightDetails } from "./sections/flightDetails.jsx";
import { SignFlightCollapse } from "./sections/signFlight.jsx";

const descriptionRow = (value, label, show, postfix, renderAsAlert) =>
  show && (
    <Descriptions.Item label={label}>
      {renderAsAlert ? (
        <>
          <Tag icon={<UserOutlined />} color="blue">
            {value} {postfix}
          </Tag>
        </>
      ) : (
        <>
          {value} {postfix}
        </>
      )}
    </Descriptions.Item>
  );

export const FlightViewModal = ({
  onClose,
  flight,
  currentUser,
  updateFlight,
  onUpdateFlight,
  showPilotLink = false,
  hideSigning = false,
}) => {
  if (!flight) return null;

  const [signed, setSigned] = useState(null);
  const [notitie, setNotitie] = useState({});
  useEffect(() => {
    setNotitie(flight ? flight.notitie : "");
    setSigned(flight.signed);
  }, [flight]);

  const [loading, setLoading] = useState(false);
  const saveNotitie = async () => {
    setLoading(true);
    await updateFlight({ uuid: flight.uuid, notitie });
    onUpdateFlight({ flight });
    setLoading(false);
    onClose();
  };

  const flightDatum = isNaN(flight.datum) ? `"${flight.datum}"` : flight.datum;

  if (flight) {
    return (
      <Modal
        width={550}
        title={`Vlucht ${flightDatum} ${
          flight.volg_nummer ? " #" + flight.volg_nummer : ""
        }`}
        open={!!flight}
        onCancel={onClose}
        className="scrollable"
        footer={[
          <Button
            type="primary"
            key="edit"
            disabled={flight.is_prive || !flight.dag_id}
            href={`/start/${flight.dag_id}/${flight.uuid}`}
            icon={<EditOutlined />}
          >
            {$t("Wijzig")}
          </Button>,
          <SluitButton key="sluit" onClick={onClose} />,
        ]}
      >
        {flight._errors?.map((error, key) => (
          <Alert key={key} message={error} type="error" showIcon />
        ))}

        <Descriptions size="small" column={1} layout="horizontal">
          {descriptionRow(flightDatum, $t("Datum"), true)}
          {descriptionRow(flight.starts, $t("Starts"), flight.starts > 1)}
          {descriptionRow(
            <span>
              <OGNtimeIcon ogn_indicator={flight.start_ogn} />
              {flight.start_tijd}
            </span>,
            $t("Start"),
            flight.start_tijd
          )}
          {descriptionRow(
            <span>
              <OGNtimeIcon ogn_indicator={flight.landings_ogn} />
              {flight.landings_tijd}
            </span>,
            $t("Landing"),
            flight.landings_tijd
          )}
          {descriptionRow(
            parseMinutesLong(flight.vluchtduur),
            $t("Vluchtduur"),
            true,
            ""
          )}
          {descriptionRow(
            i18n.parseLocaleHeightString(flight.height),
            $t("Hoogte"),
            true,
            ""
          )}

          <Descriptions.Item>
            <Tag color={colors.primary_light}>{$t(flight.start_methode)}</Tag>
          </Descriptions.Item>
        </Descriptions>

        <Divider orientation="left" plain>
          {$t("Kist")}:
        </Divider>

        <Descriptions size="small" column={1} layout="horizontal">
          {descriptionRow(flight.callsign, $t("Callsign"), flight.callsign)}
          {descriptionRow(
            flight.registratie,
            $t("Registratie"),
            flight.registratie
          )}
          {descriptionRow(flight.type, $t("Type"), flight.type)}
        </Descriptions>

        <Divider orientation="left" plain>
          {$t("Vliegers")}:
        </Divider>

        <Descriptions size="small" column={1} layout="horizontal">
          <Descriptions.Item label={$t("Gezagvoerder")}>
            {showPilotLink ? (
              <PilotLink
                name={flight.gezagvoerder_naam}
                id={flight.gezagvoerder_id}
                object={flight.gezagvoerder}
              />
            ) : (
              flight.gezagvoerder_naam
            )}
          </Descriptions.Item>
          <Descriptions.Item label={$t("Tweede-inzittende")}>
            {showPilotLink ? (
              <PilotLink
                name={flight.tweede_inzittende_naam}
                id={flight.tweede_inzittende_id}
                object={flight.tweede_inzittende}
              />
            ) : (
              flight.tweede_inzittende_naam
            )}
          </Descriptions.Item>

          <Descriptions.Item>
            <FlightTags flight={flight} pilot={currentUser} />
          </Descriptions.Item>
        </Descriptions>

        <Divider orientation="left" plain>
          {$t("Details")}:
        </Divider>

        <Descriptions size="small" column={1} layout="horizontal">
          {descriptionRow(flight.vertrek_vliegveld, $t("Van"), true)}
          {descriptionRow(flight.aankomst_vliegveld, $t("Naar"), true)}

          {!flight.is_prive && (
            <Descriptions.Item label="IGC">
              <IGCButton
                flight={flight}
                title={$t("Open kaart")}
                onUpdateFlight={onUpdateFlight}
              />
            </Descriptions.Item>
          )}

          {descriptionRow(flight.bijzonderheden, $t("Bijzonderheden"), true)}

          {!updateFlight &&
            flight.is_prive &&
            descriptionRow(flight.notitie, $t("Privé notitie"), true)}
        </Descriptions>

        {!flight.is_prive && <FlightDetails flight={flight} />}

        {!hideSigning && (
          <SignFlightCollapse
            flight={{ ...flight, signed: signed || flight.signed }}
            updateFlight={({ flight }) => {
              setSigned(flight.signed);
              onUpdateFlight && onUpdateFlight({ flight });
              onClose();
            }}
          />
        )}

        {updateFlight && (
          <>
            <Divider orientation="left" plain>
              {$t("Notitie")}:
            </Divider>

            <p>
              {$t("Voeg eigen notitie toe (alleen zichtbaar voor vliegers)")}:
            </p>

            <Input.TextArea
              rows={4}
              value={notitie}
              onChange={(elm) => setNotitie(elm.target.value.substr(0, 500))}
            />
            <Button
              style={{ marginTop: 6 }}
              onClick={() => saveNotitie()}
              type="primary"
              loading={loading}
              disabled={notitie === flight.notitie}
            >
              {$t("Sla notitie op")}
            </Button>
          </>
        )}

        {flight.uuid && (
          <p className="gray small" style={{ marginTop: 12 }}>
            Uuid: {flight.uuid}
          </p>
        )}
      </Modal>
    );
  }
  return null;
};

import { api } from '~/lib/api.js'
import { text_to_speech } from '~/lib/speech.js'

export const getBaseData = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'FLIGHTS_PENDING'
      })
      const data = await api.get('flights/base_data.json')
      data && dispatch({
        type: 'SET_BASE_DATA',
        vliegers: data.vliegers,
        default_vliegers: data.default_vliegers,
        kisten: data.kisten,
        bevoegdheden: data.bevoegdheden,
        group_name_lookup: data.group_name_lookup
      })
    } catch (error) {
      dispatch({
        type: 'FLIGHTS_ERROR'
      })
    }
  }
}

export const getFlights = ({ dag_id }) => async (dispatch) => {
  dispatch({
    type: 'FLIGHTS_PENDING'
  })

  const res = await api.get(`flights/${dag_id}/flights.json`)

  res && dispatch({
    type: 'FLIGHTS_SUCCESS',
    allFlights: res.allFlights?.length > 0 ? res.allFlights : []
  })

  res && dispatch({
    type: 'UPDATE_OGN_MODE',
    ogn_mode: res.ogn_mode,
    default_flight_cat: res.default_flight_cat,
    default_sleep_cat: res.default_sleep_cat,
    default_tmg_cat: res.default_tmg_cat
  })

  return res && res.allFlights
}

export const getFlightStats = ({ ...params }) => async (dispatch) => {
  dispatch({
    type: 'FLIGHT_STATS_PENDING'
  })

  const query_string = new URLSearchParams()
  Object.entries(params).map(([key, value]) => {
    if (value !== undefined && value !== null && value !== '') {
      query_string.append(key, value)
    }
  })

  const res = await api.get('flightstats.json?' + query_string.toString())

  dispatch({
    type: 'FLIGHT_STATS_SUCCESS',
    flights: res && res.results.length > 0 ? res.results : [],
    total_starts: res && res.total_starts ? res.total_starts : 0,
    total_pic_starts: res && res.total_pic_starts ? res.total_pic_starts : 0,
    total_dbo_starts: res && res.total_dbo_starts ? res.total_dbo_starts : 0,
    total_time: res && res.total_time ? res.total_time : 0,
    total_dbo_time: res && res.total_dbo_time ? res.total_dbo_time : 0,
    total_pic_time: res && res.total_pic_time ? res.total_pic_time : 0
  })

  return res
}

export const newFlight = (data) => async (dispatch) => {
  dispatch({
    type: 'NEW_FLIGHT',
    flightData: data
  })
}

export const updateFlight = (data, saveToQueueWhenOffline) => async (dispatch) => {
  // sometimes you only want to update
  if (data.onlyUpdateRedux) {
    return dispatch({
      type: 'UPDATE_FLIGHT',
      flightData: data
    })
  }

  // if you are not online, save to queue and return
  if (saveToQueueWhenOffline && !window.navigator.onLine) {
    dispatch({
      type: 'ADD_TO_QUEUE',
      data
    })

    // if we must delete, do that, return null. The deletion request is put in queue
    if (data?.action === 'delete') {
      dispatch({
        type: 'DELETE_FLIGHT',
        flightData: data
      })
      return null
    }

    dispatch({
      type: 'UPDATE_FLIGHT',
      flightData: data
    })
    return data
  }

  // save flight to api if we are online
  const flightData = await api.post('flights/save_flight.json', data, !saveToQueueWhenOffline)

  // update data on correct return from server, or if you are allowed to save to queue
  if (flightData || saveToQueueWhenOffline) {
    if (data?.action === 'delete' || flightData?.action == 'delete') {
      dispatch({
        type: 'DELETE_FLIGHT',
        flightData: data
      })
      return null
    }

    // after save always save to active Flight, overwriting flightData fields from server prio 1
    dispatch({
      type: 'UPDATE_FLIGHT',
      flightData: { ...data, ...flightData }
    })
  }

  // if no result, then error is created and we save to queue
  if (saveToQueueWhenOffline && !flightData) {
    dispatch({
      type: 'ADD_TO_QUEUE',
      uuid: data.uuid,
      data
    })
  }

  // add base kist to data if required and returned
  flightData && data.add_kist && flightData.kist_id && dispatch({
    type: 'ADD_KIST_TO_DATA',
    kist: {
      id: flightData.kist_id,
      callsign: flightData.callsign,
      registratie: flightData.registratie,
      type: flightData.type,
      category: 'prive',
      pilots: 2
    }
  })

  return flightData
}

export const saveFlightFromQueue = (data) => async (dispatch) => {
  // save flight data from queue
  if (window.navigator.onLine) {
    const flightData = await api.post('flights/save_flight.json', { ...data, skip_currency: true, queue: true }, data.messageOnNetworkIssue)
    // after save remove from queue
    flightData && dispatch({
      type: 'REMOVE_FROM_QUEUE',
      uuid: data.uuid
    })
    flightData && dispatch({
      type: 'UPDATE_FLIGHT',
      flightData: { ...data, ...flightData }
    })
    if (data?.action === 'delete' || flightData?.action == 'delete') {
      dispatch({
        type: 'DELETE_FLIGHT',
        flightData: data
      })
      return null
    }
    flightData?.delete_uuid && dispatch({
      type: 'DELETE_FLIGHT',
      flightData: { uuid: flightData.delete_uuid }
    })
    return flightData
  }
}

export const deleteFromQueue = (data) => async (dispatch) => {
  dispatch({
    type: 'REMOVE_FROM_QUEUE',
    uuid: data.uuid
  })
}

export const updateWebsocketEvent = (data, muteSound) => async (dispatch) => {
  console.log('Websocket: receiving data: ', data)
  !muteSound && data.message && data.flight && text_to_speech(data.flight.callsign, data.message)

  if (data.flight) {
    if (data.flight.is_deleted) {
      dispatch({
        type: 'DELETE_FLIGHT',
        flightData: data.flight
      })
    } else {
      dispatch({
        type: 'UPDATE_FLIGHT_FROM_WEBSOCKET',
        flight: data.flight
      })
    }
  }
  data.flightlog && dispatch({
    type: 'ADD_FLIGHTLOG',
    flightlog: data.flightlog
  })
  data.ogn_mode && dispatch({
    type: 'UPDATE_OGN_MODE',
    ogn_mode: data.ogn_mode,
    default_flight_cat: data.default_flight_cat,
    default_sleep_cat: data.default_sleep_cat,
    default_tmg_cat: data.default_tmg_cat
  })
}

export const getFlightLogs = ({ datum }) => async (dispatch) => {
  const res = await api.post('flightlogs/get_logs.json', { datum })
  {
    res && dispatch({
      type: 'UPDATE_FLIGHTLOG',
      flightlogs: res.length > 0 ? res : []
    })
  }
}

export const linkLogToFlight = ({ id, flight_uuid }) => async (dispatch) => {
  // let res = await api.post('flightlogs/get_logs.json', {dag_id});
  // dispatch({
  //   type: 'FLIGHTS_SUCCESS',
  //   allFlights: res && res.allFlights.length > 0 ? res.allFlights : [],
  // })
  console.log('TODO')
}

export const saveOGNmode = ({ ogn_mode, dag_id, default_flight_cat, default_sleep_cat, default_tmg_cat }) => async (dispatch) => {
  const data = await api.post('flights/save_ogn_mode.json', { ogn_mode, dag_id, default_flight_cat, default_sleep_cat, default_tmg_cat })
  data && dispatch({
    type: 'UPDATE_OGN_MODE',
    ogn_mode: data.ogn_mode,
    default_flight_cat: data.default_flight_cat,
    default_sleep_cat: data.default_sleep_cat,
    default_tmg_cat: data.default_tmg_cat
  })
  return data
}

import React from "react";
import { _parseDate } from "~/lib/localize.js";
import { FlightTags } from "~/components/flight/sections/FlightTags.jsx";
import { OGNtimeIcon } from "~/components/flight/sections/Ogn.jsx";
import { $t, i18n } from "~/i18n.js";
import { PilotLink } from "~/components/flight/sections/PilotLink.jsx";


export const AdminFlightColumns = (showLink=false) => {
  return [
    {
      title: $t("Datum"),
      dataIndex: "datum",
      width: 80,
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        new Date(a.datum + " " + a.start_tijd) -
        new Date(b.datum + " " + b.start_tijd),
      render: (text) => _parseDate(text),
    },
    {
      title: $t("Vertrek"),
      dataIndex: "vertrek_vliegveld",
      sorter: (a, b) =>
        ("" + a.vertrek_vliegveld).localeCompare(b.vertrek_vliegveld),
    },

    { title: $t("Callsign"), dataIndex: "callsign" },
    { title: $t("Registratie"), dataIndex: "registratie" },
    {
      title: $t("Gezagvoerder"),
      dataIndex: "gezagvoerder_naam",
      width: 200,
      sorter: (a, b) =>
        ("" + a.gezagvoerder_naam).localeCompare(b.gezagvoerder_naam),
      responsive: ["md"],
      render: (text, flight) =>
        showLink ? (
          <PilotLink
            name={flight.gezagvoerder_naam}
            id={flight.gezagvoerder_id}
            object={flight.gezagvoerder}
          />
        ) : (
          text
        ),
    },

    {
      title: $t("Pax/DBO"),
      dataIndex: "tweede_inzittende_naam",
      width: 200,
      sorter: (a, b) =>
        ("" + a.tweede_inzittende_naam).localeCompare(b.tweede_inzittende_naam),
      responsive: ["md"],
      render: (text, flight) =>
        showLink ? (
          <PilotLink
            name={flight.tweede_inzittende_naam}
            id={flight.tweede_inzittende_id}
            object={flight.tweede_inzittende}
          />
        ) : (
          text
        ),
    },

    {
      title: $t("Meth."),
      dataIndex: "start_methode",
      render: (t) => $t(t),
    },

    {
      title: $t("Start"),
      dataIndex: "start_tijd",
      width: 70,
      render: (text, flight) => (
        <div className="row" style={{ width: 65 }}>
          <OGNtimeIcon ogn_indicator={flight.start_ogn} />
          {text}
        </div>
      ),
    },

    {
      title: $t("Landing"),
      dataIndex: "landings_tijd",
      width: 70,
      render: (text, flight) => (
        <div className="row" style={{ width: 65 }}>
          <OGNtimeIcon ogn_indicator={flight.landings_ogn} />
          {text}
        </div>
      ),
    },
    {
      title: $t("Hoogte"),
      dataIndex: "height",
      sorter: (a, b) => a.height - b.height,
      render: (value) => i18n.parseLocaleHeightString(value),
    },
    {
      render: (text, flight) => (
        <FlightTags flight={flight} pilot={null} showNotitie={false} />
      ),
    },
  ];
};

import { EditOutlined, ExclamationCircleTwoTone, EyeInvisibleOutlined } from '@ant-design/icons'
import { Divider } from 'antd'
import React from 'react'
import { $t } from '~/i18n.js'
import { _parseDate } from '../../../lib/localize'
import { colors } from '../../../theme/colors'
import { HelpBubble } from '../../help/help.bubble'
import { FlightOpenIcon } from "../../flight/sections/flightOpenIcon";

export const Notities = ({ notities, dagNotificatie, alleenPublic, openNotitie, currentUserID, flightNote }) => {
  return (
    <div
      style={{
        maxHeight: 240,
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {alleenPublic && (
        <p style={{ marginBottom: 6 }}>
          {notities ? notities.length : 0} {$t("gedeelde notities")}{" "}
          <HelpBubble
            content={$t(
              "Instructeurs kunnen kiezen of ze een notitie met jou willen delen of niet."
            )}
          />
        </p>
      )}
      {notities &&
        notities.length > 0 &&
        notities.map((notitie, key) => {
          const canEdit =
            openNotitie && notitie.instructeur_id === currentUserID;
          return (
            <div key={key} className="spacer">
              <div className="row">
                {notitie.is_kritiek && (
                  <ExclamationCircleTwoTone
                    style={{ marginRight: 6 }}
                    twoToneColor="#eb2f96"
                  />
                )}

                {!dagNotificatie && (
                  <span
                    className="small"
                    style={{
                      marginRight: 6,
                      color: notitie.is_kritiek
                        ? colors.secondary
                        : colors.darkgray_light,
                    }}
                  >
                    {_parseDate(notitie.datum)}
                  </span>
                )}

                <div
                  className={`row ${canEdit && "clickable"}`}
                  onClick={() => canEdit && openNotitie(notitie)}
                >
                  {canEdit && (
                    <EditOutlined style={{ marginRight: 4, fontSize: 12 }} />
                  )}

                  <p className="bold small">{notitie.instructeur_naam}</p>
                </div>
              </div>
              <p>
                {!flightNote && notitie.flight_uuid && (
                  <FlightOpenIcon flight_uuid={notitie.flight_uuid} />
                )}
                {!dagNotificatie && notitie.is_prive && (
                  <span style={{ marginRight: 6 }}>
                    <EyeInvisibleOutlined />
                  </span>
                )}
                {notitie.bericht}
              </p>

              <Divider style={{ margin: "2px 0" }} />
            </div>
          );
        })}
    </div>
  );
}

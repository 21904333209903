import { Divider } from 'antd'
import React from 'react'
import { DescriptionRow } from "~/components/descriptionRow/descriptionRow";
import { $t } from '~/i18n.js'

export const CardRollen = ({ aanmeldingen, groups_aanmeld, group_name_lookup }) => {
  return (
    <div>

      <DescriptionRow title={$t('Vliegers')}>{aanmeldingen.rollen.vliegers}</DescriptionRow>

      {aanmeldingen.rollen.nieuw > 0 &&
        <DescriptionRow title={$t('Nieuwe leden')}>{aanmeldingen.rollen.nieuw}</DescriptionRow>}

      <Divider style={{ margin: '12px 0' }} />

      <DescriptionRow title={$t('Instructeur')}>{aanmeldingen.rollen.as_instructeur}</DescriptionRow>
      <DescriptionRow title={$t('DBO')}>{aanmeldingen.rollen.dbo || 0}</DescriptionRow>
      <DescriptionRow title={$t('Solist')}>{aanmeldingen.rollen.solist || 0}</DescriptionRow>
      <DescriptionRow title={$t('Brevet')}>{aanmeldingen.rollen.brevet || 0}</DescriptionRow>
      <DescriptionRow title={$t('Sleepvlieger')}>{aanmeldingen.rollen.as_sleepvlieger}</DescriptionRow>

      <Divider style={{ margin: '12px 0' }} />

      {groups_aanmeld && groups_aanmeld.length > 1 && groups_aanmeld.split(',').map(
        (g, idx) =>
          <DescriptionRow key={idx} title={group_name_lookup[g] || g}>{aanmeldingen.rollen[g] || 0}</DescriptionRow>
      )}
    </div>
  )
}

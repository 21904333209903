import { Input, InputNumber } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import { $t } from '~/i18n.js'

import { HelpBubble } from '~/components/help/help.bubble.jsx'
import { OGNlandingsTijd, OGNstartTijd } from './Ogn.jsx'

export const SectionTijd = ({ flight, saveFlight, readOnly, disableNumberOfStarts }) => {
  const includesBlocktime = flight?.start_methode?.startsWith('tmg') || flight?.blocktime > 0

  const saveTime = (data) => {
    // custom adjust landings_tijd if start_tijd has been given
    if (data.start_tijd && data.vluchtduur && data.vluchtduur > 0) {
      data.landings_tijd = dayjs(data.start_tijd, 'HH:mm')
        .add(data.vluchtduur, 'minutes')
        .format('HH:mm')
    } else if (data.start_tijd && data.landings_tijd) {
      // correct negative if both times are given
      data.vluchtduur = parseInt((dayjs(data.landings_tijd, 'HH:mm') - dayjs(data.start_tijd, 'HH:mm')) / 60000)
      if (!data.vluchtduur || isNaN(data.vluchtduur) || data.vluchtduur < 0) {
        data.vluchtduur = 0
        data.landings_tijd = data.start_tijd
      }

      // if blocktime has not yet been filled out, correct. for TMG it includes startup and shutdown time, as such add customary 10 minutes
      if (includesBlocktime) data.blocktime = (data.vluchtduur + 10)
    }
    saveFlight(data)
  }

  return (
    <div>
      {(!disableNumberOfStarts || flight.start_methode === 'tmg') && <InputNumber
        disabled={readOnly}
        min={1}
        style={{ width: 200 }}
        value={flight.starts}
        onChange={(starts) => {
          const newData = { starts, tweede_inzittende_naam: '', is_training: false }

          // for multiple starts, reset time if non-tmg
          if (starts > 1 && flight.start_methode && !flight.start_methode?.startsWith('tmg')) {
            newData.start_tijd = null
            newData.landings_tijd = null
          }
          saveFlight(newData)
        }}
        formatter={value => `${value} ${$t('start')}`}
        parser={value => value.replace(/\D/g, '')}
                                                                     />}

      {(flight.starts === 1 || (flight.start_methode && flight.start_methode?.startsWith('tmg'))) &&
        <div>

          <div className='row' style={{ marginTop: 6 }}>
            <p style={{ width: 80 }}>{$t('Start')}:</p>
            <Input
              disabled={readOnly}
              type='time'
              allowClear
              style={{ width: 120, padding: '2px 4px', marginRight: 6 }}
              placeholder='hh:mm'
              value={flight.start_tijd || ''}
              onChange={(element) => {
                saveTime({
                  start_ogn: -1,
                  start_tijd: element.target.value || null,
                  landings_tijd: flight.landings_tijd
                })
              }}
            />
            <OGNstartTijd ogn_indicator={flight.start_ogn} />
          </div>

          <div className='row' style={{ marginTop: 6 }}>
            <p style={{ width: 80 }}>{$t('Landing')}:</p>
            <Input
              disabled={readOnly}
              type='time'
              allowClear
              style={{ width: 120, padding: '2px 4px', marginRight: 6 }}
              placeholder='hh:mm'
              value={flight.landings_tijd || ''}
              onChange={(element) => {
                saveTime({
                  // only set action if no landingstime has been set, in which case server will overrule landingstime
                  action: flight.landings_tijd ? '' : 'land',
                  landings_ogn: -1,
                  start_tijd: flight.start_tijd,
                  landings_tijd: element.target.value || null
                })
              }}
            />
            <OGNlandingsTijd ogn_indicator={flight.landings_ogn} marginLeft={0} />
          </div>

        </div>}

      <div className='row' style={{ marginTop: 6 }}>
        <p style={{ width: 80 }}>{$t('Vluchtduur')}:</p>
        <InputNumber
          disabled={readOnly}
          min={0}
          style={{ width: 120 }}
          value={flight.vluchtduur}
          onChange={(vluchtduur) => {
            saveTime({
              vluchtduur,
              start_tijd: flight.start_tijd,
              landings_ogn: 0
            })
          }}
          formatter={value => `${value} min`}
          parser={value => value.replace(/\D/g, '')}
        />
      </div>

      {includesBlocktime && <div className='row' style={{ marginTop: 6 }}>
        <p style={{ width: 80 }}>{$t('Bloktijd')}:</p>
        <InputNumber
          disabled={readOnly}
          min={0}
          style={{ width: 120, marginRight: 6 }}
          value={flight.blocktime}
          onChange={(blocktime) => {
            saveTime({
              blocktime
            })
          }}
          formatter={value => `${value} min`}
          parser={value => value.replace(/\D/g, '')}
        />
        <HelpBubble content={$t('Bloktijd voor TMG vluchten bevat opstart en afkoel tijd en wordt gebruikt voor EASA recency berekeningen.')} />
      </div>}
    </div>
  )
}

import { $t } from '~/i18n.js'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { PlusOutlined } from '@ant-design/icons'
import { Button, Empty, Input, Switch, Tag } from 'antd'

import { SearchTags } from "~/components/searchTag/searchTag";
import { saveMaterialTab } from '../../../../redux/materiaal/materiaal.actions'
import { AddDocumentModal } from './addDocument'
import { DocumentList } from './documentList'

export const MaterialDocuments = ({ currentMaterial, documents, readOnly }) => {
  // setup search for kisten you are allowed access to
  const [search, setSearch] = useState('')
  const [searchTag, setSearchTag] = useState([])
  const [searchArchive, setSearchArchive] = useState(false)

  // reduce unique cluster names in tabs
  let allTags = {}
  documents.forEach(t => allTags[t.tag] = { name: t.tag })
  const allTagsList = Object.keys(allTags)
  allTags = allTagsList.map(v => allTags[v])

  const list_documents = documents?.filter(doc => {
    return doc.is_archived === searchArchive && (searchTag.length === 0 || searchTag.some(t => doc.tag === t))
  })

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const setupDocuments = async () => {
    setLoading(true)
    const payload = { action: 'setup' }
    await dispatch(saveMaterialTab({ activeTab: 'documents', materialID: currentMaterial.id, payload }))
    setLoading(false)
  }

  const saveDocuments = async (documents) => {
    const payload = { documents }
    await dispatch(saveMaterialTab({ activeTab: 'documents', materialID: currentMaterial.id, payload }))
  }

  const [readOnlyMode, setReadOnlyMode] = useState(true)

  const [openDocument, setOpenDocument] = useState(null)
  return (
    <div className='column' style={{ gap: 24, alignItems: 'flex-start' }}>

      {openDocument && <AddDocumentModal
        document={openDocument} currentMaterial={currentMaterial} defaultTag='boorddocument' existingTags={allTagsList}
        closeModal={() => setOpenDocument(null)}
                       />}

      <div className='row' style={{ gap: 12, flexWrap: 'wrap', width: '100%' }}>
        <Input.Search
          style={{ maxWidth: 250 }}
          value={search}
          allowClear
          size='small'
          placeholder={$t('Zoek op naam')}
          onChange={(event) => setSearch(event.target.value.toLowerCase())}
        />

        <SearchTags
          items={allTags}
          searchTags={searchTag}
          setSearchTags={setSearchTag}
        />

        <Tag
          className='clickable' color='red' onClick={() => setSearchArchive(!searchArchive)}
          style={{ opacity: !searchArchive ? 0.3 : 1 }}
        >{$t('archief')}
        </Tag>

        {!readOnly &&
          <p className='row' style={{ width: 120 }}>{$t('Wijzig')}:
            <Switch
              size='small' style={{ marginLeft: 4 }}
              onChange={(checked) => setReadOnlyMode(!checked)}
              checked={!readOnlyMode}
            />
          </p>}

        {!readOnlyMode && <Button
          onClick={() => setOpenDocument({ material_id: currentMaterial.id })}
          size='small' icon={<PlusOutlined />}
                          >{$t('Voeg toe')}
        </Button>}

      </div>

      {list_documents.length > 0
        ? <DocumentList
            readOnly={readOnlyMode} saveDocuments={saveDocuments}
            editDocument={setOpenDocument}
            documents={list_documents}
          />
        : <>
          {!readOnlyMode && currentMaterial.category === 'kist' && !searchTag
            ? <Button type='primary' loading={loading} onClick={setupDocuments}>{$t('Voeg standard documenten toe')}</Button>
            : <Empty description='Geen documenten' />}
        </>}

    </div>
  )
}

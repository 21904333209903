import { DownOutlined } from "@ant-design/icons";
import { Radio } from "antd";
import { Modal } from "antd";
import { Space } from "antd";
import { Button, Dropdown } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";

export const GroupDropdown = ({
  userIDs = [],
  onSave = (d, n) => {},
  addOrDelete = "add",
  SelectedGroupKeys = [],
}) => {
  const { group_name_lookup } = useSelector((state) => state.flights);

  const title =
    addOrDelete == "add" ? $t("Voeg groep toe") : $t("Verwijder van groep");

  const [selectedGroup, setSelectGroup] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    setSelectGroup(null);
    setShowModal(false);
  };

  const [loading, setLoading] = useState(false);
  const updateGroup = async (group_key) => {
    setLoading(true);
    let newUsers = null;
    for (const index in userIDs) {
      const { data } = await api._call_method(
        addOrDelete == "add" ? "POST" : "DELETE",
        "admin/user_groups.json",
        { user_id: userIDs[index], group_key }
      );
      if (data) newUsers = onSave(data, newUsers);
    }
    setLoading(false);
    closeModal();
  };
  const groupDropdownList = !group_name_lookup
    ? []
    : Object.keys(group_name_lookup)
        .map((group_key) => {
          return {
            key: group_key,
            label: group_name_lookup[group_key],
            disabled:
              SelectedGroupKeys.length > 0 &&
              SelectedGroupKeys.indexOf(group_key) > -1,
          };
        })
        .sort((a, b) => a["label"].localeCompare(b["label"]));

  return (
    <div>
      <Button
        type="text"
        size="small"
        disabled={userIDs.length === 0}
        onClick={() => setShowModal(true)}
      >
        {title}
        <DownOutlined />
      </Button>

      <Modal
        title={title}
        open={showModal}
        onCancel={!loading && closeModal}
        confirmLoading={loading}
        onOk={() => updateGroup(selectedGroup)}
        okButtonProps={{ disabled: !selectedGroup }}
        okText={$t("Voeg toe")}
        cancelText={$t("Annuleren")}
        width={350}
      >
        <Radio.Group
          onChange={(e) => setSelectGroup(e.target.value)}
          value={selectedGroup}
        >
          <Space direction="vertical">
            {groupDropdownList.map((item, key) => {
              return (
                <Radio disabled={item.disabled} key={key} value={item.key}>
                  {item.label}
                </Radio>
              );
            })}
          </Space>
        </Radio.Group>
      </Modal>
    </div>
  );
};

import { AutoComplete, Button, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { $t } from "~/i18n.js";
import { config } from "../../config.js";
import { selectElementByID } from "../../lib/helpers.js";
import { colors } from "../../theme/colors.js";
import { RecencyCurrencyBarometer } from "./recency.jsx";
import "./selectPilot.css";

export const SelectPilotLabel = ({ vlieger }) => {
  return (
    <span className="SelectPilotLabel">
      <img
        className="SelectPilotLabel__Image"
        style={{
          backgroundColor: colors.gray_lightest,
        }}
        alt=""
        onError={getUserAvatarError}
        src={getUserAvatar(vlieger, true)}
      />{" "}
      {vlieger.name} {vlieger.tag ? <span>({$t(vlieger.tag)})</span> : ""}
    </span>
  );
};

export const getUserAvatar = (user, isThumb) => {
  // convenience function to get user url avatar
  if (user && user.uuid) {
    // c= is a cookie bust to update avatar images if they are updated by the user
    return (
      config.avatar_prefix +
        user.uuid +
        (isThumb ? "/avatar_thumb.jpg" : "/avatar.jpg") +
        "?c=" +
        user.date_updated || -1
    );
  }
  return window.location.origin + "/avatar.png";
};

// Fallback to use in case that user Avatar does not exist
export const getUserAvatarError = (element) => {
  if (navigator.onLine) {
    // TODO: show image located in service bundle
    element.target.src = window.location.origin + "/avatar.png";
  }
};

export const SelectPilot = ({
  pilot,
  otherPilot,
  gezagVoerder,
  setBetalendLid,
  title,
  readOnly,
  field_id,
  field_name,
  hideAvatar,
  vliegers,
  dag_id = null,
  alle_vliegers,
  flight,
  saveFlight,
  openPilot,
}) => {
  if (!flight) return null;
  
  // find and store data on the pilot
  const otherPilotID = otherPilot && otherPilot.id;
  const [name, setName] = useState(flight[field_name]);
  const [result, setResult] = useState(
    vliegers.filter((vlieger) => vlieger.id !== otherPilotID)
  );
  const [searchInAll, setSearchInAll] = useState(false);

  useEffect(() => {
    // initiates every time the props change
    setName(flight[field_name] || (pilot && pilot.name));
    setResult(
      (vliegers.length > 0 ? vliegers : alle_vliegers || []).filter(
        (vlieger) => vlieger.id !== otherPilotID
      )
    );
    setSearchInAll(vliegers.length === 0);
  }, [flight, field_name, vliegers, alle_vliegers, otherPilotID]);

  const handleSearch = (searchText, forceSearchAll) => {
    searchText = searchText.toLowerCase();

    // filter out the pilot if it is not the other Pilot
    let filtered = searchInAll || forceSearchAll ? alle_vliegers : vliegers;
    try {
      filtered = filtered.filter(
        (vlieger) =>
          vlieger &&
          vlieger.id !== otherPilotID &&
          vlieger.lowercase &&
          vlieger.lowercase.indexOf(searchText) > -1
      );
    } catch (e) {
      console.log("UNDEFINED : ", e);
    }
    
    // if no filtered pilots you cannot continue.
    setResult(filtered);
    
    return filtered
  };

  const onSelect = (name) => {
    const data = {};

    if (!name) {
      data[field_name] = "";
      data[field_id] = "DELETE";
      data.betalend_lid_id = otherPilot ? otherPilot.id : "DELETE";
      data.is_fis = false;
      setName(null);
    } else {
      // it can either be the name you type, or the selected value you have
      // e.g. 1-Boris Schellekens if so, then you only add the second bit.
      const split = name.split("::");
      const id = parseInt(split[0]);
      let selectedPilot = selectElementByID(
        searchInAll ? alle_vliegers : vliegers,
        "id",
        parseInt(split[0])
      );
      if (!selectedPilot) selectedPilot = { id, name: split[1] };

      data[field_name] = selectedPilot.name;
      data[field_id] = selectedPilot.id;

      // always is_fis for known pilots (turn off if DBO is unknown)
      if (!selectedPilot || !otherPilot) {
        data.is_fis = false;
      } else if (field_id === "tweede_inzittende_id") {
        data.is_fis = otherPilot.is_instructeur && selectedPilot.id > 0;
      } else {
        data.is_fis = selectedPilot.is_instructeur && otherPilot.id > 0;
      }

      // determine if setBetalendLid
      if (!setBetalendLid) {
        // double check, if flight is tmg-a we cannot set a betalend lid
        data.betalend_lid_id = "DELETE";
      } else {
        if (field_id === "gezagvoerder_id") {
          if (!otherPilot) {
            // if no tweede pax, always gezagvoerder
            data.betalend_lid_id = selectedPilot.id;
          } else {
            // determine if current user is instructeur, otherwise it's PIC
            data.betalend_lid_id = selectedPilot.is_instructeur
              ? otherPilot.id
              : selectedPilot.id;
          }
        } else {
          if (!gezagVoerder) {
            // if no tweede pax, always gezagvoerder
            data.betalend_lid_id = selectedPilot.id;
          } else {
            // determine if current user is instructeur, otherwise it's PIC
            data.betalend_lid_id = gezagVoerder.is_instructeur
              ? selectedPilot.id
              : gezagVoerder.id;
          }
        }
      }

      // switch tweedeInzittende if gezagvoerder is solo of dbo
      if (
        field_id === "tweede_inzittende_id" &&
        gezagVoerder &&
        ["dbo", "solist"].includes(gezagVoerder.tag) &&
        selectedPilot &&
        selectedPilot.is_instructeur
      ) {
        data.gezagvoerder = selectedPilot;
        data.gezagvoerder_naam = selectedPilot.name;
        data.gezagvoerder_id = selectedPilot.id;
        data.tweede_inzittende = gezagVoerder;
        data.tweede_inzittende_naam = gezagVoerder.name;
        data.tweede_inzittende_id = gezagVoerder.id;
        data.is_fis = true;
        if (setBetalendLid)
          data.betalend_lid_id =
            flight?.betalend_lid_id === gezagVoerder.id
              ? otherPilot.id
              : gezagVoerder.id;
        message.info(
          $t("Vliegers omgewisseld: Instructeur dient gezagvoerder te zijn als die met een DBO/Solist vliegt.")
        );
      }
    }
    saveFlight(data);
  };

  const clickable = pilot && pilot.id > 0 && openPilot;

  return (
    <div className="selectPilot row">
      {!hideAvatar && (
        <img
          className={`${clickable ? "clickable" : ""} pilotAvatar`}
          alt=""
          onClick={() => clickable && openPilot && openPilot()}
          src={getUserAvatar(pilot, true)}
          onError={getUserAvatarError}
        />
      )}

      <div
        className="column"
        style={{
          alignItems: "flex-start",
          marginLeft: 8,
          position: "relative",
        }}
      >
        <div className="row">
          <AutoComplete
            value={name}
            onChange={(name) => {
              if (name) {
                // it can either be the name you type, or the selected value you have
                // e.g. 1-Boris Schellekens if so, then you only add the second bit.
                const split = name.split("::");
                setName(split.length > 1 ? split[1] : split[0]);
              } else {
                // clear pilot
                setName(null);
                onSelect(null);
              }
            }}
            allowClear
            popupMatchSelectWidth={false}
            disabled={readOnly || name === "-"}
            onSelect={onSelect}
            onSearch={handleSearch}
            options={result.reduce(
              (all, vlieger) =>
                all.concat({
                  label: SelectPilotLabel({ vlieger }),
                  value: vlieger.id + "::" + vlieger.name,
                }),
              []
            )}
            notFoundContent={
              <div>
                {alle_vliegers ? (
                  <div>
                    <p className="center">{$t("Niet gevonden")}? </p>

                    <Button
                      type="default"
                      disabled={!name || readOnly}
                      style={{ margin: "10px 4px 0 0", width: "100%" }}
                      onClick={() => {
                        setSearchInAll(!searchInAll);
                        let result = handleSearch(name, true);
                        if (result.length === 0) {
                          message.error($t("Geen vliegers gevonden"));
                        }
                      }}
                    >
                      {$t("Zoek in alle leden")}
                    </Button>

                    {dag_id && (
                      <Button
                        type="primary"
                        style={{ margin: "10px 4px 0 0", width: "100%" }}
                        target="_blank"
                        href={`/register/${dag_id}`}
                      >
                        {$t("Meld een lid aan")}
                      </Button>
                    )}
                  </div>
                ) : (
                  <p className="small">
                    {$t("Niet gevonden")}?{" "}
                    {$t("Schrijf [onbekend] en in bij details de naam")}.
                  </p>
                )}
              </div>
            }
          >
            <Input
              placeholder={title}
              onPressEnter={() =>
                result.length > 0 && onSelect(result[0].id.toString())
              }
            />
          </AutoComplete>
        </div>

        {!hideAvatar && (
          <div
            className={`column indicator ${clickable && "clickable"}`}
            onClick={() => clickable && openPilot()}
          >
            <RecencyCurrencyBarometer
              pilot={pilot}
              tag={pilot && pilot.tag}
              skipDetails
            />
          </div>
        )}
      </div>
    </div>
  );
};

import { MessageOutlined, PlusOutlined, WarningTwoTone } from '@ant-design/icons'
import { Button, Descriptions } from 'antd'
import React, { useState } from 'react'
import { $t } from '~/i18n.js'

import { Loader } from "~/components/loader/loader";

import { testDateIsToday } from '../../../../lib/localize'
import { MaintenanceStatusTag, MeldingStatusTag } from '../../materialTags'
import { MeldingTable } from '../../melding/sections/meldingTable'
import { AddInspection } from './addInspection'
import { Inspection } from './inspection'

export const MaterialStatus = ({ history, currentMaterial, meldingen, inspection, currentProfile, loading }) => {
  const [openInspection, setOpenInspection] = useState(false)

  if (!currentMaterial?.name) return <Loader />
  return (
    <div>

      {openInspection && <AddInspection
        currentMaterial={currentMaterial}
        closeModal={() => setOpenInspection(false)}
        visible={openInspection}
        last_motor_minutes={inspection?.total_motor_minutes}
                         />}

      <Descriptions size='small' column={1} layout='horizontal' style={{ marginBottom: 12 }}>
        <Descriptions.Item label={$t('Status')}><MeldingStatusTag status={currentMaterial.status} /></Descriptions.Item>
        <Descriptions.Item label={$t('Maintenance')}><MaintenanceStatusTag maintenance_status={currentMaterial.maintenance_status} default_value='nvt' /></Descriptions.Item>

        {currentMaterial.category === 'kist' && <Descriptions.Item label={$t('Dagelijkse inpectie')}>

          {testDateIsToday(inspection?.date_created)
            ? <Inspection inspection={inspection} />
            : <>
              {currentProfile.group_keys?.indexOf('brevet') > -1
                ? <Button size='small' type='primary' icon={<PlusOutlined />} onClick={() => setOpenInspection(true)}>{$t('Voeg toe')}</Button>
                : <p><WarningTwoTone twoToneColor='red' style={{ marginRight: 6 }} />{$t('Alleen voor brevethouders')}</p>}
            </>}

        </Descriptions.Item>}

        {currentMaterial.remark && <Descriptions.Item><MessageOutlined style={{ marginRight: 4 }} /> {currentMaterial.remark}</Descriptions.Item>}
      </Descriptions>

      <MeldingTable
        showArchive
        history={history}
        isPending={loading}
        currentMaterial={currentMaterial}
        meldingen={meldingen}
      />

    </div>
  )
}

import { i18n } from '~/i18n.js'
import { getRoles, normalizeVliegerData } from './persist.normalizers.js'
import { persistState } from './persist.state.js'

export const persistReducer = (state = persistState, action) => {
  switch (action.type) {
    case 'PERSIST_PENDING':
      return {
        ...state,
        isPending: true
      }
    case 'PERSIST_ERROR':
      return {
        ...state,
        isPending: false
      }

    case 'SET_CLUBS':
      return {
        ...state,
        lastUpdatedClubs: new Date(),
        all_clubs: action.all_clubs
      }

    case 'SET_VLIEGER_DATA':
      return {
        ...state,
        lastUpdatedData: new Date(),
        vlieger_data: normalizeVliegerData(action.vlieger_data)
      }

    case 'UPDATE_PERSIST_VLIEGER_DATA_DTO_TRAINING':
      // dto training is map reduced, as such we update accordingly
      const new_dto_training = { ...state.vlieger_data.dto_training }
      new_dto_training[action.cluster_id] = action.dto_training
      return {
        ...state,
        vlieger_data: {
          ...state.vlieger_data,
          dto_training: new_dto_training
        }
      }

    case 'SET_PROFILE_EXTENDED_DATA':
      i18n.changeLanguage(action.profile?.language)
      i18n.setUnit(action.club?.unit)

      // set localstorage the timezone
      localStorage.timezone = action.club?.timezone || 'Europe/Amsterdam'

      return {
        ...state,
        lastUpdated: new Date(),
        isPending: false,
        profile: { ...action.profile, ...getRoles(action.profile) },
        gegevens: action.gegevens,
        club: action.club,
        bestanden: action.bestanden ? action.bestanden : state.bestanden,

        // keep track of last time popup is shown, update if date_last_checked is earlier (thus forcing popup)
        check_last_shown: state.check_last_shown || new Date(1)
      }

    case 'PROFILE_ERROR_TRY_AGAIN':
      console.error('NOT INTERNET, LOADING PROFILE FAILED')
      return {
        ...state,
        // set time ago before 10 minutes so that the profile is loaded again
        lastUpdated: new Date((new Date()).getTime() - 8 * 6000),
      }

    case 'SET_CLUB':
      i18n.setUnit(action.club?.unit)

      // set localstorage the timezone
      localStorage.timezone = action.club?.timezone || 'Europe/Amsterdam'

      return {
        ...state,
        club: action.club
      }
    case 'SET_PROFILE_FILES':
      return {
        ...state,
        bestanden: action.bestanden ? action.bestanden : state.bestanden
      }
    case 'ADD_TO_PROFILE_FILES':
      return {
        ...state,
        bestanden: [action.file].concat(state.bestanden)
      }

    case 'PROFILE_SHOW_CHECK':
      return {
        ...state,
        profile: { ...state.profile, check_required: action.save ? '' : state.profile.check_required },
        check_last_shown: new Date()
      }

    case 'SET_AUTHENTICATED':
      return {
        ...state,
        isAuthenticated: true
      }

    case 'SET_PERSONAL_PREFERENCES':
      return {
        ...state,
        ...action.preferences
      }

    case 'UPDATE_VOTES':
      const newVotes = { ...state.votes }
      newVotes[action.id] = action.vote // true/false
      return {
        ...state,
        votes: newVotes
      }

    case 'ADD_FEEDBACK':
      return {
        ...state,
        feedback: [action.feedback].concat(state.feedback),
        feedbackUpdated: new Date()
      }

    case 'SET_FEEDBACK':
      return {
        ...state,
        feedback: action.feedback,
        feedbackUpdated: new Date()
      }

    case 'RESET_PERSIST_STATE':
      return {
        // don't reset votes
        ...persistState,
        votes: state.votes
      }

    default:
      return state
  }
}

import { StarTwoTone } from "@ant-design/icons";
import { Button, Divider, Form, Input, Modal, Select } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { AnnuleerButton } from "~/components/buttons/AnnuleerButton.jsx";
import { SwitchYesNo } from "~/components/form/SwitchYesNo.jsx";
import { HelpBubble } from "~/components/help/help.bubble.jsx";
import { $t } from "~/i18n.js";
import { colors } from "~/theme/colors.js";

export const EditBevoegdheidModal = ({
  bevoegdheid = {},
  open = false,
  saveBevoegheid = (b) => {},
  closeModal = () => {},
}) => {
  const [form] = Form.useForm();

  const { group_name_lookup } = useSelector((state) => state.flights);

  useEffect(() => {
    open && form.setFieldsValue(bevoegdheid);
  }, [open]);

  const saveForm = async () => {
    const values = await form.validateFields();

    const payload = {
      ...values,
      id: bevoegdheid.id,
      action: bevoegdheid.action === "add" ? "add" : "update",
    };
    saveBevoegheid(payload);
    closeModal();
  };

  return (
    <Modal
      title={$t("Wijzig bevoegdheid")}
      open={open}
      onCancel={closeModal}
      footer={[
        <AnnuleerButton key="annuleren" onClick={closeModal} />,
        <Button key="opslaan" onClick={saveForm} type="primary">
          {$t("Opslaan")}
        </Button>,
      ]}
    >
      <Form
        form={form}
        initialValues={bevoegdheid}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        name="group"
        preserve={false}
        requiredMark={false}
      >
        <Form.Item
          label={$t("Referentie")}
          name="name"
          rules={[
            {
              required: true,
              max: 99,
              message: $t("Maximaal ${max} characters", { max: 100 }),
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          label={$t("Label")}
          name="label"
          rules={[
            {
              required: false,
              max: 99,
              message: $t("Maximaal ${max} characters", { max: 100 }),
            },
          ]}
          extra={$t("Zal getoond worden ipv de referentie")}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          label={$t("Beschrijving")}
          name="description"
          rules={[
            {
              required: false,
              max: 299,
              message: $t("Maximaal ${max} characters", { max: 300 }),
            },
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item
          label={
            <p>
              {$t("Recency key")}
              <HelpBubble
                content={$t("Verbinding met EASA recency hoofdstuk")}
              />
            </p>
          }
          name="easa_key"
        >
          <Select>
            <Select.Option value="">{$t("géén")}</Select.Option>
            <Select.Option value="winch">{$t("winch")}</Select.Option>
            <Select.Option value="aerotow">{$t("aerotow")}</Select.Option>
            <Select.Option value="selfstart">{$t("selfstart")}</Select.Option>
            <Select.Option value="bungee">{$t("bungee")}</Select.Option>
            <Select.Option value="car">{$t("car")}</Select.Option>
            <Select.Option value="tmg-aerotow">
              {$t("tmg-aerotow")}
            </Select.Option>
            <Select.Option value="tmg">{$t("tmg")}</Select.Option>
            <Select.Option value="passenger">{$t("passenger")}</Select.Option>
            <Select.Option value="fes">{$t("FE(S)")}</Select.Option>
            <Select.Option value="fcl">{$t("FCL")} <span style={{marginLeft: 12}}><HelpBubble content={$t('Bij een geldige FCL licentie (PPL of hoger) hoeft de vlieger niet te voldoen aan een minimum uren en starts om de TMG aantekening geldig te houden')}/></span> </Select.Option>
          </Select>
        </Form.Item>

        <Divider />

        <Form.Item
          label={
            <p>
              {$t("Scoring")}
              <HelpBubble
                content={$t(
                  "Methode waarop gecontroleerd wordt. Boolean: simpel afvinken met gehaald of niet. Rank: score van 1 t/m 5"
                )}
              />
            </p>
          }
          name="scoring"
        >
          <Select>
            <Select.Option value="boolean">{$t("boolean")}</Select.Option>
            <Select.Option value="rank">{$t("rank")}</Select.Option>
            <Select.Option value="bd_rank">
              {$t("Briefing, Demo + Rank")}
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <p>
              {$t("Met beschrijving")}
              <HelpBubble
                content={$t(
                  "Laat beschrijving achter na toekennen van de bevoegdheid."
                )}
              />
            </p>
          }
          name="has_remark"
        >
          <SwitchYesNo />
        </Form.Item>

        <Form.Item
          label={
            <p>
              {$t("Geldigheid")}
              <HelpBubble
                content={$t(
                  "Aantal dagen dat bevoegheid geldig is na behalen."
                )}
              />
            </p>
          }
          name="geldigheid"
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          label={
            <p>
              {$t("Groep")}
              <HelpBubble
                content={$t(
                  "Na het behalen van de bevoegdheid kan de gebruiker aan de volgende groep worden toegekend."
                )}
              />
            </p>
          }
          name="group_key"
        >
          <Select>
            <Select.Option value="">{$t("géén")}</Select.Option>
            {Object.keys(group_name_lookup).map((group_key) => (
              <Select.Option key={group_key} value={group_key}>
                {group_name_lookup[group_key]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Divider />

        <span>
          <StarTwoTone twoToneColor={colors.primary} /> {$t("Endorsement")}{" "}
          <HelpBubble
            content={$t(
              "Na behalen kan een officiele endorsement aangemaakt worden als certificaat richting externe clubs, laat leeg indien niet van toepassing."
            )}
          />
          :
        </span>

        <Form.Item
          label={$t("Title")}
          name="endorse_title"
          rules={[
            {
              required: false,
              max: 99,
              message: $t("Maximaal ${max} characters", { max: 100 }),
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          label={$t("Beschrijving")}
          name="endorse_body"
          rules={[
            {
              required: false,
              max: 499,
              message: $t("Maximaal ${max} characters", { max: 500 }),
            },
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

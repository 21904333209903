import { CloseOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Divider,
  Flex,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Switch,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnnuleerButton } from "~/components/buttons/AnnuleerButton.jsx";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";
import { _parseDate } from "~/lib/localize.js";
import { persistActions } from "~/redux/actions.js";
import { GroupDropdown } from "./groupDropdown.jsx";
import { translateUserPermission } from "./translateUserPermission.jsx";
import { SwitchYesNo } from "~/components/form/SwitchYesNo.jsx";

export const UserForm = ({ history, onSave = (newUser) => {}, user = {} }) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  useEffect(() => setSelectedUser(user), [user]);

  const { club, profile } = useSelector((state) => state.persist);
  const { group_name_lookup } = useSelector((state) => state.flights);

  const permissions = [...new Set(selectedUser?.app_permissions)];

  const [openModal, setOpenModal] = useState(true);
  const closeModal = () => {
    setOpenModal(false);
    history.replace("/admin/users");
  };

  const dispatch = useDispatch();
  const saveForm = async () => {
    const values = await form.validateFields();
    setLoading(true);

    const payload = {
      ...values,
      id: user.id,
      is_active: user.is_active === values.is_active ? null : values.is_active,
    };

    const { response, data } = await api._call_method(
      user.isNew ? "POST" : "PATCH",
      "admin/users.json",
      payload
    );

    if (response.ok && data) {
      if (data.id === profile.id) {
        await dispatch(persistActions.profileAction());
      }

      await onSave({ ...data, addedUser: user.isNew });

      closeModal();
    }
    setLoading(false);
  };

  const [loadingGroup, setLoadingGroup] = useState({});
  const removeFromGroup = async (group_key) => {
    const newLoadingGroup = { ...loadingGroup };
    newLoadingGroup[group_key] = true;
    setLoadingGroup(newLoadingGroup);

    const { data } = await api.delete("admin/user_groups.json", {
      user_id: user.id,
      group_key,
    });
    if (data) {
      setSelectedUser(data);
      onSave(data);

      if (data.id === profile.id) {
        dispatch(persistActions.profileAction());
      }
    }
    newLoadingGroup[group_key] = false;
    setLoadingGroup({ ...newLoadingGroup });
  };

  const hasGroups = selectedUser?.group_keys?.length > 0;

  return (
    <Modal
      title={user?.isNew ? $t("Voeg gebruiker toe") : $t("Wijzig gebruiker")}
      open={openModal}
      onCancel={() => !loading && closeModal()}
      footer={[
        <AnnuleerButton
          key="annuleren"
          disabled={loading}
          onClick={closeModal}
        />,
        <Button
          key="opslaan"
          disabled={loading}
          loading={loading}
          onClick={saveForm}
          type="primary"
        >
          {$t("Opslaan")}
        </Button>,
      ]}
    >
      <Form
        form={form}
        initialValues={user}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        name="group"
        preserve={false}
        requiredMark={false}
      >
        <Form.Item label={$t("Voornaam")} name="first_name">
          <Input />
        </Form.Item>

        <Form.Item label={$t("Achternaam")} name="last_name">
          <Input />
        </Form.Item>

        <Form.Item
          label={$t("Emailadres")}
          name="email"
          rules={[{ required: true, message: $t("Voeg email toe") }]}
        >
          <Input
            autoComplete="username"
            type="email"
            style={{ width: "100%", maxWidth: 300 }}
          />
        </Form.Item>

        {!user.has_temporary_password && (
          <section>
            <Divider />
            {!user.isNew && (
              <Form.Item label={$t("Lid nummer")} name="lid_nummer">
                <Input />
              </Form.Item>
            )}

            <Form.Item label={$t("Lidmaatschap")} name="lidmaatschap">
              <Select>
                {club?.lidmaatschap_types?.split(",")?.map((lidmaatschap) => (
                  <Select.Option key={lidmaatschap} value={lidmaatschap}>
                    {lidmaatschap}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label={$t("Interne opmerking")} name="remark">
              <Input.TextArea />
            </Form.Item>
          </section>
        )}

        {!user.isNew && (
          <section>
            <Divider />

            <Flex justify="space-between">
              <p className="bold">{$t("Groepen")}:</p>
              <div>
                <GroupDropdown
                  userIDs={[user.id]}
                  onSave={(user) => setSelectedUser(user)}
                  SelectedGroupKeys={selectedUser?.group_keys}
                />
              </div>
            </Flex>

            {selectedUser?.group_keys.map((group_key) => (
              <p key={group_key}>
                <Popconfirm
                  placement="bottom"
                  title={$t("Zeker weten?")}
                  onConfirm={() => removeFromGroup(group_key)}
                  okText={$t("Ja")}
                  cancelText={$t("Nee")}
                >
                  <Button
                    loading={loadingGroup[group_key]}
                    style={{ marginRight: 6 }}
                    shape="circle"
                    size="small"
                    icon={<CloseOutlined />}
                  />
                </Popconfirm>

                <span>{group_name_lookup[group_key] || group_key}</span>
              </p>
            ))}

            <p className="bold" style={{ marginTop: 12 }}>
              {$t("Permissies")}:
            </p>
            {permissions.map((a) => {
              return <p key={a}>{translateUserPermission(a)}</p>;
            })}
          </section>
        )}

        {user.has_temporary_password && (
          <Alert
            style={{ marginTop: 12 }}
            type="warning"
            message={$t(
              "Let op, standard gebruiker nodig om de app te laten werken"
            )}
          ></Alert>
        )}

        {!user.isNew && !user.has_temporary_password && (
          <section>
            <Divider />
            <b>{$t("Wijzig status")}:</b>

            {user?.is_active && (
              <Alert
                type="warning"
                message={$t(
                  "Bij de-activatie ontvangt de gebruiker een email met in de bijlage de historie van diens vluchten."
                )}
              ></Alert>
            )}
            {!user?.is_active && (
              <Alert
                type="warning"
                message={$t(
                  "Bij het activeren ontvangt de gebruiker een email met de uitnodiging in te loggen."
                )}
              ></Alert>
            )}

            {!hasGroups && (
              <Alert
                style={{ marginTop: 6 }}
                type="error"
                message={$t(
                  "Zonder toegekende groep kan de gebruiker niet worden geactiveerd."
                )}
              />
            )}

            <Form.Item label={$t("Actief")} name="is_active">
              <SwitchYesNo disabled={!hasGroups} />
            </Form.Item>

            {!user?.is_active && user.date_deactivated && (
              <section>
                <p className="small" style={{ marginTop: 12 }}>
                  {$t("Verwijderd door")}: {user.deactivated_by},{" "}
                  {_parseDate(user.date_deactivated)}
                </p>
                <p className="small" style={{ marginTop: 12 }}>
                  {$t(
                    "De gebruiker data zal volgens EASA richtlijn na 36 maanden verwijderd worden. De vluchten blijven in het systeem."
                  )}
                </p>
              </section>
            )}
          </section>
        )}
      </Form>
    </Modal>
  );
};

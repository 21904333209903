import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Alert, Button, Form, Modal, Table, Tag, Flex } from "antd";
import React, { useEffect, useState } from "react";
import { InputFileSingle } from "~/components/form/InputFileSingle.jsx";
import { defaultTableConfig } from "~/components/layout/table.jsx";
import { FlightViewModal } from "~/components/flight/FlightViewModal.jsx";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";
import { parseCsv } from "~/lib/parsers.js";
import { Flight } from "~/pages/profile/Flight.js";
import { AdminFlightColumns } from "./AdminFlightColumns.jsx";
import { SluitButton } from "~/components/buttons/SluitButton.jsx";
import { message } from "antd";

export function ImportDialogFlights({
  isModal,
  closeModal,
  refreshAfterImport,
}) {
  const [form] = Form.useForm();

  const [flight, openFlight] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState({ success: 0, fail: 0 });

  const [status, setStatus] = useState({});
  const [items, setItems] = useState([]);
  const [value, setValue] = useState("");

  const reset = () => {
    form.resetFields();

    setLoading(false);
    setItems([]);
    setValue("");
    setLoadingStatus(null);
    setStatus({});
  };
  useEffect(reset, [isModal]);

  const [userLookup, setUserLookup] = useState({});
  const fetchUserLookup = async () => {
    const data = await api.get("admin/users.json");
    if (data) {
      let users = data.reduce((all, user) => {
        all[user.id] = user;
        return all;
      }, {});
      setUserLookup(users);
    }
  };
  useEffect(() => {
    isModal && fetchUserLookup();
  }, [isModal]);

  useEffect(() => {
    // Also update deps
    if (!value) return;

    let newStatus = { ...status };
    const _items = parseCsv(value);
    
    // NOTE: map and set errors
    const _flights = _items.map((obj) => {
      const f = new Flight(obj);

      if (f.vluchtduur < 0) {
        f._valid = false;
        f._errors.push($t("Vluchtduur kan niet negatief zijn"));
      }

      // update check on user
      f.gezagvoerder = userLookup[f.gezagvoerder_id];
      f.tweede_inzittende = userLookup[f.tweede_inzittende_id];

      if (!f.gezagvoerder) {
        f.gezagvoerder = { error: true };
        f._valid = false;
        f._errors.push($t("Gezagvoerder") + " " + $t("Niet gevonden"));
      }

      if (f.tweede_inzittende_id !== "DELETE" && !f.tweede_inzittende) {
        f.tweede_inzittende = { error: true };
        f._valid = false;
        f._errors.push($t("Tweede inzittende") + " " + $t("Niet gevonden"));
      }
      return f;
    });

    setItems(_flights);
    setStatus(newStatus);

    if (value && _flights.length === 0) {
      message.error(
        $t("Geen vluchten gevonden, controleer het bestand format")
      );
    }
  }, [value]);

  const n_errors = items?.reduce((count, item) => {
    if (!item?._valid) return count + 1;
    return count;
  }, 0);

  const updateStatus = (email, newStatus, currentStatus) => {
    let newStatusLookup = currentStatus || status;
    newStatusLookup[email] = newStatus;
    setStatus({ ...status });
    return newStatusLookup;
  };

  const startImport = async () => {
    let success = 0;
    let fail = 0;
    let statusLookup = { ...status };
    setLoading(true);

    for (const idx in items) {
      let flight = items[idx];

      // in case of data, skip
      if (status[flight.uuid] || !flight._valid) {
        statusLookup = updateStatus(flight.uuid, "skipped", statusLookup);
      } else {
        statusLookup = updateStatus(flight.uuid, "loading");
        const { response, data } = await api.post2(
          "flights/save_flight.json",
          flight
        );

        if (!response.ok && !data?.error) {
          let msg = "fail";
          if (data?.error) msg = `fail: ${data?.detail || data?.error}`;
          statusLookup = updateStatus(flight.uuid, msg, statusLookup);
          fail += 1;
        } else {
          statusLookup = updateStatus(flight.uuid, "success", statusLookup);
          success += 1;
        }
      }
    }
    refreshAfterImport();
    setLoadingStatus({ success, fail });
    setLoading(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setValue(e.target.result.toString());
      };
      reader.readAsText(file);
    }
  };

  let columns = AdminFlightColumns(true);
  return (
    <>
      <Modal
        width={"100%"}
        title={$t("Importeer vluchten")}
        open={isModal}
        onCancel={!loading && closeModal}
        footer={[
          <Button
            key="opslaan"
            disabled={!value || items.length == 0}
            onClick={startImport}
            type="primary"
            loading={loading}
          >
            {$t("Importeer")}
          </Button>,
          <SluitButton key="sluit" disabled={loading} onClick={!loading && closeModal} />,
        ]}
      >
        <section>
          <p className="bold">1. {$t("Selecteer bestand")}:</p>
          <Flex gap={12} vertical={true}>
            <Alert
              type="info"
              showIcon
              message={
                <p>
                  {$t("Gebruik het volgende CSV template")}:{" "}
                  <a href="https://zweefapp-ui.s3.eu-central-1.amazonaws.com/template_import_flights_admin.csv">
                    {$t("Link naar template")}
                  </a>
                </p>
              }
            />

            <Alert
              type="info"
              showIcon
              message={$t(
                "Advies is om het aantal vluchten per bestand te limiteren tot 500 in geval dat er fouten tussen zitten."
              )}
            />

            <Alert
              type="info"
              showIcon
              message={$t(
                "Let op of de vliegers van de vlucht goed zijn ingeladen. Bij inactieve leden voldoet alleen de naam."
              )}
            />

            <Form form={form}>
              <Form.Item
                name="file"
                rules={[{ required: false }]}
                onChange={handleFileChange}
              >
                <InputFileSingle accept=".csv" />
              </Form.Item>
            </Form>
          </Flex>
        </section>

        <p className="bold">2. {$t("Bekijk de data")}</p>

        <FlightViewModal
          onClose={() => openFlight(null)}
          flight={flight}
          updateFlight={null}
          onUpdateFlight={null}
          hideSigning={true}
          currentUser={null}
        />

        {items.length > 0 && (
          <p style={{ marginBottom: 12 }}>
            {$t("Import bevat ${number} vluchten(s)", {
              number: items.length,
            })}
          </p>
        )}

        {n_errors > 0 && (
          <Alert
            style={{ marginBottom: 12 }}
            type="warning"
            message={$t("Import bevat ${number} data fout(en)", {
              number: n_errors,
            })}
          />
        )}

        {(loadingStatus?.success > 0 || loadingStatus?.fail > 0) && (
          <Alert
            type="warning"
            message={$t(
              "Import: ${success} gelukt, ${fail} mislukt",
              loadingStatus
            )}
          />
        )}

        <Table
          style={{
            overflowX: "auto",
            cursor: "pointer",
          }}
          size="small"
          pagination={{ ...defaultTableConfig, defaultPageSize: 100 }}
          rowKey="uuid"
          dataSource={items}
          columns={[
            {
              width: 80,
              render: (_, flight) => {
                return (
                  <div>
                    {!flight._valid && (
                      <Tag color="warning">{$t("Foute data")}</Tag>
                    )}
                  </div>
                );
              },
            },
            {
              width: 80,
              render: (_, flight) => {
                return (
                  <div>
                    {status[flight.uuid] === "skipped" && (
                      <Tag color="orange">{$t("Overgeslagen")}</Tag>
                    )}
                    {status[flight.uuid] === "loading" && (
                      <Tag icon={<SyncOutlined spin />} color="processing" />
                    )}
                    {status[flight.uuid] === "success" && (
                      <Tag icon={<CheckCircleOutlined />} color="success" />
                    )}
                    {status[flight.uuid]?.startsWith("fail") && (
                      <Tag icon={<ExclamationCircleOutlined />} color="warning">
                        {status[flight.uuid]}
                      </Tag>
                    )}
                  </div>
                );
              },
            },
            { title: "UUID", dataIndex: "uuid" },
            ...columns,
          ]}
          onRow={(flight) => {
            return {
              onClick: () => openFlight(flight),
            };
          }}
        />
      </Modal>
    </>
  );
}

import {
  CheckCircleFilled,
  EditFilled,
  EyeInvisibleOutlined,
  LockFilled,
  MessageOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { Tag, Tooltip } from "antd";
import React from "react";
import { $t } from "~/i18n.js";
import { LandingIcon } from "~/components/icon/icon.jsx";
import { colors } from "~/theme/colors.js";

export const FlightTags = ({
  flight,
  pilot = {},
  showNotitie = false,
  hidePrive = false,
}) => (
  <>
    {!flight.is_prive && flight.is_locked && (
      <LockFilled style={{ color: "black", marginRight: 2 }} />
    )}
    {!flight.is_prive && !flight.is_locked && (
      <UnlockOutlined style={{ color: "black", marginRight: 2 }} />
    )}

    {!hidePrive && flight.is_prive && (
      <EditFilled style={{ color: "black", marginRight: 2 }} />
    )}

    {!flight.is_prive && !flight.igc_visible && (
      <Tooltip placement="top" title={$t("IGC niet zichtbaar")}>
        <EyeInvisibleOutlined
          style={{ color: "rgba(0, 0, 0, 0.25)", marginLeft: 6 }}
        />
      </Tooltip>
    )}

    {flight.category && <Tag>{flight.category}</Tag>}

    {flight.is_fis && (
      <Tooltip placement="top" title={$t("Instructievlucht")}>
        <Tag color="lime">FI(s)</Tag>
      </Tooltip>
    )}
    {flight.is_training && (
      <Tooltip
        placement="top"
        title={$t("Jaarlijkse EASA trainingsvlucht met instructeur.")}
      >
        <Tag color="geekblue">training</Tag>
      </Tooltip>
    )}
    {flight.is_examen && (
      <Tooltip placement="top" title={$t("Examen vlucht")}>
        <Tag color="geekblue">{$t("examen")}</Tag>
      </Tooltip>
    )}
    {flight.is_profcheck && (
      <Tooltip placement="top" title={$t("EASA prof-check")}>
        <Tag color="geekblue">prof</Tag>
      </Tooltip>
    )}

    {flight.signed && (
      <CheckCircleFilled style={{ color: colors.groen_dark }} />
    )}

    {pilot &&
      (flight.is_training || flight.is_examen || flight.is_profcheck) &&
      flight.gezagvoerder_id === pilot.id && (
        <Tooltip
          placement="top"
          title={$t(
            "EASA trainingsvlucht, Examen of Profcheck als instructeur."
          )}
        >
          <Tag color="geekblue">{$t("afgenomen")}</Tag>
        </Tooltip>
      )}

    {flight.is_overland && (
      <Tooltip placement="top" title={$t("Overland vlucht")}>
        <Tag color="green">
          {flight.afstand > 0 ? flight.afstand + "km" : $t("Ovl")}
        </Tag>
      </Tooltip>
    )}
    {flight.vertrek_vliegveld &&
      flight.aankomst_vliegveld &&
      flight.vertrek_vliegveld !== flight.aankomst_vliegveld && (
        <Tag color="green">
          <LandingIcon size={12} />
          <span style={{ marginLeft: 4 }}>{flight.aankomst_vliegveld}</span>
        </Tag>
      )}

    {flight.is_deleted && <Tag color="red">{$t("verwijderd")}</Tag>}

    {showNotitie
      ? (flight.notitie || flight.bijzonderheden) && (
          <Tooltip
            placement="left"
            title={`${flight.bijzonderheden} ${flight.notitie}`}
          >
            <MessageOutlined style={{ margin: "0 2px" }} />
          </Tooltip>
        )
      : flight.bijzonderheden && (
          <Tooltip placement="left" title={flight.bijzonderheden}>
            <MessageOutlined style={{ margin: "0 2px" }} />
          </Tooltip>
        )}
  </>
);

export const FlightTagColumn = () => ({
  render: (_, flight) => <FlightTags flight={flight} />,
  filters: [
    { text: $t('training'), value: 'training' }, //
    { text: $t('examen'), value: 'examen' }, //
    { text: $t('overland'), value: 'overland' } //
  ],
  onFilter: (value, record) => {
    if (value === 'training') return record.is_training
    if (value === 'examen') return record.is_examen || record.is_profcheck
    if (value === 'overland') return record.is_overland
    return false
  }
})

import { Form, Input } from 'antd'
import React, { useState } from 'react'
import { $t } from '~/i18n.js'

import { DataCard } from "~/components/dataCards/dataCard";
import { deserializePhone, serializePhone } from '../../../lib/localize'

export const CardEmergency = ({ gegevens, saveData, type }) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const saveForm = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()

      // manipulate values for save
      const res = await saveData({
        gegevens: {
          ...values,
          emergency_contact_phone: serializePhone(values.emergency_contact_phone)
        }
      })
      setLoading(false)
      return res
    } catch (info) {
      console.log('Validate Failed:', info)
      setLoading(false)
    }
  }

  return (
    <DataCard title={$t('Contactpersoon in geval van nood')} onSave={saveForm} loading={loading} type={type}>

      <Form
        form={form}
        name='emergency'
        initialValues={{
          ...gegevens,
          emergency_contact_phone: deserializePhone(gegevens.emergency_contact_phone)
        }}
        requiredMark={false}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >

        <Form.Item
          label={$t('Naam')}

          name='emergency_contact'
          rules={[{ required: true, message: $t('Vul in') }]}
        >
          <Input placeholder={$t('Voor- en achternaam')} />
        </Form.Item>

        <Form.Item
          label={$t('Relatie')}

          name='emergency_contact_relation'
          rules={[{ required: true, message: $t('Vul in') }]}
        >
          <Input placeholder={$t('Partner, moeder, buurman')} />
        </Form.Item>

        <Form.Item
          label={$t('Telefoon')}

          name='emergency_contact_phone'
          rules={[{ required: true, message: $t('Vul in') }]}
        >
          <Input />
        </Form.Item>

        <p className='small' style={{ marginTop: 12, marginBottom: 6 }}>{$t('Deze persoon contacten wij alléén in geval van nood')}.</p>

      </Form>

    </DataCard>
  )
}

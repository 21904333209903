
export const splitText = '~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~'


export const ExportCluster = (cluster, profile, club) => {

    // Simplified scope of data, without ID's and initial order
    const data = {
        name: cluster.name,
        description: cluster.description,
        curriculum_versie: cluster.curriculum_versie || 'v1',
        is_dto: cluster.is_dto,
        category: cluster.category.map(c => {
            return {
                order: c.order,
                name: c.name,
                label: c.label,
                description: c.description,
                bevoegdheid: c.bevoegdheid.map(b => {
                    return {
                        order: b.order,
                        name: b.name,
                        label: b.label,
                        easa_key: b.easa_key,
                        description: b.description,
                        endorse_title: b.endorse_title,
                        endorse_body: b.endorse_body,
                        geldigheid: b.geldigheid,
                        group_key: b.group_key,
                        scoring: b.scoring,
                        has_remark: b.has_remark,
                    }
                })
            }
        })
    }

    const txt = `Gliding.App Export DTO program "${cluster.name}"
Date exported: ${new Date()}

Curriculum version: ${cluster.curriculum_versie || 'v1'}
Description: ${cluster.description}

Is DTO: ${cluster.is_dto ? 'yes' : 'no'}

---
Originating club: ${club.full_name}
Contactperson: ${profile.name} via ${profile.email}

Details to the program can be added here:
-
-
-
-


For questions, please email contact@gliding.app




TECHNICAL DATA - PLEASE DO NOT ALTER AFTER THIS
${splitText}
${JSON.stringify(data)}
`

    const blob = new Blob([txt], { type: 'text/plain' }); // Create a Blob object with the string
    const url = URL.createObjectURL(blob); // Create a URL for the Blob
    
    // Create a temporary anchor element and trigger a download
    const a = document.createElement('a');
    a.href = url;
    a.download = `${club.name} - ${cluster.name} - ${cluster.curriculum_versie || 'v1'}.txt`; 
    document.body.appendChild(a);
    a.click();

    // Clean up the URL and remove the temporary element
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
}
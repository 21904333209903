import { DiffOutlined, LockFilled, UnlockOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Tag, Tooltip, message } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import useMedia from 'use-media'
import { Clock } from "~/components/clock/clock.jsx";
import { CloseDay } from "~/components/closeDay/closeDay.jsx";
import { HelpButton } from "~/components/help/help.jsx";
import { MainLayout } from "~/components/layout/layout.jsx";
import { UserModal } from "~/components/profile/userModal.jsx";
import { FlightBar } from "~/components/flight/FlightBar.jsx";
import { FlightEditModal } from "~/components/flight/FlightEditModal.jsx";
import { $t } from "~/i18n.js";
import {
  generate_uuid,
  selectElementByID,
  updateIfNeeded,
} from "../../../lib/helpers.js";
import { testDateIsToday, toLocaleDate } from "../../../lib/localize.js";
import {
  aanmeldingenActions,
  daysActions,
  flightsActions,
} from "../../../redux/actions.js";
import { emptyFlight } from "../../../redux/flights/flights.state.js";
import { FlightQueueComponent } from "./flightQueueComponent.jsx";
import { LiveFlightConnection } from "./liveFlightConnection.jsx";
import { helpDocs } from "./start.help.jsx";
import { TabelVluchten } from "./tabel/tabelVluchten.jsx";

export const StartAdministratieComponent = ({
  match,
  history,
  persist,
  flights,
  days,
  aanmeldingen,
  getAanmeldingen,
  openDay,
  updateStatus,
  newFlight,
  updateFlight,
  getFlights,
  getAllDays,
  getBaseData,
}) => {
  const [loading, setLoading] = useState(true);
  const isWide = useMedia({ minWidth: "1000px" }, false);

  // showFlightbar only on open days, and if permission has to change but without zweefvlieger
  const showFlightBar =
    persist.profile.wijzig_start &&
    days.activeDay.status === "open" &&
    persist.profile.group_keys.indexOf("zweefvlieger") === -1;

  // current selected flight depends on UUID
  const [activeFlightUUID, setActiveFlightUUID] = useState(null);
  useEffect(() => setActiveFlightUUID(match.params.uuid), [match.params.uuid]);

  const currentFlight = selectElementByID(
    flights.allFlights,
    "uuid",
    activeFlightUUID
  );
  // correct currentFlight, in case it is a aerotow flight, we need to select the sleep_uuid
  if (
    currentFlight &&
    currentFlight.start_methode.endsWith("-a") &&
    currentFlight.sleep_uuid
  )
    setActiveFlightUUID(currentFlight.sleep_uuid);

  const currentSleepFlight =
    currentFlight &&
    currentFlight.start_methode === "sleep" &&
    currentFlight.sleep_uuid &&
    selectElementByID(flights.allFlights, "uuid", currentFlight.sleep_uuid);
  const [selectedPilot, setSelectedPilot] = useState(null);

  // only save to queue if the user is allowed to wijzig starts, e.g. combi
  const saveToQueueWhenOffline = persist.profile.wijzig_start;

  // can only add a vlucht if you have the right, or the day is not locked
  // normal user only if day is closed (not locked)
  // admin user (wijzig vluchten) kan do it when day is locked
  const readOnly =
    (days.activeDay.status === "locked" && !persist.profile.wijzig_vluchten) ||
    // startwagen only if day is open, as such when closed it is readonly if wijzig starts
    (days.activeDay.status === "open" && !persist.profile.wijzig_start) ||
    // explicit, only readonly on closed if user is not allowed to fly but update start admin, this is
    (days.activeDay.status !== "open" &&
      !persist.profile.vliegt &&
      persist.profile.wijzig_start);

  useEffect(() => {
    updateIfNeeded(flights.lastUpdatedBaseData, getBaseData, null, 3600000); // 1 hour
  }, [flights.lastUpdatedBaseData]);
  useEffect(() => {
    updateIfNeeded(
      days.lastUpdated,
      getAllDays,
      () => days.lastUpdated && openDay(match.params.id),
      3600000
    ); // 1 hour
  }, [match.params.id]);
  useEffect(() => {
    // only download aanmeldingen if online, day is currently selected, and activeDay is aangemeld
    if (
      days.activeDay.can_aanmeld &&
      days.activeDay.dag_id === parseInt(match.params.id)
    ) {
      // if dag_id is null, then we also have to update
      const trigger = aanmeldingen.dag_id ? aanmeldingen.lastUpdated : null;
      updateIfNeeded(
        trigger,
        () =>
          getAanmeldingen({
            dag_id: match.params.id,
            normalizeAsVliegers: true,
          }),
        null,
        3600000
      ); // 1 hour
    }
  }, [match.params.id, aanmeldingen.lastUpdated, days.activeDay]);

  const syncData = async () => {
    // hard sync of all data
    await getBaseData();
    await getAllDays();
    getAanmeldingen({ dag_id: match.params.id, normalizeAsVliegers: true });
    getFlights({ dag_id: match.params.id });
  };

  useEffect(() => {
    // only download flights if online
    const fetchData = async () => {
      const newFlights = await getFlights({ dag_id: match.params.id });
      setLoading(false);

      // open first flight if startwagen, and only open first zweefvlucht
      if (
        showFlightBar &&
        newFlights &&
        newFlights.length > 0 &&
        !match.params.uuid
      ) {
        const filtered =
          newFlights &&
          newFlights.filter((f) => !f.start_methode.endsWith("-a"));
        if (filtered && filtered.length > 0)
          setActiveFlightUUID(filtered[0].uuid);
      }
    };
    if (flights.lastUpdatedBaseData && navigator.onLine) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [match.params.id, getFlights, flights.lastUpdatedBaseData]);

  const createFlight = async ({ extraData, skipSleepConnection }) => {
    // create new flight with optionally extraData, e.g. kist or sleep_uuid
    let category = "";
    if (extraData.start_methode?.endsWith("-a")) {
      category = flights.default_sleep_cat || persist.club.sleep_cat;
    } else if (
      extraData.start_methode === "tmg" ||
      extraData.start_methode === "sep"
    ) {
      category = flights.default_tmg_cat || persist.club.tmg_cat;
    } else {
      category = flights.default_flight_cat || persist.club.flight_cat;
    }
    // extract first category, only if category is not other
    category =
      category.indexOf(",") > 0
        ? (category.split(",")[1] || "").trim()
        : category;

    if (category === "other") category = "";

    const newFlightObject = {
      ...emptyFlight,
      dag_id: days.activeDay.dag_id,
      datum: days.activeDay.datum,

      // in case admin adds a flight, lock it directly
      is_locked: days.activeDay.status === "locked",
      uuid: generate_uuid(),
      index: flights.allFlights.length,
      start_methode:
        extraData?.start_methode || days.activeDay.start_methode || "lier",
      vertrek_vliegveld: days.activeDay.vertrek_vliegveld,
      aankomst_vliegveld: days.activeDay.aankomst_vliegveld,
      category,

      // order is the figure on which it is sorted, see function fights.normalizers.getFlightOrder
      order: 10000 + flights.allFlights.length,

      ...extraData,
    };

    // awaiting and adding sleep uuid if required
    if (newFlightObject.start_methode === "sleep" && !skipSleepConnection) {
      const sleep_uuid = await connectOrCreateSleepFlight({
        flight: newFlightObject,
        new_method: "sleep",
      });
      newFlightObject.sleep_uuid = sleep_uuid;
    }

    // Add new and save directly
    await newFlight(newFlightObject);
    await updateFlight(newFlightObject, saveToQueueWhenOffline);

    return newFlightObject.uuid;
  };

  const createOrGetFlight = async ({ uuid, kist }) => {
    const zweef_vluchten =
      flights.allFlights &&
      flights.allFlights.filter((f) => !f.start_methode.endsWith("-a"));
    if (zweef_vluchten && zweef_vluchten.length > 0) {
      // if flight is given, select this flight
      if (uuid) return setActiveFlightUUID(uuid);

      // if no kist and first flight is empty, use that
      if (
        !kist &&
        zweef_vluchten &&
        zweef_vluchten.length > 0 &&
        !zweef_vluchten[0].registratie
      ) {
        return setActiveFlightUUID(flights.allFlights[0].uuid);
      }
    }
    let extraData = {};
    if (kist) {
      extraData = {
        kist_id: kist.id,
        registratie: kist.registratie,
        callsign: kist.callsign,
        type: kist.type,
        flarm: kist.flarm,
        start_methode:
          kist.start_methode || days.activeDay.start_methode || "lier",
      };
    }
    const new_uuid = await createFlight({ extraData });
    await setActiveFlightUUID(new_uuid);
    return new_uuid;
  };

  const [dayActiveSleepKist, setDayActiveSleepKist] = useState(null);
  const connectOrCreateSleepFlight = async ({ flight, new_method }) => {
    // if sleep selected it will generate a second new flight for the sleepvliegtuig, or update the connected sleepvlucht to TMG
    const hasSleepUUID = !!flight.sleep_uuid;
    let sleep_uuid = flight.sleep_uuid;

    if (new_method === "sleep" && !hasSleepUUID) {
      // Create sleep_flight if not already connected
      // add in extra data to setup the newSleepFlight
      const extraData = {
        volg_nummer: null,
        sleep_uuid: flight.uuid,
        betalend_lid_id: "DELETE",
        is_deleted: false,

        // default fallback to tmg-a, but if there is only 1 sleepkist, use that
        start_methode: "tmg-a",

        // add in start/landing times if possible, this is the case that the glider has already been departed (or een landed)
        start_tijd: flight.start_tijd,
      };

      // add in sleepVlieger, if on that day there is 1 vlieger that has sleepVlieger duty
      if (
        aanmeldingen.sleepvliegers &&
        aanmeldingen.sleepvliegers.length > 0 &&
        aanmeldingen.sleepvliegers[0]
      ) {
        extraData.gezagvoerder_id = aanmeldingen.sleepvliegers[0].id;
        extraData.gezagvoerder_naam = aanmeldingen.sleepvliegers[0].name;
      }

      // add in sleepKist if club only has 1 sleepkist
      let connectedStartMethod = "NONE";
      if (
        flights.sleep_kisten &&
        flights.sleep_kisten.length === 1 &&
        flights.sleep_kisten[0]
      ) {
        extraData.kist_id = flights.sleep_kisten[0].id;
        extraData.registratie = flights.sleep_kisten[0].registratie;
        extraData.callsign = flights.sleep_kisten[0].callsign;
        extraData.type = flights.sleep_kisten[0].type;
        extraData.flarm = flights.sleep_kisten[0].flarm;
        connectedStartMethod = flights.sleep_kisten[0].start_methode;
      } else if (dayActiveSleepKist) {
        extraData.kist_id = dayActiveSleepKist.id;
        extraData.registratie = dayActiveSleepKist.registratie;
        extraData.callsign = dayActiveSleepKist.callsign;
        extraData.type = dayActiveSleepKist.type;
        extraData.flarm = dayActiveSleepKist.flarm;
        connectedStartMethod = dayActiveSleepKist.start_methode;
        extraData.start_methode;
      }

      if (["tmg", "sep"].indexOf(connectedStartMethod) < 0) {
        message.info(
          "'tmg-a' is added as tow method. Please setup default start method for aerotow material, you can do this under material settings"
        );
        extraData.start_methode = "tmg-a";
      } else {
        extraData.start_methode = `${connectedStartMethod}-a`;
      }
      // sleepUUID becomes the one of the newly created flight
      sleep_uuid = await createFlight({ extraData });
    }

    if (new_method.endsWith("-a") && !hasSleepUUID) {
      // Sleep aircraft selected, and then modified to sleep, as such create flight and select that flight
      const extraData = {
        sleep_uuid: flight.uuid,
        start_methode: "sleep",
        betalend_lid_id: "DELETE",
        is_deleted: false,
        start_tijd: flight.start_tijd,
      };

      // sleepUUID becomes the one of the newly created flight
      sleep_uuid = generate_uuid();
      await updateFlight(
        { uuid: flight.uuid, sleep_uuid },
        saveToQueueWhenOffline
      );
      sleep_uuid = await createFlight({ extraData, skipSleepConnection: true });

      // select this UUID as active
      await setActiveFlightUUID(sleep_uuid);
    }

    if (hasSleepUUID) {
      // determine when switching sleep/aerotow, what the default startmethode should be
      let connectedStartMethod = "NONE";
      const connectedAirplane =
        hasSleepUUID &&
        currentSleepFlight &&
        selectElementByID(
          flights.sleep_kisten,
          "registratie",
          currentSleepFlight.registratie
        );

      // we try to extract the startmethod from the connected airplane or default values
      if (connectedAirplane) {
        connectedStartMethod = connectedAirplane.start_methode;
      } else if (
        flights.sleep_kisten &&
        flights.sleep_kisten.length === 1 &&
        flights.sleep_kisten[0]
      ) {
        connectedStartMethod = flights.sleep_kisten[0].start_methode;
      } else if (dayActiveSleepKist) {
        connectedStartMethod = dayActiveSleepKist.start_methode;
      }

      // default sleep must be tmg or SEP ortherwise error, and data of clubs might not be synced yet, so fix
      if (["tmg", "sep"].indexOf(connectedStartMethod) < 0) {
        connectedStartMethod = "tmg";
      }

      if (new_method === "sleep") {
        // Update connected sleep_uuid to aerotow, depending on the sleepkist there
        // only update essential data that might have been changed, but assume that rest (above) is still relevant

        const updatedSleepData = {
          uuid: sleep_uuid,

          // we move from tmg/sep to tmg-a/sep-a
          start_methode: `${connectedStartMethod}-a`,
          betalend_lid_id: "DELETE",
          action: "recover",

          // update start time, don't update landingstime as aerotow might already be landed
          start_tijd: flight.start_tijd,
        };
        await updateFlight(updatedSleepData, saveToQueueWhenOffline);
      }

      if (new_method.endsWith("-a")) {
        // Update connected sleep_uuid to sleep behind aerotow
        // only update essential data that might have been changed, but assume that rest (above) is still relevant
        const updatedSleepData = {
          uuid: sleep_uuid,
          // this is a bit confusing, but if the method is changed to -a, the connected flight is actual the glider, and as such sleep
          start_methode: "sleep",
          is_deleted: false,

          // update start time, don't update landingstime as aerotow might already be landed
          start_tijd: flight.start_tijd,
        };
        await updateFlight(updatedSleepData, saveToQueueWhenOffline);
      }

      if (new_method !== "sleep" && !new_method.endsWith("a")) {
        // e.g. new method is self start or something else, then we delete the connected flight
        await updateFlight(
          {
            uuid: sleep_uuid,
            betalend_lid_id: "DELETE",
            is_deleted: true,
            action: "delete",
          },
          saveToQueueWhenOffline
        );
      }
    }
    return sleep_uuid;
  };

  const triggerUpdateFlight = (data) => {
    // 1 register actions landing en/of start
    if (data.start_tijd && data.landings_tijd) {
      data.vluchtduur = parseInt(
        (dayjs(data.landings_tijd, "HH:mm") - dayjs(data.start_tijd, "HH:mm")) /
          60000
      );

      // correct negative
      if (data.vluchtduur < 0) {
        data.vluchtduur = 0;
        message.error(
          `Kist kan niet starten nadat het geland is, landingstijd aangepast naar ${data.start_tijd}`
        );
        data.landings_tijd = data.start_tijd;
      }

      // for TMG correct blocktimes, by default we take 10 minutes additional to flighttime
      if (data.start_methode?.startsWith("tmg")) {
        data.blocktime = data.vluchtduur + 10;
      }
    }

    // 2 extract the volgnummer in case it is none
    if (
      !data.start_methode?.endsWith("-a") &&
      data.start_tijd &&
      !data.volg_nummer
    ) {
      // get last volg_nummer of all Flights
      const volg_nummer = flights.allFlights.reduce((max, flight) => {
        if (flight.volg_nummer && flight.volg_nummer > max)
          return flight.volg_nummer;
        return max;
      }, 0);
      data.volg_nummer = volg_nummer + 1;
    }

    // 3 save sleep to activeDay to select next
    if (!data.start_methode?.endsWith("-a") && data.registratie) {
      setDayActiveSleepKist({
        kist_id: data.id,
        registratie: data.registratie,
        callsign: data.callsign,
        type: data.type,
        flarm: data.flarm,
        start_methode: data.start_methode,
      });
    }

    // 4 save data to redux
    updateFlight(data, saveToQueueWhenOffline);
    return data;
  };

  const docs = helpDocs();
  return (
    <MainLayout
      renderHeadless={persist.profile.wijzig_start}
      isLoading={
        flights.isPending ||
        days.isPending ||
        loading ||
        !days.activeDay.can_start
      }
      history={history}
    >
      <UserModal
        visible={selectedPilot && selectedPilot.id}
        onClose={() => setSelectedPilot(null)}
        pilot={selectedPilot}
      />

      <div
        className="row"
        style={{
          justifyContent: "space-between",
          flexWrap: "wrap",
          alignItems: "center",
          marginRight: 55,
        }}
      >
        <p className="large">
          {isWide && <Clock />}
          {days.activeDay.status === "locked" && (
            <LockFilled style={{ color: "black", marginRight: 4 }} />
          )}
          {days.activeDay.status === "closed" && (
            <UnlockOutlined style={{ color: "black", marginRight: 4 }} />
          )}
          <span>
            {toLocaleDate(new Date(days.activeDay.datum))} -{" "}
            {days.activeDay.type_dag}
          </span>
        </p>

        <div className="row" style={{ alignItems: "center" }}>
          {!readOnly &&
            days.activeDay.is_today &&
            persist.profile.wijzig_start &&
            persist.club && (
              <>
                <FlightQueueComponent syncData={syncData} />
                <LiveFlightConnection
                  club={persist.club}
                  dayId={match.params.id}
                />
              </>
            )}

          {!readOnly &&
            persist.profile.wijzig_start &&
            flights.allFlights.length === 0 && (
              <Button size="small" onClick={createOrGetFlight}>
                {$t("Nieuwe vlucht")}
              </Button>
            )}

          {!persist.profile.wijzig_start && (
            <Popconfirm
              placement="bottom"
              title={
                <p>
                  {$t("Zeker weten dat je een nieuwe vlucht wilt toevoegen?")}
                  <br />
                  {$t(
                    "De vlucht is direct zichtbaar in de startadministratie."
                  )}
                </p>
              }
              onConfirm={() => createOrGetFlight({})}
              disabled={readOnly}
              okText={$t("Ja")}
              cancelText={$t("Nee")}
            >
              <Button size="small" disabled={readOnly}>
                {$t("Voeg vlucht toe")}
              </Button>
            </Popconfirm>
          )}

          <Tooltip
            placement="top"
            title={$t("Bekijk dagtotalen als de dag afgesloten is.")}
          >
            <Button
              size="small"
              disabled={days.activeDay.status === "open"}
              icon={<DiffOutlined />}
              href={"/totaal/" + match.params.id}
              style={{ marginLeft: 6 }}
            >
              {$t("Dagtotaal")}
            </Button>
          </Tooltip>

          <CloseDay
            dag_id={match.params.id}
            canClose={days.activeDay.status === "open"}
            canOpen={
              days.activeDay.status === "closed" &&
              testDateIsToday(days.activeDay.datum)
            }
            updateStatus={updateStatus}
            getFlights={getFlights}
          />

          <HelpButton
            title={$t("Start administratie")}
            subtitle={$t("Voer hier de start administratie van de dag door.")}
            helpDocs={docs}
          />
        </div>
      </div>

      {days.activeDay.status !== "open" && (
        <div className="row" style={{ marginBottom: 6 }}>
          <Tag color="red" style={{ marginRight: 4 }}>
            {$t("Gesloten")}
          </Tag>
          {days.activeDay.status === "closed" ? (
            <Tooltip
              placement="right"
              title={$t(
                "Alle correcties zullen worden bijgehouden en doorgestuurd naar de admin, DDI en piloot in kwestie ter controle."
              )}
            >
              <p>{$t("Correcties mogelijk")}</p>
            </Tooltip>
          ) : (
            <p>{$t("Correcties niet meer mogelijk")}</p>
          )}
        </div>
      )}

      {days.activeDay.status === "open" && !persist.profile.wijzig_start && (
        <div className="row" style={{ marginBottom: 6 }}>
          <Tag color="green" style={{ marginRight: 4 }}>
            {$t("Open")}
          </Tag>
          <p>{$t("Tijdschrijven kan nu alleen vanuit de startwagen.")}</p>
        </div>
      )}

      {currentFlight && (
        <>
          {showFlightBar ? (
            <FlightBar
              readOnlyDay={readOnly}
              isPending={flights.isPending}
              flight={currentFlight}
              updateFlight={triggerUpdateFlight}
              sleepFlight={currentSleepFlight}
              connectOrCreateSleepFlight={connectOrCreateSleepFlight}
              kisten={flights.kisten}
              sleep_kisten={flights.sleep_kisten}
              vliegers={flights.vliegers}
              aanmeldingen={
                aanmeldingen.dag_id === match.params.id
                  ? aanmeldingen.aanmeldingen
                  : []
              }
              kistStatus={flights.kistStatus}
              activeDay={days.activeDay}
              openKist={createOrGetFlight}
              setSelectedPilot={setSelectedPilot}
            />
          ) : (
            <FlightEditModal
              readOnly={readOnly}
              currentUser={persist.profile}
              visible={!!setActiveFlightUUID}
              onClose={() => {
                history.replace(`/start/${match.params.id}`);
                setActiveFlightUUID(null);
              }}
              flight={currentFlight}
              updateFlight={(data) => triggerUpdateFlight({ ...data })}
              connectOrCreateSleepFlight={connectOrCreateSleepFlight}
              sleepFlight={currentSleepFlight}
              kisten={flights.kisten}
              sleep_kisten={flights.sleep_kisten}
              vliegers={flights.vliegers}
              aanmeldingen={
                aanmeldingen.dag_id === match.params.id
                  ? aanmeldingen.aanmeldingen
                  : []
              }
              setSelectedPilot={setSelectedPilot}
            />
          )}
        </>
      )}

      <div className="row" style={{ alignItems: "flex-start" }}>
        <TabelVluchten
          showLandingButton={showFlightBar}
          renderFullWidth={
            (!isWide && !persist.profile.wijzig_start) ||
            persist.profile.wijzig_start
          }
          getPending={flights.isPending || loading}
          allFlights={flights.allFlights}
          activeFlightUUID={activeFlightUUID}
          openFlight={(uuid) =>
            history.replace(`/start/${match.params.id}/${uuid}`)
          }
          updateFlight={triggerUpdateFlight}
        />
      </div>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  aanmeldingen: state.aanmeldingen,
  persist: state.persist,
  flights: state.flights,
  days: state.days
})

const mapDispatchToProps = (dispatch) => {
  return {
    getFlights: (d) => dispatch(flightsActions.getFlights(d)),
    newFlight: (d) => dispatch(flightsActions.newFlight(d)),
    updateFlight: (d, saveToQueueWhenOffline) => dispatch(flightsActions.updateFlight(d, saveToQueueWhenOffline)),
    updateStatus: (d) => dispatch(daysActions.updateStatus(d)),
    openDay: (d) => dispatch(daysActions.openDay(d)),
    getAanmeldingen: (d) => dispatch(aanmeldingenActions.getAanmeldingen(d)),
    getAllDays: (d) => dispatch(daysActions.getAllDays(d)),
    getBaseData: (d) => dispatch(flightsActions.getBaseData(d))
  }
}

export const StartAdministratiePagina = connect(mapStateToProps, mapDispatchToProps)(StartAdministratieComponent)

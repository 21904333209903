import {
  CheckOutlined,
  CloseOutlined,
  RetweetOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  Button,
  Cascader,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Switch,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { $t } from "~/i18n.js";

import { AnnuleerButton } from "~/components/buttons/AnnuleerButton.jsx";
import { HelpBubble } from "~/components/help/help.bubble.jsx";
import { TimeInput } from "~/components/timeInput/timeInput.jsx";
import { _parseDate } from "../../../../lib/localize.js";
import { saveMaterialTab } from "../../../../redux/materiaal/materiaal.actions.js";
import { MovePartModal } from "./movePart.jsx";

const explainIjkDatum =
  "Indien het component geinstalleerd is, zal hij dagelijks de totalen bijwerken met vluchten vanaf deze ijkdatum en met deze initiele waardes. Laat leeg als alle vluchten in het systeem meegenomen moeten worden.";
const explainNVT = "Indien niet relevant voor onderdeel, laat leeg.";

const CheckInput = ({ value, onChange, type }) => {
  return (
    <div className="row">
      <Checkbox
        checked={value !== -1}
        style={{ marginRight: 6 }}
        onChange={(click) => onChange(click.target.checked ? 0 : -1)}
      />

      {(!value || value > -1) && type === "time" && (
        <TimeInput
          allowClear={false}
          value={value}
          onChange={(value) => onChange(value)}
        />
      )}

      {(!value || value > -1) && type === "number" && (
        <Input
          type="number"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          style={{ maxWidth: 135 }}
        />
      )}
    </div>
  );
};

export const AddPartModal = ({
  part,
  currentMaterial,
  closeModal,
  partsList,
}) => {
  const [form] = Form.useForm();
  const parts_path = Form.useWatch("parts_path", form);
  const is_installed = Form.useWatch("is_installed", form);
  const track_inservice_life = Form.useWatch("track_inservice_life", form);

  useEffect(() => {
    form.resetFields();
  }, [part]);

  const dispatch = useDispatch();

  const [saving, setSaving] = useState(false);
  const saveForm = async () => {
    try {
      setSaving(true);
      const values = await form.validateFields();
      let parent_part_id = values.parts_path
        ? values.parts_path[values.parts_path.length - 1]
        : null;
      if (parent_part_id === part.id) {
        // can't be itself
        parent_part_id = null;
      }

      const payload = [
        {
          ...part,
          ...values,

          // parent path is the last in the array of ID's
          parent_part_id,
          initial_date: values?.initial_date?.format("YYYY-MM-DD"),
          initial_motor_date: values?.initial_motor_date?.format("YYYY-MM-DD"),
          date_installed: !values.is_installed
            ? null
            : values?.date_installed?.format("YYYY-MM-DD"),
          date_uninstalled: values.is_installed
            ? null
            : values?.date_uninstalled?.format("YYYY-MM-DD"),
        },
      ];
      const res = await dispatch(
        saveMaterialTab({
          activeTab: "parts",
          materialID: currentMaterial.id,
          payload,
        })
      );
      if (res) closeModal();
      setSaving(false);
    } catch (info) {
      console.log("Validate Failed:", info);
      setSaving(false);
    }
  };

  const [deleting, setDeleting] = useState(false);
  const deletePart = async () => {
    setDeleting(true);
    const payload = [{ id: part.id, action: "delete" }];
    const res = dispatch(
      saveMaterialTab({
        activeTab: "parts",
        materialID: currentMaterial.id,
        payload,
      })
    );
    if (res) closeModal();
    setDeleting(false);
  };

  const [showMoveModal, setShowMoveModal] = useState(false);

  return (
    <Modal
      title={part?.id ? $t("Wijzig onderdeel") : $t("Voeg onderdeel toe")}
      confirmLoading={saving}
      open={!!part}
      onCancel={closeModal}
      footer={[
        part.id && (
          <Popconfirm
            key="remove"
            placement="bottom"
            title={$t("Zeker weten dat je dit onderdeel wilt verwijderen")}
            onConfirm={deletePart}
            okText={$t("Ja")}
            cancelText={$t("Nee")}
          >
            <Button danger icon={<StopOutlined />} loading={deleting}>
              {$t("Verwijder")}
            </Button>
          </Popconfirm>
        ),

        part.id && (
          <Button
            key="move"
            icon={<RetweetOutlined />}
            style={{ marginRight: 24 }}
            onClick={() => setShowMoveModal(true)}
          >
            {$t("Verhuis")}
          </Button>
        ),

        <AnnuleerButton  key="annuleren" onClick={closeModal} />,
        <Button
          key="opslaan"
          onClick={saveForm}
          type="primary"
          loading={saving}
        >
          {$t("Opslaan")}
        </Button>,
      ]}
    >
      {showMoveModal && (
        <MovePartModal
          visible={showMoveModal}
          part={part}
          currentMaterial={currentMaterial}
          onSave={() => closeModal()}
          closeModal={() => setShowMoveModal(false)}
        />
      )}

      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxHeight: "70vh", overflowY: "auto", paddingRight: 12 }}
        name="onderdeel"
        preserve={false}
        requiredMark={false}
        initialValues={{
          order: 0,
          is_installed: true,
          ...part,
          initial_date: part?.initial_date ? dayjs(part?.initial_date) : null,
          initial_motor_date: part?.initial_motor_date
            ? dayjs(part?.initial_motor_date)
            : null,

          date_installed: part?.date_installed
            ? dayjs(part?.date_installed)
            : null,
          date_uninstalled: part?.date_uninstalled
            ? dayjs(part?.date_uninstalled)
            : null,
        }}
      >
        <Form.Item
          label={$t("Naam")}
          name="name"
          rules={[
            {
              required: true,
              max: 90,
              message: $t("Maximaal ${c} characters", { c: 50 }),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={$t("Rubriek")}
          name="parts_path"
          rules={[{ required: false }]}
        >
          <Cascader
            style={{ width: 200 }}
            placeholder=""
            options={partsList}
            changeOnSelect
          />
        </Form.Item>

        <Form.Item
          label={$t("Beschrijving")}
          name="description"
          rules={[
            {
              required: false,
              max: 190,
              message: $t("Maximaal ${c} characters", { c: 200 }),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={$t("Opmerking (vrij veld)")}
          name="remark"
          rules={[
            {
              required: false,
              max: 300,
              message: $t("Maximaal ${c} characters", { c: 300 }),
            },
          ]}
        >
          <Input.TextArea rows={3} />
        </Form.Item>

        {!part.parent_part_id && (
          <Form.Item label={$t("Positie")} name="order">
            <Input type="number" prefix="#" />
          </Form.Item>
        )}

        <Divider orientation="left" plain>
          {$t("Technische data")}:
        </Divider>

        <Form.Item
          label={$t("Producent")}
          name="manufacturer"
          rules={[
            {
              required: false,
              max: 180,
              message: $t("Maximaal ${c} characters", { c: 300 }),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={
            <p>
              {$t("Part nummer")}{" "}
              <HelpBubble
                content={$t("Onderdeel nummer van fabrikant, niet uniek.")}
              />
            </p>
          }
          name="part_number"
          rules={[
            {
              required: false,
              max: 180,
              message: $t("Maximaal ${c} characters", { c: 180 }),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={
            <p>
              {$t("Serie nummer")}{" "}
              <HelpBubble content={$t("Uniek nummer van onderdeel.")} />
            </p>
          }
          name="serie_number"
          rules={[
            {
              required: false,
              max: 180,
              message: $t("Maximaal ${c} characters", { c: 180 }),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={
            <p>
              MEL{" "}
              <HelpBubble
                content={$t(
                  "Geef aan of het item op de Minimum Equipment List van het toestel staat, indien deze defect is dan is het toestel buiten gebruik."
                )}
              />
            </p>
          }
          name="is_mel"
          valuePropName="checked"
        >
          <Switch
            checkedChildren={
              <p>
                <CheckOutlined /> {$t("Ja")}
              </p>
            }
            unCheckedChildren={
              <p>
                <CloseOutlined /> {$t("Nee")}
              </p>
            }
          />
        </Form.Item>

        {(part.parent_part_id || parts_path?.length > 0) && (
          <>
            <Form.Item
              label={
                <p>
                  {$t("Is geinstalleerd")}{" "}
                  <HelpBubble
                    content={$t(
                      "Geef aan of onderdeel op dit moment ingebouwd is."
                    )}
                  />
                </p>
              }
              name="is_installed"
              valuePropName="checked"
            >
              <Switch
                checkedChildren={
                  <p>
                    <CheckOutlined /> {$t("Ja")}
                  </p>
                }
                unCheckedChildren={
                  <p>
                    <CloseOutlined /> {$t("Nee")}
                  </p>
                }
              />
            </Form.Item>

            {is_installed && (
              <Form.Item
                label={$t("Datum ingebouwd")}
                name="date_installed"
                rules={[{ required: false, message: $t("Voeg een datum toe") }]}
              >
                <DatePicker
                  format={localStorage.localeFormat}
                  allowClear={false}
                />
              </Form.Item>
            )}

            {!is_installed && (
              <Form.Item
                label={$t("Datum uitgebouwd")}
                name="date_uninstalled"
                rules={[{ required: false, message: $t("Voeg een datum toe") }]}
              >
                <DatePicker
                  format={localStorage.localeFormat}
                  allowClear={false}
                />
              </Form.Item>
            )}
          </>
        )}

        {currentMaterial.category === "kist" && (
          <div>
            <Divider orientation="left" plain>
              {$t("In-service life")}:
            </Divider>

            {part.date_last_flight && (
              <p className="small">
                {$t("Laatste vlucht: ${flightdate}", {
                  flightdate: _parseDate(part.date_last_flight),
                })}
              </p>
            )}

            <Form.Item
              label={
                <p>
                  {$t("Meet gebruik")}{" "}
                  <HelpBubble
                    content={$t(
                      "Geef aan of het belangrijk is om de in-service life van het component bij te houden."
                    )}
                  />
                </p>
              }
              name="track_inservice_life"
              valuePropName="checked"
            >
              <Switch
                checkedChildren={
                  <p>
                    <CheckOutlined /> {$t("Ja")}
                  </p>
                }
                unCheckedChildren={
                  <p>
                    <CloseOutlined /> {$t("Nee")}
                  </p>
                }
              />
            </Form.Item>

            {track_inservice_life && (
              <>
                <Form.Item
                  label={
                    <p>
                      {$t("Master part")}{" "}
                      <HelpBubble
                        content={$t(
                          "Gebruikt om starts en uren bij te houden op het Certificate Release to Service."
                        )}
                      />
                    </p>
                  }
                  name="master_part"
                  rules={[{ required: false }]}
                >
                  <Select>
                    <Select.Option key="null" value="">
                      {$t("Géén")}
                    </Select.Option>
                    <Select.Option key="hull" value="hull">
                      {$t("hull")}
                    </Select.Option>
                    <Select.Option key="engine" value="engine">
                      {$t("engine")}
                    </Select.Option>
                    <Select.Option key="propeller" value="propeller">
                      {$t("propeller")}
                    </Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label={
                    <p>
                      {$t("Ijkdatum")} <HelpBubble content={explainIjkDatum} />
                    </p>
                  }
                  name="initial_date"
                >
                  <DatePicker format={localStorage.localeFormat} />
                </Form.Item>
                <Form.Item
                  label={
                    <p>
                      {$t("Starts")} <HelpBubble content={explainNVT} />
                    </p>
                  }
                  name="initial_starts"
                  rules={[{ required: false }]}
                >
                  <CheckInput type="number" />
                </Form.Item>

                <Form.Item
                  label={
                    <p>
                      {$t("Tijd")} <HelpBubble content={explainNVT} />
                    </p>
                  }
                  name="initial_minutes"
                  rules={[{ required: false }]}
                >
                  <CheckInput type="time" />
                </Form.Item>

                {currentMaterial.kist_configuration !== "Glider" && (
                  <Form.Item
                    label={
                      <p>
                        {$t("Ijkdatum motor")}{" "}
                        <HelpBubble content={explainIjkDatum} />
                      </p>
                    }
                    name="initial_motor_date"
                    rules={[{ required: false }]}
                  >
                    <DatePicker format={localStorage.localeFormat} />
                  </Form.Item>
                )}

                {currentMaterial.kist_configuration !== "Glider" && (
                  <Form.Item
                    label={
                      <p>
                        {$t("Motortijd")} <HelpBubble content={explainNVT} />
                      </p>
                    }
                    name="initial_motor_minutes"
                    rules={[{ required: false }]}
                  >
                    <CheckInput type="time" />
                  </Form.Item>
                )}
              </>
            )}
          </div>
        )}
      </Form>
    </Modal>
  );
};

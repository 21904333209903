// TODO use https://www.robinwieruch.de/react-router-private-routes/
import { Modal } from "antd";
import React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { config } from "./config.js";
import { $t } from "./i18n.js";
import { AdminRoutes } from "./pages/admin/Router.jsx";
import { AanmeldLijstPagina } from "./pages/administratie/aanmeldLijst/aanmeldLijst.jsx";
import { PrintAanmeldLijstPagina } from "./pages/administratie/aanmeldLijst/print.aanmeldLijst.jsx";
import { DagOverzichtPagina } from "./pages/administratie/dagOverzicht/dagOverzicht.jsx";
import { DagVerslagPagina } from "./pages/administratie/dagverslag/dagVerslag.jsx";
import { RoostersPagina } from "./pages/administratie/roosters/roosters.jsx";
import { SleepAdministratiePagina } from "./pages/administratie/sleep/sleep.jsx";
import { StartAdministratiePagina } from "./pages/administratie/start/start.jsx";
import { DagtotaalPagina } from "./pages/administratie/totaal/totaal.jsx";
import { DocumentShortcutPage } from "./pages/documents/document.shortcut.jsx";
import { DocumentsPagina } from "./pages/documents/documents.jsx";
import { EmailPage } from "./pages/email/EmailPage.jsx";
import { FindingDetailPage } from "./pages/findings/FindingDetailPage.jsx";
import { FindingsPage } from "./pages/findings/FindingsPage.jsx";
import { LedenLijstOverzichtPagina } from "./pages/ledenlijst/LedenLijstOverzicht.jsx";
import { ActivateEmailPage } from "./pages/login/activate_email.jsx";
import { LoginPage } from "./pages/login/login.jsx";
import { LoginWithAccessTokenPage } from "./pages/login/login_with_accesstoken.jsx";
import { PasswordForgetPage } from "./pages/login/password_forget.jsx";
import { PasswordResetPage } from "./pages/login/password_reset.jsx";
import { MaterialOverviewTabsPage } from "./pages/materiaal/materiaalOverviewTabs.jsx";
import { MaterialTabsPage } from "./pages/materiaal/materiaalTabs.jsx";
import { MeldingOverviewPage } from "./pages/materiaal/melding/meldingOverview.jsx";
import { MeldingPage } from "./pages/materiaal/melding/meldingPage.jsx";
import { OnderhoudsUrenPagina } from "./pages/onderhoud/index.jsx";
import { MijnOnderhoudPagina } from "./pages/profile/mijnOnderhoudsUren.jsx";
import { MyDataPagina } from "./pages/profile/mydata.jsx";
import { MyStartsPagina } from "./pages/profile/mystarts.jsx";
import { RegistratiePagina } from "./pages/registratie/registratie.jsx";
import { ReservationPage } from "./pages/reservation/reservation.jsx";
import { NotFound } from "./pages/utils/404_not_found.jsx";
import { MaintenancePage } from "./pages/utils/503_maintenance.jsx";
import { NotFound404 } from "./pages/utils/NotFound404.jsx";
import { WwwHomePage } from "./pages/www/www.jsx";
import { persistActions } from "./redux/actions.js";

// Club public route are components like login which can only be accessible for club, not with WWW in front or naked
function redirectToWWWModal() {
  Modal.error({
    content: (
      <div>
        <p>{$t("Navigeer naar je eigen club zweef.app pagina")}</p>
        <p className="small" style={{ marginTop: 18 }}>
          {$t("Deze heeft een website url als")}: https://
          <span className="bold">{$t("club")}</span>.
          {window.isZweef() ? "zweef.app" : "gliding.app"}
        </p>
        <p className="small">
          {$t("Of druk op de Logo van je club op de homepagina")}.
        </p>
      </div>
    ),
  });
}

const ClubPublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (config.tenant === "www") {
        redirectToWWWModal();
        return <Redirect to="/" />;
      }
      return <Component {...props} {...rest} />;
    }}
  />
);

const RedirectExternal = ({ to, ...rest }) => {
  if (window.location.href.includes("www.")) {
    return (
      <p>Sorry, this is a legacy page, you should hard refresh this page</p>
    );
  }
  window.location.replace(to);
};

// Private route is connected to Redux and will redirect to login if not authenticated
const has_access = (profile, access_required) => {
  if (!access_required) return true;
  return access_required.some((a) => {
    if (profile[a]) return true;
  });
};
export const PrivateRouteComponent = ({
  component: Component,
  isAuthenticated,
  profile,
  access_required,
  title,
  ...rest
}) => {
  document.title = `${title ? title + " | " : ""}${
    window.isZweef() ? "Zweef.App" : "Gliding.App"
  }`;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (config.tenant === "www")
          return <WwwHomePage {...props} title={title} {...rest} />;
        return isAuthenticated === true &&
          has_access(profile, access_required) ? (
          <Component {...props} title={title} {...rest} />
        ) : (
          <Redirect to={`/login?redirect=${location.pathname}`} />
        );
      }}
    />
  );
};

export const PrivateRoute = connect(
  (state) => ({
    isAuthenticated: state.persist.isAuthenticated,
    profile: state.persist.profile,
  }),
  null,
  null,
  { pure: false }
)(PrivateRouteComponent);

// Force logout component, then redirect to login
const LogOutComponent = ({ logout, ...rest }) => (
  // force logout
  <Route
    {...rest}
    render={() => {
      logout();
      return <Redirect to="/login" />;
    }}
  />
);

const LogOut = connect(
  null,
  (dispatch) => ({ logout: (data) => dispatch(persistActions.logout(data)) }),
  null,
  { pure: false }
)(LogOutComponent);

export const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Redirect from="/uitloggen" to="/logout" />
        <Redirect from="/vergeten" to="/forgot" />
        <Redirect from="/wachtwoord*" to="/password*" />
        <Redirect from="/nieuw_email*" to="/new_email*" />
        <Redirect from="/akkoord/:token" to="/agree/:token" />
        <Redirect from="/aanmelden*" to="/register*" />
        <Redirect from="/totaal*" to="/total*" />
        <Redirect from="/sleep*" to="/tow*" />
        <Redirect from="/dagverslag*" to="/dailyreport*" />
        <Redirect from="/roosters*" to="/rosters*" />
        <Redirect from="/materiaal*" to="/material*" />
        <Redirect from="/melding*" to="/report*" />
        <Redirect from="/onderhoudsuren*" to="/maintenancehours*" />
        <Redirect from="/leden*" to="/members*" />
        <Redirect from="/mijn_starts*" to="/my_starts*" />
        <Redirect from="/mijn_gegevens*" to="/my_details*" />
        <Redirect from="/mijn_onderhoud*" to="/my_maintenance*" />
        <Redirect from="/documenten*" to="/documents*" />
        <Redirect from="/onderhoud*" to="/maintenance*" />
        <Redirect from="/registratie*" to="/registration*" />

        <RedirectExternal
          path="/privacy*"
          to="https://www.gliding.app/privacy"
        />
        <RedirectExternal path="/terms*" to="https://www.gliding.app/terms" />
        <RedirectExternal path="/agree*" to="https://www.gliding.app/terms" />
        <RedirectExternal path="/pricing*" to="https://www.gliding.app/pricing"/>

        <LogOut path="/logout" component={LogOut} />
        <ClubPublicRoute
          path="/login"
          title={$t("Inloggen")}
          component={LoginPage}
        />
        <ClubPublicRoute path="/forgot" component={PasswordForgetPage} />
        <Route
          exact
          path="/password/:password_reset_code/"
          component={PasswordResetPage}
        />
        <Route
          exact
          path="/new_email/:email_code/"
          component={ActivateEmailPage}
        />
        <Route
          exact
          path="/login_complete"
          component={LoginWithAccessTokenPage}
        />

        <PrivateRoute
          path="/register/print/:id"
          component={PrintAanmeldLijstPagina}
          title={$t("Aanmeldlijst")}
          access_required={["vliegt", "iedereen_aanmelden"]}
        />
        <PrivateRoute
          path="/register/:id"
          component={AanmeldLijstPagina}
          title={$t("Aanmeldlijst")}
          access_required={["vliegt", "iedereen_aanmelden"]}
        />
        <PrivateRoute
          path="/register"
          component={DagOverzichtPagina}
          title={$t("Aanmeldlijst")}
          link_to="register"
          access_required={["vliegt", "iedereen_aanmelden"]}
        />
        <PrivateRoute
          path="/reservations"
          component={ReservationPage}
          title={$t("Reserveren")}
          access_required={["vliegt", "iedereen_aanmelden"]}
        />
        <PrivateRoute
          path="/start/:id/:uuid"
          component={StartAdministratiePagina}
          title={$t("Startadministratie")}
          access_required={["vliegt", "wijzig_start"]}
        />
        <PrivateRoute
          path="/start/:id"
          component={StartAdministratiePagina}
          title={$t("Startadministratie")}
          access_required={["vliegt", "wijzig_start"]}
        />
        <PrivateRoute
          path="/start"
          component={DagOverzichtPagina}
          title={$t("Startadministratie")}
          link_to="start"
          access_required={["vliegt", "wijzig_start"]}
        />
        <PrivateRoute
          path="/total/:id"
          component={DagtotaalPagina}
          title={$t("Dagtotaal")}
          access_required={["vliegt", "wijzig_start"]}
        />
        <PrivateRoute
          path="/tow/:id"
          component={SleepAdministratiePagina}
          title={$t("Sleepadministratie")}
          access_required={["wijzig_sleep"]}
        />
        <PrivateRoute
          path="/tow"
          component={DagOverzichtPagina}
          title={$t("Sleepadministratie")}
          link_to="tow"
          access_required={["wijzig_sleep"]}
        />
        <PrivateRoute
          path="/dailyreport/:id"
          component={DagVerslagPagina}
          title={$t("Dagverslag")}
          access_required={["ziet_dto"]}
        />
        <PrivateRoute
          path="/dailyreport"
          component={DagOverzichtPagina}
          title={$t("Dagverslagen")}
          link_to="dailyreport"
          access_required={["ziet_dto"]}
        />
        <PrivateRoute
          path="/rosters/:id"
          component={RoostersPagina}
          title={$t("Roosters")}
        />
        <PrivateRoute
          path="/rosters"
          component={RoostersPagina}
          title={$t("Roosters")}
        />
        <ClubPublicRoute
          path="/status/:afdeling/:materialID/:activeTab/:obj_id"
          component={MaterialTabsPage}
          title={$t("Status")}
        />
        <ClubPublicRoute
          path="/status/:afdeling/:materialID/:activeTab"
          component={MaterialTabsPage}
          title={$t("Status")}
        />
        <ClubPublicRoute
          path="/status/:afdeling/:materialID"
          component={MaterialTabsPage}
          title={$t("Status")}
        />
        <PrivateRoute
          path="/material/overview"
          component={MaterialOverviewTabsPage}
          title={$t("Material")}
          activeTab="material"
        />
        <PrivateRoute
          path="/material/workorders"
          component={MaterialOverviewTabsPage}
          title={$t("Workorders")}
          activeTab="workorders"
        />
        <PrivateRoute
          path="/material/parts"
          component={MaterialOverviewTabsPage}
          title={$t("Parts")}
          activeTab="parts"
        />
        <PrivateRoute
          path="/material/teams"
          component={MaterialOverviewTabsPage}
          title={$t("Teams")}
          activeTab="teams"
        />
        <PrivateRoute
          path="/material/:materialID/:activeTab/:obj_id/:action"
          component={MaterialTabsPage}
          title={$t("Materiaal")}
          access_required={["vliegt", "wijzig_start"]}
        />
        <PrivateRoute
          path="/material/:materialID/:activeTab/:obj_id"
          component={MaterialTabsPage}
          title={$t("Materiaal")}
          access_required={["vliegt", "wijzig_start"]}
        />
        <PrivateRoute
          path="/material/:materialID/:activeTab"
          component={MaterialTabsPage}
          title={$t("Materiaal")}
          access_required={["vliegt", "wijzig_start"]}
        />
        <PrivateRoute
          path="/material/:materialID"
          component={MaterialTabsPage}
          title={$t("Materiaal")}
          access_required={["vliegt", "wijzig_start"]}
        />
        <PrivateRoute
          path="/material"
          component={MaterialOverviewTabsPage}
          title={$t("Materiaal")}
          activeTab="material"
        />
        <PrivateRoute
          path="/report/:id"
          component={MeldingPage}
          title={$t("Melding")}
        />
        <PrivateRoute
          path="/report"
          component={MeldingOverviewPage}
          title={$t("Meldingen")}
        />
        <PrivateRoute
          path="/findings/:id"
          component={FindingDetailPage}
          access_required={["wijzig_findings"]}
          title={$t("Bevindingen")}
        />
        <PrivateRoute
          path="/findings"
          component={FindingsPage}
          access_required={["wijzig_findings"]}
          title={$t("Bevindingen")}
        />
        <PrivateRoute
          path="/maintenancehours"
          component={OnderhoudsUrenPagina}
          access_required={["wijzig_winteruren", "goedkeuren_uren"]}
          title={$t("Onderhoudsuren")}
        />
        <PrivateRoute
          path="/members/:id"
          component={LedenLijstOverzichtPagina}
          title={$t("Ledenlijst")}
          overzicht="members"
        />
        <PrivateRoute
          path="/members"
          component={LedenLijstOverzichtPagina}
          title={$t("Ledenlijst")}
          overzicht="members"
        />
        <PrivateRoute
          path="/dto/:id"
          component={LedenLijstOverzichtPagina}
          title={$t("DTO leerlingvolglijst")}
          overzicht="dto"
          access_required={["ziet_dto"]}
        />
        <PrivateRoute
          path="/dto"
          component={LedenLijstOverzichtPagina}
          title={$t("DTO leerlingvolglijst")}
          overzicht="dto"
          access_required={["ziet_dto"]}
        />
        <PrivateRoute
          path="/email"
          component={EmailPage}
          title={$t("Email")}
          access_required={["ziet_emaillijst"]}
        />
        <PrivateRoute
          path="/my_starts"
          component={MyStartsPagina}
          access_required={["vliegt"]}
          title={$t("Mijn starts")}
        />
        <PrivateRoute
          path="/my_details"
          component={MyDataPagina}
          access_required={["vliegt"]}
          title={$t("Mijn gegevens")}
        />
        <PrivateRoute
          path="/my_maintenance"
          component={MijnOnderhoudPagina}
          access_required={["vliegt"]}
          title={$t("Mijn onderhoud")}
        />
        <PrivateRoute
          path="/documents"
          component={DocumentsPagina}
          title={$t("Documenten")}
        />
        <PrivateRoute
          path="/d:uuid"
          component={DocumentShortcutPage}
          title={$t("Documenten")}
        />
        <PrivateRoute
          path="/"
          exact
          component={DagOverzichtPagina}
          title={$t("Aanmeldlijst")}
          link_to="register"
        />
        <Route
          path="/maintenance"
          exact
          component={MaintenancePage}
          title="503"
        />
        <Route
          path="/registration"
          exact
          component={RegistratiePagina}
          title={$t("Club registreren")}
        />
        <PrivateRoute
          path="/unsubscribe"
          component={MyDataPagina}
          access_required={["vliegt"]}
          title={$t("Mijn gegevens")}
        />
        <Route
          path="/admin"
          access_required={["ziet_staff"]}
          title={$t("Vluchten")}
        >
          <AdminRoutes>
            <Route component={NotFound404} title={$t("Niet gevonden")} />
          </AdminRoutes>
        </Route>
        {/* Catch all 404 */}

        <Route component={NotFound} title={$t("Niet gevonden")} />
      </Switch>
    </BrowserRouter>
  );
};

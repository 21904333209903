import { ArrowDownOutlined, ArrowUpOutlined, CloudUploadOutlined, EditOutlined, SoundTwoTone } from '@ant-design/icons'
import { Button, Collapse, Descriptions, Tag } from 'antd'
import React, { useState } from 'react'
import { $t } from '~/i18n.js'

import { HelpBubble } from "~/components/help/help.bubble";
import { _parseDate } from '../../../../lib/localize'
import { colors } from '../../../../theme/colors'
import { InFlightService } from '../../materialTags'
import { AddDocumentModal } from '../documents/addDocument'
import { DocumentList } from '../documents/documentList'
import './partCard.css'

export const PartCard = ({ editPart, currentMaterial, part, level, readOnly, parts_path, openMaintenance, saveParts }) => {
  // Optional open first level
  // let defaultActiveKey = level < 1 ? Array.from({length: part.parts.length}, (item, index) => index) : [];
  const [openDocument, setOpenDocument] = useState(null)

  const subparts = part.parts?.sort((a, b) => a.order - b.order)

  const [moving, setMoving] = useState(false)
  const move_up = async (subpart, key) => {
    if (!moving && key > 0) {
      setMoving(true)
      const prev_part = subparts[key - 1]

      // in case orders are same, bump up previous
      const delta = prev_part.order === subpart.order ? 1 : 0
      await saveParts([
        { id: prev_part.id, order: subpart.order + delta },
        { id: subpart.id, order: prev_part.order }])
      setMoving(false)
    }
  }

  const move_down = async (subpart, key) => {
    if (!moving && key < (subparts.length - 1)) {
      setMoving(true)
      const next_part = subparts[key + 1]

      // in case orders are same, bump up previous
      const delta = subpart.order === next_part.order ? 1 : 0
      await saveParts([
        { id: subpart.id, order: next_part.order + delta },
        { id: next_part.id, order: next_part.order }])
      setMoving(false)
    }
  }

  return (
    <div className='partCard' style={level > 0 ? { padding: '12px 0 12px 12px' } : {}}>

      {openDocument && <AddDocumentModal
        document={openDocument} currentMaterial={currentMaterial} part_id={part.id} activeTab='parts' defaultTag='part'
        closeModal={() => setOpenDocument(null)}
                       />}

      <Descriptions size='small' column={3} layout='horizontal'>

        <Descriptions.Item span={3}>{part.description}</Descriptions.Item>

        {part.is_mel && <Descriptions.Item span={1}><Tag color='red'>MEL</Tag> <HelpBubble content='Item staat op Minimum Equipment List. Indien het onderdeel defect is dan is het vliegtuig buiten gebruik.' /></Descriptions.Item>}

        {part.master_part && <Descriptions.Item span={1}><Tag color='green'>{$t(part.master_part)}</Tag></Descriptions.Item>}

        {part.manufacturer && <Descriptions.Item span={1}>{part.manufacturer}</Descriptions.Item>}
        {part.part_number && <Descriptions.Item span={1} label={$t('Part')}>{part.part_number}</Descriptions.Item>}
        {part.serie_number && <Descriptions.Item span={1} label={$t('Serie')}>{part.serie_number}</Descriptions.Item>}

        {part.remark && <Descriptions.Item span={3}><SoundTwoTone twoToneColor='green' /> {part.remark}</Descriptions.Item>}

        {part.track_inservice_life && <Descriptions.Item>
          <InFlightService element={part} />
        </Descriptions.Item>}

        {part.date_uninstalled && <Descriptions.Item label={$t('Uitgebouwd op')}>{_parseDate(part.date_uninstalled)}</Descriptions.Item>}
        {part.date_installed && <Descriptions.Item label={$t('Ingebouwd op')}>{_parseDate(part.date_installed)}</Descriptions.Item>}

      </Descriptions>

      {part.maintenance?.length > 0 &&
        <div className='row' style={{ gap: 6, flexWrap: 'wrap' }}>{$t('Maintenance')}: {part.maintenance.map((maintenance, idx) => <Tag className='clickable' key={idx} onClick={() => openMaintenance(maintenance.id)}>
          <p className='row' style={{ gap: 6, flexWrap: 'wrap' }}>
            {maintenance.status && <span className='icon' style={{ backgroundColor: colors[maintenance.status], width: 15, height: 15, borderRadius: '50%' }} />}
            {maintenance.name}
          </p>
        </Tag>)}
        </div>}

      {!readOnly && <Button onClick={() => setOpenDocument({ action: 'new' })} size='small' icon={<CloudUploadOutlined />}>{$t('Voeg document toe')}</Button>}
      {part?.documents?.length > 0 && <DocumentList readOnly={readOnly} editDocument={setOpenDocument} documents={part.documents} hideTag />}

      {subparts?.length > 0 && <Collapse style={{ marginTop: 12 }}>
        {part.parts.map((subpart, key) => {
          // Connect path to next level
          return (
            <Collapse.Panel
              key={key} header={<span>{subpart.name} {!subpart.is_installed && <Tag style={{ marginLeft: 18 }} color='red'>Niet geinstalleerd</Tag>} </span>}
              extra={[
                !readOnly && <Button
                  disabled={moving} onClick={(e) => {
                    e.stopPropagation()
                    move_up(subpart, key)
                  }} key='up' shape='circle' size='small' icon={<ArrowUpOutlined />}
                             />,

                !readOnly && <Button
                  disabled={moving} onClick={(e) => {
                    e.stopPropagation()
                    move_down(subpart, key)
                  }} key='down' shape='circle' size='small' icon={<ArrowDownOutlined />}
                             />,

                !readOnly && <Button
                  style={{ marginLeft: 6 }}
                  onClick={(e) => {
                    e.stopPropagation()
                    editPart({ ...subpart, parts_path })
                  }} key='alter'
                  shape='circle' size='small' icon={<EditOutlined />}
                             />
              ]}
            >
              <PartCard
                editPart={editPart} currentMaterial={currentMaterial} readOnly={readOnly} level={level + 1} part={subpart} parts_path={parts_path.concat(subpart.id)}
                openMaintenance={openMaintenance} saveParts={saveParts}
              />
            </Collapse.Panel>
          )
        }
        )}
                               </Collapse>}

    </div>
  )
}

import { Card, Divider } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { $t } from '~/i18n.js'

import { connect } from 'react-redux'
import { BreadcrumbHeader } from '~/components/layout/breadcrumbHeader.jsx'
import { MainLayout } from '~/components/layout/layout.jsx'
import { api } from '~/lib/api.js'
import { selectElementByID, updateIfNeeded } from '~/lib/helpers.js'
import { parseMinutesLong, toLocaleDate } from '~/lib/localize.js'
import { flightsActions } from '~/redux/actions.js'

const RenderCard = ({ title, total }) => {
  if (!total) return null
  return (
    <Card
      style={{ marginTop: 24 }}
      size="small"
      title={<p className="bold">{title}</p>}
    >
      {total.lier && total.lier.starts ? (
        <p>
          {$t("Lier")}:{$t("${count} starts", { count: total.lier.starts })},{" "}
          {parseMinutesLong(total.lier.minutes)}
        </p>
      ) : null}
      {total.sleep && total.sleep.starts ? (
        <p>
          {$t("Sleep")}:{$t("${count} starts", { count: total.sleep.starts })},{" "}
          {parseMinutesLong(total.sleep.minutes)}
        </p>
      ) : null}
      {total["tmg-a"] && total["tmg-a"].starts ? (
        <p>
          {$t("TMG Aerotow")}:
          {$t("${count} starts", { count: total["tmg-a"].starts })},{" "}
          {parseMinutesLong(total["tmg-a"].minutes)}
        </p>
      ) : null}
      {total["sep-a"] && total["sep-a"].starts ? (
        <p>
          {$t("SEP Aerotow")}:
          {$t("${count} starts", { count: total["sep-a"].starts })},{" "}
          {parseMinutesLong(total["sep-a"].minutes)}
        </p>
      ) : null}
      {total.zelf && total.zelf.starts ? (
        <p>
          {$t("Zelfstart")}:
          {$t("${count} starts", { count: total.zelf.starts })},{" "}
          {parseMinutesLong(total.zelf.minutes)}
        </p>
      ) : null}
      {total.tmg && total.tmg.starts ? (
        <p>
          {$t("TMG")}:{$t("${count} starts", { count: total.tmg.starts })},{" "}
          {parseMinutesLong(total.tmg.minutes)}
        </p>
      ) : null}
      <Divider style={{ margin: 6 }} />
      <p>
        <span className="bold">{$t("Totaal")}:</span>
        {$t("${count} starts", { count: total.starts })},{" "}
        {parseMinutesLong(total.minutes)}
      </p>
    </Card>
  );
}

const DagtotaalComponent = ({ history, match, flights, getBaseData }) => {
  // load in data
  const [loading, setLoading] = useState(false)

  const [total, setTotal] = useState(null)
  const [additioneleKisten, setAddKisten] = useState([])
  const [message, setMessage] = useState(null)

  useEffect(() => {
    updateIfNeeded(flights.lastUpdatedBaseData, getBaseData, null, 3600000) // 1 hour
  }, [flights.lastUpdatedBaseData, getBaseData])

  useEffect(() => {
    setLoading(true)
    api
      .post('flights/day_total.json', { dag_id: match.params.id })
      .then((res) => {
        if (res) {
          if (res.message) {
            setMessage(res.message)
          } else {
            // reduce data from multiple records to 1 record
            const base = { starts: 0, minutes: 0 }

            const totals =
              res &&
              Array.isArray(res) &&
              res.reduce(
                (all, kist_methode) => {
                  // add to total
                  all.datum = kist_methode.datum;
                  all.starts += kist_methode.starts;
                  all.minutes += kist_methode.minutes;

                  // add minutes and starts to method
                  if (
                    kist_methode.start_methode &&
                    all[kist_methode.start_methode]
                  ) {
                    all[kist_methode.start_methode].starts +=
                      kist_methode.starts;
                    all[kist_methode.start_methode].minutes +=
                      kist_methode.minutes;
                  }

                  // add if it does not exist
                  if (!all.kisten.includes(kist_methode.registratie)) {
                    all.kisten.push(kist_methode.registratie);
                  }

                  // then check if registratie al exists
                  if (!all[kist_methode.registratie]) {
                    all[kist_methode.registratie] = {
                      starts: 0,
                      minutes: 0,
                      kist: kist_methode,
                    };
                  }

                  // and add to the totals
                  all[kist_methode.registratie].starts += kist_methode.starts;
                  all[kist_methode.registratie].minutes += kist_methode.minutes;

                  // add method
                  all[kist_methode.registratie][kist_methode.start_methode] =
                    kist_methode;

                  return all;
                },
                {
                  starts: 0,
                  minutes: 0,
                  lier: { ...base },
                  sleep: { ...base },
                  zelf: { ...base },
                  tmg: { ...base },
                  sep: { ...base },
                  bungee: { ...base },
                  car: { ...base },
                  overig: { ...base },
                  "tmg-a": { ...base },
                  "sep-a": { ...base },
                  kisten: [],
                }
              );
          
            setTotal(totals)

            // then determine all kisten
            let addKisten = []
            totals.kisten.map(registratie => {
                const kist = selectElementByID(
                  flights.kisten,
                  'registratie',
                  registratie
                )

                if (!kist) {
                  addKisten.push({registratie})
                }
            })
            console.log('additional kisten: ', addKisten)
            setAddKisten(addKisten)


          }
          setLoading(false)
        }
      })
  }, [match.params.id])

  return (
    <MainLayout
      history={history}
      isLoading={loading || flights.isPending || !flights.lastUpdatedBaseData}
    >
      <BreadcrumbHeader
        breadcrumbs={[
          <Link to='/start' className='title' key='1'>
            {$t('Startadministratie')}
          </Link>,
          total && total.datum ? toLocaleDate(new Date(total.datum)) : ''
        ]}
        buttons={[]}
      />

      {message && <p className='bold'>{message}</p>}

      {!message && (
        <>
          <p style={{ marginTop: 24 }}>
            {$t('Dagtotalen geschikt voor de logboeken van alle kisten.')}
          </p>

          <RenderCard title={$t('Dag totaal')} total={total} />

          {total &&
            total.kisten &&
            flights.kisten &&
            flights.kisten.concat(additioneleKisten).map((kist, key) => {
              
              const totalK = total[kist.registratie]
              if (!kist) { console.log('... FAILED to load in kist: ', total[kist.registratie]) }
              
              if (kist && totalK && totalK.starts > 0) {
                return (
                  <RenderCard
                    key={key}
                    title={`${kist.callsign ? kist.callsign + ': ' : ''}${
                      kist.registratie
                    } ${kist.type ? " (" + kist.type + ")" : ""}`}
                    total={totalK}
                  />
                )
              } else {
                return null
              }
            })}
        </>
      )}
    </MainLayout>
  )
}

const mapStateToProps = (state) => ({
  flights: state.flights
})

const mapDispatchToProps = (dispatch) => {
  return {
    getBaseData: (d) => dispatch(flightsActions.getBaseData(d))
  }
}

export const DagtotaalPagina = connect(
  mapStateToProps,
  mapDispatchToProps
)(DagtotaalComponent)

import { CheckCircleFilled, MinusCircleOutlined, QuestionOutlined, WarningOutlined } from '@ant-design/icons'
import { Button, Tag, Tooltip } from 'antd'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { $t } from '~/i18n.js'

import { _parseDate } from '../../lib/localize.js'
import { colors } from '../../theme/colors.js'
import { HelpBubble } from '../help/help.bubble.jsx'
import { RecencyExplanationModal } from './recency.explanation.jsx'

const recencyValidThrough = (is_invalid, valid_through, skip_valid_alert) => {
  const alert_date = !skip_valid_alert && valid_through && dayjs(valid_through, 'YYYY-MM-DD').diff(dayjs(), 'days') < 90
  return (
    <td className='col'>{is_invalid
      ? <Tag color='red'><span className='small'>{$t('Onvoldoende')}</span></Tag>
      : <div className='row'><CheckCircleFilled style={{ color: colors.groen_dark }} />
        {
        alert_date ? <Tag color='error' size='small'>{_parseDate(valid_through)}</Tag> : <span className='small' style={{ marginLeft: 4 }}>{_parseDate(valid_through)}</span>
      }
        </div>}
    </td>
  )
}

const recencyRow = (key, value, min, valid_through, skip_valid_alert) => <tr>
  <td className='col'>{key}</td>
  {value < min
    ? <td className='col'>{value} / {min}</td>
    : <td className='col'>{value}
      <Tooltip placement='top' title={$t('Minimale recency eisen om te voldoen aan eisen.')}>
        <span className='gray'>(≥{min})</span>
      </Tooltip>
    </td>}
  {recencyValidThrough(value < min, valid_through, skip_valid_alert)}
</tr>

const _recencyTable = (title, table) => {
  const [recencyModalVisible, showRecencyModal] = useState(false)

  return (
    <div className='column' style={{ alignItems: 'flex-start' }}>

      <div className='row'>
        <p className='clickable' onClick={() => showRecencyModal(true)} size='small'>{title}</p>
        <Button className='clickable' style={{ marginLeft: 6 }} shape='circle' onClick={() => showRecencyModal(true)} size='small'>
          <QuestionOutlined />
        </Button>
      </div>
      {recencyModalVisible && <RecencyExplanationModal visible={recencyModalVisible} onClose={() => showRecencyModal(false)} />}

      <div style={{ flexGrow: 1 }}>
        {table}
      </div>
    </div>
  )
}

export const RecencyTable = ({ recency }) => {
  return _recencyTable($t('EASA SPL recency, afgelopen 24 maanden:'),
    <table className='dataBlock'>
      <tbody>
        <tr>
          <td className='col bold'>{$t('SPL recency algemeen')}</td>
          <td className='col' />
          <td className='col'>{recency.spl === 'invalid' ? <Tag color='red'>{$t('Onvoldoende')}</Tag> : <CheckCircleFilled style={{ color: colors.groen_dark }} />}</td>
        </tr>
        {recency.spl_profcheck && <tr>
          <td className='col'>{$t('Proficiency check (AoC)')}</td>
          <td className='col' />
          {recencyValidThrough(false, recency.spl_profcheck_valid_to)}
                                  </tr>}
        {recencyRow($t('Trainingsvluchten'), recency.checks, 2, recency.checks_valid_to)}
        {recencyRow($t('Starts'), recency.starts, 15, recency.starts_valid_to)}
        {recencyRow($t('Uren'), parseInt(recency.vliegduur / 6) / 10, 5, recency.vliegduur_valid_to)}
        {Number.isInteger(recency.lier) && recencyRow($t('Lierstart'), recency.lier, 5, recency.lier_valid_to)}
        {Number.isInteger(recency.sleep) && recencyRow($t('Sleepstart'), recency.sleep, 5, recency.sleep_valid_to)}
        {Number.isInteger(recency.zelfstart) && recencyRow($t('Zelfstart'), recency.zelfstart, 5, recency.zelfstart_valid_to)}
        {Number.isInteger(recency.bungee) && recencyRow($t('Bungeestart'), recency.bungee, 2, recency.bungee_valid_to)}
        {Number.isInteger(recency.pax) && recencyRow($t('Passagier (90 dagen)'), recency.pax, 3, recency.pax_valid_to, true)}

      </tbody>
    </table>)
}

export const TMGRecencyTable = ({ recency }) => {
  return _recencyTable(
    $t("EASA TMG recency, afgelopen 24 maanden:"),
    <table className="dataBlock">
      <tbody>
        <tr>
          <td className="col bold">{$t("TMG recency algemeen")}</td>
          <td className="col" />
          <td className="col">
            {recency.tmgaantekening === "invalid" ? (
              <Tag color="red">{$t("Onvoldoende")}</Tag>
            ) : (
              <CheckCircleFilled style={{ color: colors.groen_dark }} />
            )}
          </td>
        </tr>
        {recency.tmg_excemption == "invalid" && (
          <tr>
            <td className="col">
              FCL{" "}
              <HelpBubble
                content={$t(
                  "Bij een geldige FCL licentie (PPL of hoger) hoeft de vlieger niet te voldoen aan een minimum uren en starts om de TMG aantekening geldig te houden"
                )}
              />
            </td>
            <td> </td>
            <td>
              <CheckCircleFilled style={{ color: colors.groen_dark }} />
            </td>
          </tr>
        )}

        {recency.tmg_profcheck && (
          <tr>
            <td className="col">{$t("Proficiency check (AoC)")}</td>
            <td className="col" />
            {recencyValidThrough(false, recency.tmg_profcheck_valid_to)}
          </tr>
        )}
        {recencyRow(
          $t("TMG starts"),
          recency.tmg_starts,
          12,
          recency.tmg_starts_valid_to
        )}
        {recencyRow(
          $t("TMG uren"),
          parseInt(recency.tmg_uren / 6) / 10,
          6,
          recency.tmg_uren_valid_to
        )}
        {recencyRow(
          <p>
            {$t("TMG training")}{" "}
            <HelpBubble
              title={$t("EASA TMG Trainingsvlucht")}
              content={$t(
                "Deze trainingsvlucht, van minimaal 1 uur, mag verspreid worden over meerdere vluchten zolang die op dezelfde dag gebeuren."
              )}
            />
          </p>,
          recency.tmg_check,
          1,
          recency.tmg_check_valid_to
        )}
        {Number.isInteger(recency["tmg-a"]) &&
          recencyRow(
            $t("TMG Aerotow"),
            recency["tmg-a"],
            5,
            recency["tmg-a_valid_to"]
          )}
        {Number.isInteger(recency.tmg_pax) &&
          recencyRow(
            $t("TMG Passagier (${days} dagen)", { days: 90 }),
            recency.tmg_pax,
            3,
            recency.tmg_pax_valid_to,
            true
          )}
      </tbody>
    </table>
  );
}

export const FISRecencyTable = ({ recency }) => {
  if (!recency) return null
  return _recencyTable($t('EASA FI(s) recency, afgelopen 3 jaar:'),
    <table className='dataBlock'>
      <tbody>
        <tr>
          <td className='col '><span className='bold'>{$t('FI(s) recency algemeen')}</span>
            {recency.spl === 'invalid' && <> <br /> <span className='small'>{$t('Note: Ongeldig SPL')}</span></>}
          </td>
          <td className='col' />
          <td className='col'>{recency.fis === 'invalid' ? <Tag color='red'>{$t('Onvoldoende')}</Tag> : <CheckCircleFilled style={{ color: colors.groen_dark }} />}</td>
        </tr>
        {recency.fis_date_exam && <tr>
          <td className='col'>{$t('Flight instructor exam')}</td>
          <td className='col'>{recency.fis_date_exam ? _parseDate(recency.fis_date_exam) : <MinusCircleOutlined style={{ color: colors.darkgray_light, marginLeft: 6 }} />}</td>
          {recencyValidThrough(recency.fis_date_exam_invalid, recency.fis_date_exam_valid_to)}
                                  </tr>}
        {recencyRow($t('of FI(s) uren'), parseInt(recency.fis_uren / 6) / 10, 30, recency.fis_uren_valid_to)}
        {recencyRow($t('of FI(s) starts'), recency.fis_starts, 60, recency.fis_starts_valid_to)}
        <tr>
          <td className='col'>{$t('Refresher Training')}</td>
          <td className='col'>{recency.fis_date_refresher_course ? _parseDate(recency.fis_date_refresher_course) : <MinusCircleOutlined style={{ color: colors.darkgray_light, marginLeft: 6 }} />}</td>
          {recencyValidThrough(recency.fis_date_refresher_course_invalid, recency.fis_date_refresher_course_valid_to)}
        </tr>
        <tr>
          <td className='col'>{$t('Instr. flt under supervision')}</td>
          <td className='col'>{recency.fis_date_training_flight ? _parseDate(recency.fis_date_training_flight) : <MinusCircleOutlined style={{ color: colors.darkgray_light, marginLeft: 6 }} />}</td>
          {recencyValidThrough(recency.fis_date_training_flight_invalid, recency.fis_date_training_flight_valid_to)}
        </tr>
      </tbody>
    </table>)
}

export const FESRecencyTable = ({ recency }) => {
  if (!recency) return null
  return _recencyTable($t('EASA FE(s) recency:'),
    <table className='dataBlock'>
      <tbody>
        <tr>
          <td className='col '><span className='bold'>{$t('FE(s) recency:')}</span></td>
          <td className='col' />
          <td className='col'>{recency.fes === 'invalid' ? <Tag color='red'>{$t('Onvoldoende')}</Tag> : <CheckCircleFilled style={{ color: colors.groen_dark }} />}</td>
        </tr>
        <tr>
          <td className='col'>{$t('FE(S) refresher course')}</td>
          <td className='col'>{recency.fes_refresher ? _parseDate(recency.fes_refresher) : <MinusCircleOutlined style={{ color: colors.darkgray_light, marginLeft: 6 }} />}</td>
          {recencyValidThrough(recency.fes_date_refresher_invalid, recency.fes_date_refresher_valid_to)}
        </tr>
      </tbody>
    </table>)
}

const WarningTag = ({ content }) => <Tag className='small' color='red' style={{ fontSize: 11, marginTop: 1, padding: '2px 3px' }}><WarningOutlined /> {content}</Tag>

export const RecencyCurrencyBarometer = ({ pilot, tag, skipDetails }) => {
  if (!pilot) return <div style={{ height: 20 }} />

  const recency = pilot?.recency
  const brevet_invalid = recency?.spl === 'invalid'
  const currency = pilot.currency && (typeof pilot.currency === 'string' || pilot.currency instanceof String) ? pilot.currency : ''
  const showCheck = currency && currency === 'rood' && recency?.check_start_3mnd === 'invalid'
  return (
    <div className="column" style={{ alignItems: "flex-start" }}>
      <div
        className="row"
        style={{
          maxWidth: 270,
          flexWrap: "wrap",
          height: skipDetails ? 20 : null,
        }}
      >
        {tag && (
          <div className="tag row" style={{ marginRight: 6 }}>
            <p className="small">{$t(tag)}</p>
          </div>
        )}

        {pilot && currency && currency !== "nvt" && !brevet_invalid && (
          <div className="currency row" style={{ marginRight: 6 }}>
            <div
              className="icon"
              style={
                currency && {
                  backgroundColor: colors[currency],
                  position: "relative",
                }
              }
            >
              {showCheck && (
                <WarningOutlined
                  style={{
                    color: "white",
                    fontSize: 12,
                    position: "absolute",
                    top: 4,
                    left: 4,
                  }}
                />
              )}
            </div>
            {/* Currencies: $t('rood') $t('groen') $t('geel') */}
            <p className="small">
              {currency === "orange" ? $t("oranje") : $t(currency)}
            </p>

            {/* Edgecase, DBO/Solisten without medical */}
            {recency?.medical && (
              <Tag
                color="red"
                className="small"
                style={{ marginLeft: 6, padding: 2 }}
              >
                <WarningOutlined /> {$t("medical")}{" "}
              </Tag>
            )}
          </div>
        )}

        {brevet_invalid && (
          <div className="currency row" style={{ marginRight: 6 }}>
            <div className="icon brevet" />
            <div className="column" style={{ alignItems: "flex-start" }}>
              <p className="small bold">{$t("Ongeldig")}</p>
              {!skipDetails && (
                <p className="small" style={{ maxWidth: 125 }}>
                  {recency?.checks < 2 && (
                    <span>{$t("trainings vlucht")} </span>
                  )}
                  {recency?.starts < 15 && <span>{$t("starts")} </span>}
                  {recency?.vliegduur < 300 && <span>{$t("uren")} </span>}
                  {recency?.medical && <span>{$t("medical")}</span>}
                </p>
              )}
            </div>
          </div>
        )}

        {(!brevet_invalid || !skipDetails) && recency && (
          <>
            {recency?.lieraantekening === "invalid" && (
                <WarningTag content={$t("Lier")} />
              )}
            {recency?.sleepaantekening === "invalid" && (
                <WarningTag content={$t("Sleep")} />
              )}
            {recency?.zelfstartaantekening === "invalid" && (
                <WarningTag content={$t("Zelf")} />
              )}
            {recency?.tmgaantekening === "invalid" && (
                <WarningTag content="TMG" />
              )}
            {recency && recency["tmg-a-aantekening"] === "invalid" && (
                <WarningTag content="TMG-A" />
              )}
            {recency?.passagiervliegen === "invalid" && (
                <WarningTag content="Pax" />
              )}
            {recency?.tmg_passagiervliegen === "invalid" && (
                <WarningTag content="T Pax" />
              )}
            {recency?.bungeeaantekening === "invalid" && (
                <WarningTag content="Bung" />
              )}
            {recency?.caraantekening === "invalid" && (
                <WarningTag content={$t("car")} />
              )}
            {recency?.fis === "invalid" && (
              <WarningTag content="FI(s)" />
            )}
            {recency?.fes === "invalid" && (
              <WarningTag content="FE(s)" />
            )}
          </>
        )}
      </div>
    </div>
  );
}

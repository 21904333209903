import { EditOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Input, Tag } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { $t } from "~/i18n.js";

import { selectElementByID } from "~/lib/helpers.js";
import {
  MaintenanceStatusTag,
  MeldingStatusTag,
} from "~/pages/materiaal/materialTags.jsx";
import { HelpBubble } from "../../help/help.bubble.jsx";
import { PrivateKistModal } from "./sectionKist.privateKist.modal.jsx";

export const SectionKist = ({
  kisten,
  selectedKist,
  readOnly,
  flight,
  saveFlight,
  is_today,
  width,
}) => {
  const [registratie, setRegistratie] = useState(
    flight ? flight.registratie : null
  );

  const [showPrivateModal, setShowPrivateModal] = useState(false);

  const searchInput = useRef(null);
  useEffect(() => {
    // initiates every time the props change
    if (flight) {
      setRegistratie(flight.registratie);
    }
    setResult(kisten);
  }, [flight, kisten, selectedKist]);
  const [result, setResult] = useState(kisten || []);

  const handleSearch = (searchText) => {
    searchText = searchText.toUpperCase();

    // filter and select text PH123 >>> PH-123, also for DE etc.
    if (searchText.match(/^[A-Z][A-Z][0-9]/)) {
      searchText =
        searchText.substr(0, 2) + "-" + searchText.substr(2, searchText.length);
    }

    let filtered = kisten.filter(
      (kist) =>
        kist?.registratie?.indexOf(searchText) > -1 ||
        kist?.callsign?.indexOf(searchText) > -1
    );

    // if no result, then show what you typed
    if (filtered.length === 0) {
      filtered = [
        {
          registratie: searchText,
        },
      ];
    }

    setResult(filtered);
  };

  const onSelect = (registratie) => {
    // check if selected flights matches, if not then no kist_id
    const selectedKist = selectElementByID(kisten, "registratie", registratie);
    const data = selectedKist
      ? {
          registratie,
          kist_id: selectedKist.id,
          callsign: selectedKist.callsign,
          type: selectedKist.type,
          flarm: selectedKist.flarm,
        }
      : {
          registratie,
          kist_id: null,
          callsign: "",
          type: "",
          flarm: "",
        };

    // reset tweede inzittende if only 1 pilot, in case that you selected two pilots and then change type
    if (selectedKist && selectedKist.pilots === 1) {
      data.tweede_inzittende_id = "DELETE";
      data.tweede_inzittende_naam = "";
    }

    // set the startmethod to tmg if sleep and method is lier/zelf
    if (!flight.start_tijd) {
      data.start_methode = selectedKist.start_methode;
    }

    saveFlight(data);
  };
  return (
    <div className="column" style={{ alignItems: "flex-start" }}>
      {flight && (
        <PrivateKistModal
          visible={showPrivateModal}
          kist_id={selectedKist && selectedKist.id}
          registratie={flight.registratie}
          callsign={flight.callsign}
          type={flight.type}
          flarm={flight.flarm}
          closeModal={() => setShowPrivateModal(false)}
          saveFlight={saveFlight}
        />
      )}

      <AutoComplete
        allowClear={false}
        disabled={readOnly}
        value={registratie}
        onChange={(value) => setRegistratie(value ? value.toUpperCase() : "")}
        style={{ width: width || 170 }}
        onSelect={onSelect}
        onSearch={handleSearch}
        options={
          result &&
          result.reduce(
            (all, kist) =>
              all.concat({
                label: (
                  <span>
                    {kist.callsign ? kist.callsign + ": " : ""}
                    {kist.registratie}
                    {is_today && kist?.material_status === "niet inzetbaar" && (
                      <Tag color="error">!</Tag>
                    )}
                  </span>
                ),
                value: kist.registratie,
              }),
            []
          )
        }
      >
        <Input.Search
          placeholder={$t("registratie")}
          ref={searchInput}
          onPressEnter={() => onSelect(result[0]?.registratie)}
        />
      </AutoComplete>

      {is_today && selectedKist?.material_status === "niet inzetbaar" && (
        <p className="row" style={{ marginTop: 6 }}>
          {$t("Status")}:{" "}
          <MeldingStatusTag status={selectedKist?.material_status} />
          <HelpBubble
            content={$t(
              "Let op: er zijn defecten gemeld die nog niet zijn opgelost. Bekijk de actuele staat van het materieel om zeker te zijn dat je ermee kan vliegen."
            )}
          />
        </p>
      )}

      {is_today && selectedKist?.maintenance_status === "red" && (
        <p className="row" style={{ marginTop: 6 }}>
          {$t("Onderhoud")}:
          <MaintenanceStatusTag
            maintenance_status={selectedKist?.maintenance_status}
          />
          <HelpBubble
            content={$t(
              "Let op: het onderhoudprogramma is verlopen. Bekijk de actuele staat van het materieel om zeker te zijn dat je ermee kan vliegen."
            )}
          />
        </p>
      )}

      {flight && (registratie || flight.callsign) && (
        <div style={{ margin: "4px 0 4px 12px" }}>
          <p className="small" style={{ maxWidth: 170 }}>
            {flight.callsign || $t("Callsign mist")} - {flight.type}
          </p>

          {(!selectedKist ||
            (selectedKist && selectedKist.category === "prive")) && (
            <Button
              type="default"
              size="small"
              style={{ marginTop: 4 }}
              disabled={readOnly}
              icon={<EditOutlined />}
              onClick={() => setShowPrivateModal(true)}
            >
              {$t("Wijzig kist")}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

import { CheckCircleFilled, ExclamationCircleTwoTone, EyeOutlined } from '@ant-design/icons'
import React from 'react'
import { OGNtimeIcon } from '~/components/flight/sections/Ogn.jsx';
import { FlightOpenIcon } from '~/components/flight/sections/flightOpenIcon.jsx';
import { $t } from '~/i18n.js'
import { _parseDate, parseMinutes } from '~/lib/localize.js';
import { colors } from '~/theme/colors.js';

export const MaterialLogbookColumns = () => ([
  {
    title: $t('Datum'),
    dataIndex: 'datum',
    sorter: (a, b) => new Date(a.datum) - new Date(b.datum),
    render: (text) => _parseDate(text, { month: '2-digit', day: '2-digit' })
  },
  { title: $t('Vertrek'), dataIndex: 'vertrek_vliegveld' },
  { title: $t('Starts'), dataIndex: 'starts' },
  { title: $t('Duur'), dataIndex: 'minutes', render: text => parseMinutes(text) },
  {
    title: $t('Inspecties'),
    dataIndex: 'inspections',
    render: (inspections, record) => {
    // inspections implemented as per 2023, as such do not show before this
      if ((new Date(record.datum)).getFullYear() > 2022) return inspections > 0 ? <CheckCircleFilled style={{ color: colors.green }} /> : <ExclamationCircleTwoTone twoToneColor={colors.gray_light} />
    }
  }
])

export const MaterialLogbookColumnsPerDay = () => [
  {
    title: $t("Datum"),
    dataIndex: "datum",
    sorter: (a, b) =>
      new Date(a.datum + " " + a.start_tijd) -
      new Date(b.datum + " " + b.start_tijd),
    render: (text) => _parseDate(text, { month: "2-digit", day: "2-digit" }),
  },
  {
    title: $t("Vertrek"),
    dataIndex: "vertrek_vliegveld",
    sorter: (a, b) =>
      ("" + a.vertrek_vliegveld).localeCompare(b.vertrek_vliegveld),
  },
  {
    title: $t("Meth."),
    dataIndex: "start_methode",
    filters: [
      { text: $t("lier"), value: "lier" },
      { text: $t("sleep"), value: "sleep" },
      { text: $t("zelfstart"), value: "zelf" },
      { text: $t("bungee"), value: "bungee" },
      { text: $t("car"), value: "car" },
      { text: $t("tmg"), value: "tmg" },
      { text: $t("tmg-a"), value: "tmg-a" },
      { text: $t("sep"), value: "sep" },
      { text: $t("sep-a"), value: "sep-a" },
    ],
    onFilter: (value, record) => record.start_methode === value,
    render: (m) => $t(m),
  },
  {
    title: $t("Start"),
    dataIndex: "start_tijd",
    width: 70,
    render: (text, flight) => (
      <div className="row" style={{ width: 65 }}>
        <OGNtimeIcon ogn_indicator={flight.start_ogn} />
        {text}
      </div>
    ),
  },
  {
    title: $t("Landing"),
    dataIndex: "landings_tijd",
    width: 70,
    render: (text, flight) => (
      <div className="row" style={{ width: 65 }}>
        <OGNtimeIcon ogn_indicator={flight.landings_ogn} />
        {text}
      </div>
    ),
  },
  {
    title: $t("Duur"),
    dataIndex: "vluchtduur",
    sorter: (a, b) => a.vluchtduur - b.vluchtduur,
    render: (text, flight) => (
      <div>
        {flight.starts > 1 && <span>{flight.starts} starts, </span>}
        <span>{parseMinutes(text)}</span>
      </div>
    ),
    responsive: ["md"],
  },
  {
    render: (text, flight) => (
      <FlightOpenIcon flight_uuid={flight.uuid} icon={<EyeOutlined />} />
    ),
  },
];
